import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";
import { popupApi } from "../popup";

export const eventsApi = createApi({
  reducerPath: "eventsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllEventType: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `event/filterType?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getAllEventTypeWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `event/filterType`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getEventType: builder.query({
      query(id) {
        return {
          url: `event/type/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createEventType: builder.mutation({
      query(form) {
        return {
          url: "event/type",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    applyEvent: builder.mutation({
      query(form) {
        return {
          url: "event/apply",
          method: "POST",
          data: form,
        };
      },

      transformResponse: (result) => result,

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {}
      },
    }),
    rejectEvent: builder.mutation({
      query(form) {
        return {
          url: "event/reject",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    acceptEvent: builder.mutation({
      query(form) {
        return {
          url: "event/accept",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    joinEvent: builder.mutation({
      query(data) {
        return {
          url: "event/joinEvent",
          method: "DELETE",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    getAllEvent: builder.query({
      query(filter) {
        const { title, eventTypeId, eventFormat } = filter;
        return {
          url: `event/filter?${title ? `&Title=${title}` : ""}${
            eventFormat ? `&EventFormat=${eventFormat}` : ""
          }${eventTypeId ? `&EventTypeId=${eventTypeId}` : ""}`,
        };
      },

      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getAllEventWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `event/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getEvent: builder.query({
      query(id) {
        return {
          url: `event/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getEventUserId: builder.query({
      query(id) {
        return {
          url: `event/byUserId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getEventManager: builder.query({
      query(id) {
        return {
          url: `event/siteUserManager?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    updateEvent: builder.mutation({
      query(data) {
        return {
          url: "event",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createEvent: builder.mutation({
      query(form) {
        return {
          url: "event",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    getUsersList: builder.query({
      query(id) {
        return {
          url: `event/export`,
          responseType: "blob",
          params: { id },
        };
      },
      transformResponse: (res) => {
        var url = window.URL.createObjectURL(new Blob([res]));
        var a = document.createElement("a");
        a.href = url;
        a.download = "event-users.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
    }),
    deleteEvent: builder.mutation({
      query(id) {
        return {
          url: `event`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useDeleteEventMutation,
  useJoinEventMutation,
  useGetEventManagerQuery,
  useGetEventUserIdQuery,
  useUpdateEventMutation,
  useCreateEventMutation,
  useGetAllEventWithoutFilterQuery,
  useAcceptEventMutation,
  useApplyEventMutation,
  useRejectEventMutation,
  useGetAllEventTypeWithoutFilterQuery,
  useGetAllEventQuery,
  useGetAllEventTypeQuery,
  useGetEventQuery,
  useGetEventTypeQuery,
  useLazyGetUsersListQuery,
} = eventsApi;
export const useGetPopupSearchQuery =
  popupApi.endpoints.getPopupSearch.useQuery;
