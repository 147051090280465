import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextModal } from "shared/components";
import "./Card.scss";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const ReviewCard = ({
  img,
  firstName,
  lastName,
  description,
  className,
  position,
  title,
}) => {
  const { t } = useTranslation();
  const data = description
    .split("</p>")
    .filter(Boolean)
    .map((item) => item + "</p>");

  const [show, setShow] = useState(false);
  const handleOpenModal = (e) => {
    setShow(true);
    e.preventDefault();
  };
  const visibleData = data.slice(0, 2);
  const cardStyle = {
    height: "340px",
  };

  return (
    <>
      <div style={cardStyle} className={`reviewCard  ${className}`}>
        <div className="reviewCardTop">
          <img alt="user" src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`} />
        </div>
        <div className="reviewCardCenter">
          <h4>{title}</h4>
          {/* <p
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                ></p> */}
          {visibleData.map((item, index) => (
            <div
              className="reviewCardCenterDiv"
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
          <button onClick={(e) => handleOpenModal(e)}>{t("showMore")}</button>
        </div>

        <div className="reviewCardBottom">
          <h4>
            {firstName} {lastName}
          </h4>
          <span>{position}</span>
        </div>
      </div>
      <TextModal
        show={show}
        description={description}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default ReviewCard;
