import * as React from "react";
const SvgEye = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.982 10A2.98 2.98 0 0 1 10 12.983 2.98 2.98 0 0 1 7.016 10a2.98 2.98 0 0 1 2.983-2.983A2.98 2.98 0 0 1 12.982 10Z"
    />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.002 16.891c2.941 0 5.683-1.733 7.591-4.733.75-1.175.75-3.15 0-4.325-1.908-3-4.65-4.733-7.591-4.733-2.942 0-5.684 1.733-7.592 4.733-.75 1.175-.75 3.15 0 4.325 1.908 3 4.65 4.733 7.592 4.733Z"
    />
  </svg>
);
export default SvgEye;
