import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const newsApi = createApi({
    reducerPath: "newsApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getAllNews: builder.query({
            query(filter) {
                const { skip, take, field, orderBy, typeId } = filter;
                return {
                    url: `news/filter`,
                    params: { skip, take, orderBy, typeId, field },
                };
            },
            providesTags: ["News"],
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getNews: builder.query({
            query(id) {
                return {
                    url: `news/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
            providesTags: ["News"],
        }),
        sendNews: builder.mutation({
            query(data) {
                return {
                    url: "news/sendNews",
                    method: "POST",
                    data,
                };
            },
            invalidatesTags: ["News"],
            transformResponse: (result) => result,
        }),
        createComment: builder.mutation({
            query(data) {
                return {
                    url: "news/comment",
                    method: "POST",
                    data: data,
                };
            },
            invalidatesTags: ["Comments"],
            transformResponse: (result) => result,
        }),
        updateComment: builder.mutation({
            query(data) {
                return {
                    url: "news/comment",
                    method: "PUT",
                    data: data,
                };
            },
            invalidatesTags: ["Comments"],

            transformResponse: (result) => result,
        }),
        deleteComment: builder.mutation({
            query(id) {
                return {
                    url: "news/comment",
                    method: "DELETE",
                    data: { id: id },
                };
            },
            invalidatesTags: ["Comments"],
            transformResponse: (result) => result,
        }),
        getAllComments: builder.query({
            query(filter) {
                const { newsId, take, orderBy, sortField, skip } = filter;
                return {
                    url: `news/commentsFilter`,
                    params: { newsId, take, orderBy, sortField, skip },
                };
            },
            providesTags: ["Comments"],
            keepUnusedDataFor: 1,
            transformResponse: (result) => result?.data,
        }),

        newsLike: builder.mutation({
            query(data) {
                return {
                    url: "newslike",
                    method: "POST",
                    data,
                };
            },
            invalidatesTags: ["News"],
            transformResponse: (result) => result,
        }),
        deleteNewsLike: builder.mutation({
            query(data) {
                return {
                    url: "newslike",
                    method: "DELETE",
                    data,
                };
            },
            invalidatesTags: ["News"],
            transformResponse: (result) => result,
        }),
        newsCommentLike: builder.mutation({
            query(data) {
                return {
                    url: "newscommentlike",
                    method: "POST",
                    data,
                };
            },
            invalidatesTags: ["Comments"],
            transformResponse: (result) => result,
        }),
        deleteNewsCommentLike: builder.mutation({
            query(data) {
                return {
                    url: "newscommentlike",
                    method: "DELETE",
                    data,
                };
            },
            invalidatesTags: ["Comments"],
            transformResponse: (result) => result,
        }),

        getComments: builder.query({
            query(id) {
                return {
                    url: `news/commentById?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getFile: builder.query({
            query(commentId) {
                return {
                    url: `news/downloadNewsCommentFile?commentId=${commentId}`,
                    responseType: "blob",
                };
            },
            keepUnusedDataFor: 1,
            deserialize: (data) => {
                return data;
            },
            serialize: (data) => {
                return data;
            },

            transformResponse: (result) => result,
        }),
        createNewsView: builder.mutation({
            query(data) {
                return {
                    url: "newsview",
                    method: "POST",
                    data: data,
                };
            },
            invalidatesTags: ["News"],
            transformResponse: (result) => result,
        }),
    }),
});

export const {
    useCreateNewsViewMutation,
    useNewsLikeMutation,
    useDeleteNewsLikeMutation,
    useNewsCommentLikeMutation,
    useDeleteNewsCommentLikeMutation,
    useLazyGetFileQuery,
    useUpdateCommentMutation,
    useDeleteCommentMutation,
    useGetAllNewsQuery,
    useGetNewsQuery,
    useSendNewsMutation,
    useCreateCommentMutation,
    useLazyGetAllCommentsQuery,
    useGetAllCommentsQuery,
    useGetCommentsQuery,
} = newsApi;
