import * as React from "react";
const SvgFilter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M4.499 1.75h11c.916 0 1.666.75 1.666 1.667V5.25c0 .667-.416 1.5-.833 1.917l-3.583 3.166c-.5.417-.834 1.25-.834 1.917v3.583c0 .5-.333 1.167-.75 1.417L10 18c-1.084.667-2.584-.083-2.584-1.417v-4.416c0-.584-.333-1.334-.666-1.75L3.582 7.083c-.417-.416-.75-1.166-.75-1.666V3.5c0-1 .75-1.75 1.667-1.75ZM9.108 1.75 5 8.333"
    />
  </svg>
);
export default SvgFilter;
