import * as React from "react";
const SvgEyeSlash = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m10.897 7.102-3.795 3.795a2.682 2.682 0 1 1 3.795-3.795Z"
    />
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.366 4.327c-1.313-.99-2.813-1.53-4.365-1.53-2.648 0-5.115 1.56-6.833 4.26-.675 1.057-.675 2.835 0 3.892.593.93 1.283 1.733 2.033 2.378M6.314 14.647c.855.36 1.763.555 2.685.555 2.648 0 5.115-1.56 6.833-4.26.675-1.057.675-2.835 0-3.892-.248-.39-.518-.758-.795-1.103"
    />
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.633 9.525a2.674 2.674 0 0 1-2.115 2.115M7.103 10.898 1.5 16.501M16.5 1.5l-5.602 5.603"
    />
  </svg>
);
export default SvgEyeSlash;
