import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { v4 as uuid } from "uuid";
import Member from "shared/media/user/userIcon.png";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import Checkbox from "@mui/material/Checkbox";
import {
  RenderIf,
  Comments,
  Button,
  SearchInput,
  File,
  UserCard,
} from "shared/components";
import { updateObject } from "shared/helper";
import {
  Send,
  Send2,
  Bookmark,
  Like,
  CalendarSearch,
  Eye,
  DownArrow,
} from "shared/components/Icons";
import { getYearAndMonthNameAndDate } from "shared/helper/formatDate";
import {
  useDetailedInfoByIdQuery,
  useGetAllUsersQuery,
} from "redux/api/siteUsers";
import {
  useCreateCommentMutation,
  useSendNewsMutation,
  useLazyGetAllCommentsQuery,
  useDeleteNewsLikeMutation,
  useNewsLikeMutation,
} from "redux/api/news";
import {
  useBookmarkMutation,
  useDeleteBookmarkMutation,
} from "redux/api/bookmark";
import { custom, customStyleSecond } from "../DiscussionComment/style";
import { initialValues, initialValues2 } from "./data";
import classNames from "./NewsCard.scss";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
const { REACT_APP_BASE_PHOTO_URL } = process.env;

const NewsCard = ({
  image,
  title,
  text,
  date,
  comment,
  cardClickHandler,
  newsData,
  newsId,
  refetch,
  isLiked,
  likeCount,
  viewCount,
  isBookmarked,
}) => {
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.user.user);
  const { data } = useDetailedInfoByIdQuery({ id: id });
  const [toggle, setToggle] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const [suggestion, setSuggettion] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [modal, setModal] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
  });
  const [searchResults, setSearchResults] = useState([]);
  const { data: siteUsers, isSuccess: usersSuccess } = useGetAllUsersQuery({
    name: filterInputs,
  });
  const [createComment, { isSuccess: commentSuccess }] =
    useCreateCommentMutation();
  const [sendNews, { isSuccess: sendSuccess, isLoading: sendLoading, error }] =
    useSendNewsMutation();
  const [getCommentsByNews, { data: commentData, isLoading: commentLoading }] =
    useLazyGetAllCommentsQuery();
  const [newsLike, { isSuccess: likeSuccess }] = useNewsLikeMutation();
  const [deleteNewsLike, { isSuccess: deleteLikeSuccess }] =
    useDeleteNewsLikeMutation();
  const [bookmark, { isSuccess: bookmarkSuccess }] = useBookmarkMutation();
  const [deleteBookmark, { isSuccess: deleteSuccess }] =
    useDeleteBookmarkMutation();
  const handleTagClick = (id) => {
    setSelectedId((prevIds) => [...prevIds, id]);
  };

  let formattedMessage = inputVal;
  selectedId?.forEach((id, index) => {
    const regex = new RegExp(`@\\[.*?\\]\\(${id}\\)`, "g");
    formattedMessage = formattedMessage.replace(regex, `@${id}`);
  });
  const getImagesFromFile = (value) => {
    setFileData(value);
  };

  const handleCheckboxChange = (event, email, id) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedUserEmails((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        email,
      ]);
      setSelectedUserIds((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        id,
      ]);
    } else {
      setSelectedUserEmails((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== email)
      );
      setSelectedUserIds((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== id)
      );
    }
  };
  const filterInputsUpdate = (e) => {
    const { name, value } = e.target;
    const updateFiltersInput = updateObject(filterInputs, {
      [name]: value,
    });
    setFilterInputs(updateFiltersInput);
    let filteredUsers = [];
    if (value === "") {
      filteredUsers = [];
    } else {
      filteredUsers = siteUsers?.data?.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(updateFiltersInput[name].toLowerCase())
      );
    }
    setSearchResults(filteredUsers);
  };

  const likeNews = () => {
    const formData = new FormData();
    formData.append("NewsId", newsData);
    newsLike(formData);
  };
  const deleteLike = () => {
    const data = {
      newsId: newsData,
    };
    deleteNewsLike(data);
  };
  const saveBookmark = () => {
    const formData = new FormData();
    formData.append("NewsId", newsData);

    bookmark(formData);
  };
  const deleteBookmarked = () => {
    const data = {
      newsId: newsData,
    };
    deleteBookmark(data);
  };
  function handleLoadMore() {
    setRowsPerPage(rowsPerPage + 3);
    getCommentsByNews({
      newsId: newsId,
      skip: 0,
      take: rowsPerPage + 3,
      orderBy: false,
      sortField: "id",
    });
  }
  const formik = useFormik({
    initialValues,
    initialValues2,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (values.submitType === "POST2") {
        const comment = new FormData();
        comment.append("newsId", newsData);
        if (inputVal) {
          comment.append("description", formattedMessage);
        }
        comment.append("siteUserId", id);
        if (fileData?.length) {
          for (let i = 0; i < fileData?.length; i++) {
            comment.append(`files`, fileData?.[i]);
          }
        } else {
          comment.append(`files`, null);
        }
        let condition = !inputVal?.trim() && !fileData?.length;
        !condition && createComment(comment);
      }

      resetForm();
    },
  });
  const sendNewsPost = () => {
    const data = {
      newsId: newsData,
      emails: selectedUserEmails,
      ids: selectedUserIds,
    };
    selectedUserEmails.length && sendNews(data);
  };

  const textData = text
    .split("</p>")
    .filter(Boolean)
    .map((item) => item + "</p>");
  const visibleData = textData.slice(0, 1);
  const onHideModal = () => {
    setModal(false);
    setSelectedUserEmails([]);
    setSearchResults([]);
    setFilterInputs({
      firstName: "",
      lastName: "",
    });
  };
  useEffect(() => {
    if (sendSuccess) {
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setModal(false);
      setSelectedUserEmails([]);
      setFilterInputs({
        firstName: "",
        lastName: "",
      });
      setSearchResults([]);
    }
  }, [sendSuccess]);
  useEffect(() => {
    if (error) {
      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);

  useEffect(() => {
    if (commentSuccess) {
      setInputVal("");
      setFileData(null);

      refetch();
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [commentSuccess]);
  useEffect(() => {
    const formattedData = siteUsers?.data?.map((user) => ({
      id: user.id,
      display: `${user.firstName} ${user.lastName}`,
    }));
    setSuggettion(formattedData);
  }, [usersSuccess]);
  useEffect(() => {
    if (deleteSuccess || bookmarkSuccess || likeSuccess || deleteLikeSuccess) {
      refetch();
    }
  }, [deleteSuccess, bookmarkSuccess, likeSuccess, deleteLikeSuccess]);
  return (
    <div className="newsCard">
      <div onClick={cardClickHandler} className="newsCardOne">
        <div className="newsCardOneLeft">
          <img
            alt="news img"
            src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${image}`}
          />
        </div>
        <div className="newsCardOneRight">
          <h4>{title}</h4>
          {visibleData?.map((item, index) => (
            <div
              className="newsCardOneRightDiv"
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}

          <div className="newsCardOneRightBottom d-flex align-items-center">
            <CalendarSearch />{" "}
            <span className="mx-2">{getYearAndMonthNameAndDate(date)}</span>
          </div>
        </div>
      </div>
      <div className="newsCardTwo d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            onClick={isLiked ? deleteLike : likeNews}
            className={`${isLiked ? "like-active" : ""} newsCardTwoIcon`}>
            <Like />
            <span>
              {" "}
              {t("like")} ({likeCount})
            </span>
          </div>
          <div onClick={() => setModal(true)} className="newsCardTwoIcon">
            <Send2 />
            <span> {t("share")}</span>
          </div>
          <div
            onClick={() => {
              setToggle(!toggle);
              getCommentsByNews({
                newsId: newsId,
                skip: 0,
                take: rowsPerPage,
                orderBy: false,
                sortField: "id",
              });
            }}
            className="newsCardTwoIcon">
            <Send />
            <span>
              {" "}
              {t("comment")} ({comment?.length})
            </span>
          </div>
          <div
            onClick={isBookmarked ? deleteBookmarked : saveBookmark}
            className={`${isBookmarked ? "like-active" : ""} newsCardTwoIcon`}>
            <Bookmark />
            <span> {t("Bookmark")}</span>
          </div>
        </div>
        <div className="d-flex align-items-center newsCardOneRightBottomIcon mx-2">
          <Eye />{" "}
          <span className="mx-2">
            {viewCount} {t("view")}
          </span>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <RenderIf condition={toggle}>
          <div className="newsCardComment">
            <div className="newsCardCommentInput d-flex">
              <div className="newsCardCommentInputLeft">
                <img
                  alt="user"
                  src={
                    data?.profilePhotoUrl
                      ? `${REACT_APP_BASE_PHOTO_URL}${data?.profilePhotoUrl}`
                      : Member
                  }
                />
              </div>
              <div className="newsCardCommentInputRight">
                <MentionsInput
                  autoFocus={false}
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                  placeholder={t("writeComment")}
                  style={customStyleSecond}
                  classNames={classNames}>
                  <Mention
                    key={() => uuid()}
                    trigger="@"
                    data={suggestion}
                    onAdd={handleTagClick}
                    style={custom}
                  />
                </MentionsInput>

                <File
                  commentSuccess={commentSuccess}
                  handleImageFile={getImagesFromFile}
                />
                <button
                  className="sendButton"
                  onClick={() => formik.setFieldValue("submitType", "POST2")}
                  type="submit">
                  <Send2 />
                </button>
              </div>
            </div>

            {commentData?.map((c) => (
              <Comments
                key={c?.id}
                newsId={newsId}
                isLiked={c?.isLiked}
                likeCount={c?.likeCount}
                getCommentsByNews={getCommentsByNews}
                commentId={c?.id}
                rowsPerPage={rowsPerPage}
                siteUserId={c?.siteUserId}
                description={c?.description}
                userName={c?.siteUserName}
                userPhoto={c?.siteUserFileUrl}
                newsData={newsData}
                createDate={c?.createdDate}
                newsCommentFiles={c?.newsCommentFiles}
                refetch={refetch}
              />
            ))}
            <RenderIf condition={commentData?.length}>
              <Button
                onClick={handleLoadMore}
                className="showMoreButton mt-3"
                marginRight="8px"
                icon={<DownArrow />}>
                {t("showMore")}
              </Button>
            </RenderIf>
          </div>
        </RenderIf>
      </form>

      <Modal show={modal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("sendPost")}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="sendList">
              <SearchInput
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs?.firstName}
                setFilterInputs={setFilterInputs}
                placeholder={t("search")}
                name="firstName"
              />
              <div className="sendListBody">
                {searchResults?.map((c) => (
                  <div key={c?.id} className="sendListBodyItem d-flex">
                    <div className="sendListBodyItemLeft">
                      <UserCard
                        image={
                          c?.profilePhotoUrl
                            ? `${REACT_APP_BASE_PHOTO_URL}${c?.profilePhotoUrl}`
                            : Member
                        }
                        userName={c?.firstName}
                        userLastName={c?.lastName}
                        profession={c?.position}
                      />
                    </div>
                    <div className="sendListBodyItemRight">
                      <Checkbox
                        {...label}
                        color="success"
                        checked={selectedUserEmails.includes(c?.email)}
                        onChange={(e) =>
                          handleCheckboxChange(e, c?.email, c?.id)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="pt_14_26 font_14 align-items-center d-flex"
              onClick={sendNewsPost}
              background="#057953"
              type="submit"
              disabled={!selectedUserIds?.length || sendLoading}>
              {t("share")}
              <RenderIf condition={sendLoading}>
                <div class="spinner-border" role="status"></div>{" "}
              </RenderIf>
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default NewsCard;
