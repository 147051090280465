import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  Add,
  FilePpt,
  Filedoc,
  Filepdf,
  Filexls,
} from "shared/components/Icons";
import RenderIf from "shared/components/RenderIf";
import { docType, fileTypes, imgTypes, videoTypes } from "shared/constants/url";
import { Gallery } from "./Icons";

const FileContent = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    width: 100% !important;
    height: 340px !important;
  }
  img {
    border-radius: 8px;
    object-fit: cover;
  }
  video {
    border-radius: 8px;
    object-fit: cover;
    height: 300px;
    width: 100%;
  }
`;
const StyledDiv = styled.div`
  background: none;
  display: flex;
  flex-direction: column;

  border-radius: 12px;
  position: relative;
  z-index: 1;
  &:hover {
    .fileName {
      display: block !important;
      z-index: 999;
    }
  }
  .BtnSvg {
    display: none !important;
  }
  .fileName {
    display: none !important;
  }

  label {
    border: none;
    width: 85px;
    max-width: 85px;
    min-width: 85px;

    top: 0px;
    svg {
      display: none !important;
    }
  }
  .Btns {
    position: absolute;
    top: 8px;
    display: flex;
    flex-direction: column;

    pointer-events: none;
    .gallery {
      img {
        margin-top: 40px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: black;
    }
    .fileType {
      margin-top: 20px;
    }
    button {
      width: max-content;
      padding: 8px 24px;
      border: none;
      border-radius: 12px;
      color: #121315;
      font-weight: 500;
      margin-top: 5px;
    }
  }

  span {
    display: none;
  }
  .noPhotoYet {
    margin-top: 20px;
    max-width: 500px;
    width: 500px;
    background: rgba(18, 19, 21, 0.35);
    backdrop-filter: blur(17.5px);
    border-radius: 12px;
    p {
      text-align: center;
      color: black;
      padding: 20px;
      margin-bottom: 0px;
    }
  }
`;

export default function FileSlider({
  file,
  handleImageFile,
  multiple = true,
  handleImageID,
  commentSuccess,
  forComment,
  discussionFile,
  setDiscussionFile,
  forDiscussion,
}) {
  const allTypes = [...imgTypes, ...videoTypes];

  const [fileState, setFile] = useState([]);
  const [uplodedFile, setUploadedFile] = useState([]);
  const [deletedFilesId, setDeletedFilesId] = useState([]);
  const handleChange = (el, e) => {
    setUploadedFile([...(uplodedFile || []), ...Array.from(el)]);
    if (el) {
      const filesArray = Array.from(el).map((item) => ({
        url: URL.createObjectURL(item),
        type: item.type,
        name: item.name,
      }));
      setFile([...fileState, ...filesArray]);

      let fileList = [];
      for (let [, value] of Object.entries(el)) {
        fileList = fileList.concat(value);
      }
    }
  };

  const handleRemoveFileItem = (index, removedFile) => {
    const updatedFiles = [...fileState];
    updatedFiles.splice(index, 1);
    setFile(updatedFiles);
    const updatedUploadedFiles = uplodedFile.filter(
      (file) =>
        file.name !== removedFile.name || file.fileName !== removedFile.fileName
    );
    setUploadedFile(updatedUploadedFiles);
    if (removedFile?.id) {
      setDeletedFilesId([...deletedFilesId, removedFile.id]);
      if (forDiscussion) {
        const updatedDiscussionFile = discussionFile?.filter(
          (file) => file.id !== removedFile.id
        );
        setDiscussionFile(updatedDiscussionFile);
      }
    }
  };

  useEffect(() => {
    handleImageFile?.(uplodedFile);
    handleImageID?.(deletedFilesId);
  }, [uplodedFile]);

  useEffect(() => {
    if (file) {
      setFile(file);
      setUploadedFile([...(uplodedFile || []), ...(file || {})]);
    }
  }, [file]);

  useEffect(() => {
    if (commentSuccess) {
      setFile([]);
    }
  }, [commentSuccess]);

  return (
    <StyledDiv className="photoDownladerFile" id="drop-area">
      <div>
        <FileUploader
          multiple={multiple}
          handleChange={handleChange}
          name="fileState"
          types={forComment ? allTypes : fileTypes}
        />
        <div className="Btns">
          <button>
            <Gallery width="30px" height="30px" />
          </button>
        </div>
      </div>

      <RenderIf condition={fileState?.length}>
        <FileContent className="fileContent fileSlider">
          <div
            style={{
              width: "100%",
              height: "300px",
              position: "relative",
            }}>
            <Carousel
              emulateTouch
              swipeable
              infiniteLoop
              showArrows={true}
              showThumbs={true}
              selectedItem={fileState?.length - 1}
              className="editSlider">
              {fileState?.map((item, index) => {
                const extension = item.fileUrl
                  ? item.fileUrl.split(".").pop().toLowerCase()
                  : item.url;
                if (imgTypes?.includes(extension)) {
                  return (
                    <div className="otherPhotoImgSide" key={index}>
                      <img src={item?.fileUrl} alt="slider" />
                      <div
                        className="deleteIcon"
                        onClick={() => handleRemoveFileItem(index, item)}>
                        <Add className="deleteIcon" />
                      </div>
                    </div>
                  );
                } else if (videoTypes.includes(extension)) {
                  return (
                    <>
                      <video src={item.fileUrl} key={item?.id} controls></video>
                      <div
                        className="deleteIcon"
                        onClick={() => handleRemoveFileItem(index, item)}>
                        <Add className="deleteIcon" />
                      </div>
                    </>
                  );
                } else if (!item.file && item?.type?.startsWith("image/")) {
                  return (
                    <div className="otherPhotoImgSide" key={index}>
                      <img src={item?.url} alt="slider" />
                      <div
                        className="deleteIcon"
                        onClick={() => handleRemoveFileItem(index, item)}>
                        <Add className="deleteIcon" />
                      </div>
                    </div>
                  );
                } else if (
                  (!item.file && item?.type?.startsWith("video/")) ||
                  item?.type?.startsWith("audio/")
                ) {
                  return (
                    <div className="otherPhotoImgSide" key={index}>
                      <video
                        style={{
                          objectFit: "cover",
                          height: "300px",
                        }}
                        src={item?.url}
                        controls></video>
                      <div
                        className="deleteIcon"
                        onClick={() => handleRemoveFileItem(index, item)}>
                        <Add className="deleteIcon" />
                      </div>
                    </div>
                  );
                } else if (
                  !item.file &&
                  item?.type?.startsWith("application/")
                ) {
                  return (
                    <div className="otherPhotoImgSide" key={index}>
                      <RenderIf
                        condition={
                          item.name.endsWith("doc") ||
                          item.name.endsWith("docx")
                        }>
                        <Filedoc width="100%" height="90%" />
                      </RenderIf>
                      <RenderIf condition={item.name.endsWith("pdf")}>
                        <Filepdf width="100%" height="90%" />
                      </RenderIf>
                      <RenderIf
                        condition={
                          item.name.endsWith("pptx") ||
                          item.name.endsWith("ppt")
                        }>
                        <FilePpt width="100%" height="90%" />{" "}
                      </RenderIf>
                      <RenderIf
                        condition={
                          item.name.endsWith("xlsx") ||
                          item.name.endsWith("xls")
                        }>
                        <Filexls width="100%" height="90%" />{" "}
                      </RenderIf>
                      {/* <p key={item?.id}>{item.url}</p> */}
                      <div
                        className="deleteIcon"
                        onClick={() => handleRemoveFileItem(index, item)}>
                        <Add className="deleteIcon" />
                      </div>
                    </div>
                  );
                } else if (docType?.includes(extension)) {
                  return (
                    <div className="otherPhotoImgSide" key={index}>
                      <RenderIf
                        condition={extension === "doc" || extension === "docx"}>
                        <Filedoc width="100%" height="90%" />{" "}
                      </RenderIf>
                      <RenderIf condition={extension === "pdf"}>
                        <Filepdf width="100%" height="90%" />{" "}
                      </RenderIf>
                      <RenderIf
                        condition={extension === "pptx" || extension === "ppt"}>
                        <FilePpt width="100%" height="90%" />{" "}
                      </RenderIf>
                      <RenderIf
                        condition={extension === "xlsx" || extension === "xls"}>
                        <Filexls width="100%" height="90%" />{" "}
                      </RenderIf>
                      {/* <p className="mt-3" key={item?.id}>
                        {item.fileUrl}
                      </p> */}
                      <div
                        className="deleteIcon"
                        onClick={() => handleRemoveFileItem(index, item)}>
                        <Add className="deleteIcon" />
                      </div>
                    </div>
                  );
                }
              })}
            </Carousel>
          </div>
        </FileContent>
      </RenderIf>
    </StyledDiv>
  );
}
