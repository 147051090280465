import * as React from "react";
const SvgFilexls = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    {...props}
  >
    <g fill="none">
      <path fill="#CCC" d="M10 0h45l15 15v65H10z" />
      <path fill="#FFF" d="M11 79h58V15L55 1H11z" />
      <path fill="#CCC" d="M56 14h11.586L56 2.414V14zM55 0l15 15H55V0z" />
      <path
        fill="#999"
        d="M16 16V9h14v7H16zm17 0V9h14v7H33zm17 0V9h5v6h9v1H50zM16 26v-7h14v7H16z"
      />
      <path
        fill="#86BFA0"
        d="M33 26v-7h14v7H33zm17 0v-7h14v7H50zM33 36v-7h14v7H33zm17 0v-7h14v7H50zM33 46v-7h14v7H33zm17 0v-7h14v7H50zM33 56v-7h14v7H33zm17 0v-7h14v7H50zM33 66v-7h14v7H33zm17 0v-7h14v7H50zM33 76v-7h14v7H33zm17 0v-7h14v7H50z"
      />
      <path fill="#1F7347" d="M0 33v38l30 5V29z" />
      <path
        fill="#FFF"
        d="m18.03 44.03-2.902 6.1h-.049l-2.577-6.057-4.11.032 4.381 8.407L8 60.912l4.027.026 2.935-6.148h.042l2.927 6.185L22 61l-4.699-8.565L21.856 44z"
      />
    </g>
  </svg>
);
export default SvgFilexls;
