import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { Urls } from "shared/constants/url";
import { Cover } from "shared/modules";
import { RenderIf, Loading } from "shared/components";
import PriveRoots from "./PriveRoots";

const Layout = React.lazy(() => import("./Landing/Layout"));
const FogotPassword = React.lazy(() => import("./Landing/FogotPassword"));
const Register = React.lazy(() => import("./Landing/Register"));
const Login = React.lazy(() => import("./Landing/Login"));
const LoginConact = React.lazy(() => import("./LoginContact"));
const OtpPage = React.lazy(() => import("./Landing/OtpPage"));
const ResetPassword = React.lazy(() => import("./Landing/ResetPassword"));

const Router = () => {
  const { user } = useSelector((state) => state?.user);
  return (
    <>
      <Suspense fallback={<Loading />}>
        <RenderIf condition={!user}>
          <Routes>
            <Route path={Urls.LANDING_URL} element={<Layout />}>
              <Route
                path={Urls.LANDING_URL}
                element={<Navigate to={Urls.LOGIN_URL} replace />}
              />
              <Route path={Urls.LOGIN_URL} element={<Login />} />
              <Route
                path="*"
                element={<Navigate to={Urls.LOGIN_URL} replace />}
              />
              <Route
                path={Urls.FOGOT_PASSWORD_URL}
                element={<FogotPassword />}
              />
              <Route path={Urls.REGISTER_URL} element={<Register />} />
              <Route
                path={Urls.RESET_PASSWORD_URL + "/:token"}
                element={<ResetPassword />}
              />
              <Route path={Urls.OTP_URL} element={<OtpPage />} />
            </Route>
            <Route path={Urls.LOGIN_CONTACT_URL} element={<LoginConact />} />
          </Routes>
        </RenderIf>

        <RenderIf condition={user}>
          <Cover>
            <Suspense fallback={<Loading />}>
              <PriveRoots />
            </Suspense>
          </Cover>
        </RenderIf>
      </Suspense>
    </>
  );
};

export default Router;
