import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  token: {
    accessToken: "",
    refreshToken: "",
    expiresAt: "",
  },
};

export const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logout: () => initialState,
  },
});

export const rootReducer = persistReducer(
  {
    key: "root",
    storage,
    whitelist: ["token"],
  },
  rootSlice.reducer
);

export const { setToken, logout } = rootSlice.actions;

export default rootReducer;
