import React from "react";
import { ReactTinyLink } from "react-tiny-link";
import "./Data.scss";

const GetMetaData = ({ url }) => {
  React.useEffect(() => {
    // Component will be mounted when url changed
  }, [url]);

  return (
    <div className="dataMain">
      <ReactTinyLink
        cardSize="small"
        showGraphic={true}
        maxLine={2}
        minLine={1}
        url={url}
      />
    </div>
  );
};

export default GetMetaData;
