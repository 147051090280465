import * as React from "react";
const SvgShow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 19"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.371 9.54a2.371 2.371 0 1 1-4.742-.001 2.371 2.371 0 0 1 4.742 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.998 15.016c2.857 0 5.469-2.053 6.94-5.477-1.471-3.423-4.084-5.476-6.94-5.476h.004c-2.857 0-5.469 2.053-6.94 5.476 1.471 3.424 4.084 5.477 6.94 5.477h-.004Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgShow;
