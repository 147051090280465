import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const discussionApi = createApi({
  reducerPath: "discussionApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: ["Discussion"],
  endpoints: (builder) => ({
    createDiscussion: builder.mutation({
      query(data) {
        return {
          url: "discussion",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    editDiscussion: builder.mutation({
      query(data) {
        return {
          url: "discussion",
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
    }),
    deleteDiscussion: builder.mutation({
      query(data) {
        return {
          url: "discussion",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    sendDiscussion: builder.mutation({
      query(data) {
        return {
          url: "discussion/sendDiscussion",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    reportDiscussion: builder.mutation({
      query(data) {
        return {
          url: "discussion/report",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    sendComments: builder.mutation({
      query(data) {
        return {
          url: "discussion/comment",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    updateDiscussionComments: builder.mutation({
      query(data) {
        return {
          url: "discussion/comment",
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    deleteDiscussionComments: builder.mutation({
      query(id) {
        return {
          url: "discussion/comment",
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),

    getAllDiscussion: builder.query({
      query(filter) {
        const { top, sortBy } = filter;
        return {
          url: `discussion/filter?${top ? `&Top=${top}` : ""}${
            sortBy ? `&SortBy=${sortBy}` : ""
          }`,
        };
      },
      providesTags: ["Discussion"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getCommentsByPost: builder.query({
      query(filter) {
        const { discussionId, take, orderBy, sortField } = filter;
        return {
          url: `discussion/commentsFilter`,
          params: { discussionId, take, orderBy, sortField },
        };
      },
      providesTags: ["Discussion"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getDiscussionDetail: builder.query({
      query(id) {
        return {
          url: `discussion/byId?id=${id}`,
        };
      },
      providesTags: ["Discussion"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    discussionLike: builder.mutation({
      query(data) {
        return {
          url: "discussionlike",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    deleteDiscussionLike: builder.mutation({
      query(data) {
        return {
          url: "discussionlike",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    discussionCommentLike: builder.mutation({
      query(data) {
        return {
          url: "discussioncommentlike",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    deleteDiscussionCommentLike: builder.mutation({
      query(data) {
        return {
          url: "discussioncommentlike",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
    createPostView: builder.mutation({
      query(data) {
        return {
          url: "discussionview",
          method: "POST",
          data: data,
        };
      },
      invalidatesTags: ["Discussion"],
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useCreatePostViewMutation,
  useDiscussionCommentLikeMutation,
  useDeleteDiscussionCommentLikeMutation,
  useDeleteDiscussionLikeMutation,
  useDiscussionLikeMutation,
  useLazyGetCommentsByPostQuery,
  useGetCommentsByPostQuery,
  useGetDiscussionDetailQuery,
  useLazyGetDiscussionDetailQuery,
  useReportDiscussionMutation,
  useCreateDiscussionMutation,
  useGetAllDiscussionQuery,
  useEditDiscussionMutation,
  useDeleteDiscussionMutation,
  useSendDiscussionMutation,
  useSendCommentsMutation,
  useUpdateDiscussionCommentsMutation,
  useDeleteDiscussionCommentsMutation,
} = discussionApi;
