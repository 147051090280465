import * as React from "react";
const SvgEllipse = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <circle cx={5} cy={4.999} r={5} fill="#057953" />
  </svg>
);
export default SvgEllipse;
