import * as React from "react";
const SvgMessageQuestion = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.166 15.357h-3.333l-3.709 2.466a.83.83 0 0 1-1.291-.691v-1.775c-2.5 0-4.167-1.667-4.167-4.167v-5c0-2.5 1.667-4.167 4.167-4.167h8.333c2.5 0 4.167 1.667 4.167 4.167v5c0 2.5-1.667 4.167-4.167 4.167Z"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 5v4.167"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.996 11.668h.008"
    />
  </svg>
);
export default SvgMessageQuestion;
