import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const contributorsApi = createApi({
  reducerPath: "contributorsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getContributors: builder.query({
      query(filter) {
        const { skip, take } = filter;

        return {
          url: `contributor/filter?Skip=${skip}&Take=${take}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
  }),
});

export const { useGetContributorsQuery } = contributorsApi;
