import React from "react";
import CrocusoftLogo from "shared/media/logos/image.png";
import { Link } from "react-router-dom";
import { Urls } from "shared/constants/url";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerMain">
        <div className="footerMainTop">
          <Link to={Urls.LOGIN_CONTACT_URL}>Contact</Link>
          <Link to={Urls.POLICY_URL}>
            <span className="circle"> Policy Agreement</span>
          </Link>
        </div>
        <div className="mb-2 mb-md-0">
          <span>©2023 PASHA Hub. All rights reserved.</span>
        </div>
        <Link to="https://crocusoft.com/" target="blank" className="ms-2 link">
          <div>
            {/* <span>Designed & Developed by</span> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <span>Designed & Developed by</span>
              <img
                src={CrocusoftLogo}
                alt="Crocusoft-logo"
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "cover",
                }}
              />
              <span style={{ fontWeight: "600" }}>Crocusoft</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
