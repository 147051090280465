import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const communityApi = createApi({
  reducerPath: "communityApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllPostComments: builder.query({
      query(filter) {
        const { communityDiscussionId, take, orderBy } = filter;
        return {
          url: `community/discussion/commentsFilter?${
            communityDiscussionId
              ? `&CommunityDiscussionId=${communityDiscussionId}`
              : ""
          }${take ? `&Take=${take}` : ""}${
            orderBy ? `&OrderBy=${orderBy}` : ""
          }`,
        };
      },
      providesTags: ["Community"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getCommunities: builder.query({
      query(filter) {
        const { title } = filter;
        return {
          url: `community/filter?${title ? `Title=${title.title}` : ""}`,
        };
      },
      providesTags: ["Community"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunityUserId: builder.query({
      query(id) {
        return {
          url: `community/byUserId?id=${id}`,
        };
      },
      providesTags: ["Community"],

      keepUnusedDataFor: 1,
    }),
    getCommunity: builder.query({
      query(filter) {
        const { id, name } = filter;

        return {
          url: `community/byId?id=${id}${name ? `&Name=${name.name}` : ""}`,
        };
      },
      providesTags: ["Community"],

      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    updateCommunity: builder.mutation({
      query(form) {
        return {
          url: "community",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
      invalidatesTags: ["Community"],
    }),
    createCommunityDiscussion: builder.mutation({
      query(form) {
        return {
          url: "community/discussion",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      invalidatesTags: ["Community"],
    }),
    updateCommunityDiscussion: builder.mutation({
      query(form) {
        return {
          url: "community/discussion",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
      invalidatesTags: ["Community"],
    }),
    deleteCommunityDiscussion: builder.mutation({
      query(id) {
        return {
          url: `community/discussion/discussion`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["Community"],
    }),
    getAllCommunityDiscussion: builder.query({
      query(filter) {
        const { top, sortBy } = filter;
        return {
          url: `community/discussion/filter?Top=${top}${
            sortBy ? `&SortBy=${sortBy}` : ""
          }`,
        };
      },
      providesTags: ["Community"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),

    reportCommunityDiscussion: builder.mutation({
      query(data) {
        return {
          url: "community/report",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    applyCommunity: builder.mutation({
      query(form) {
        return {
          url: "community/apply",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    rejectCommunity: builder.mutation({
      query(form) {
        return {
          url: "community/reject",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    acceptCommunity: builder.mutation({
      query(form) {
        return {
          url: "community/accept",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    sendCommunity: builder.mutation({
      query(data) {
        return {
          url: "community/discussion/sendDiscussion",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    createCommunityDiscussionComment: builder.mutation({
      query(data) {
        return {
          url: "community/discussion/comment",
          method: "POST",
          data: data,
        };
      },
      transformResponse: (result) => result,
      invalidatesTags: ["Community"],
    }),
    updateComment: builder.mutation({
      query(data) {
        return {
          url: "community/discussion/comment",
          method: "PUT",
          data: data,
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    deleteComment: builder.mutation({
      query(id) {
        return {
          url: "community/discussion/comment",
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["Community"],

      transformResponse: (result) => result,
    }),
    joinCommunity: builder.mutation({
      query(data) {
        return {
          url: "community/joinCommunity",
          method: "DELETE",
          data: data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    joinCommunityEvent: builder.mutation({
      query(data) {
        return {
          url: "community/joinCommunityEvent",
          method: "DELETE",
          data: data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    applyCommunityEvent: builder.mutation({
      query(form) {
        return {
          url: "community/event/apply",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    rejectCommunityEvent: builder.mutation({
      query(form) {
        return {
          url: "community/event/reject",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    acceptCommunityEvent: builder.mutation({
      query(form) {
        return {
          url: "community/event/accept",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    getAllCommunityEvent: builder.query({
      query(filter) {
        const { title, communityId, eventFormat, eventTypeId } = filter;
        return {
          url: `community/event/filter?${title ? `&Title=${title}` : ""}${
            communityId ? `&CommunityId=${communityId}` : ""
          }${eventFormat ? `&EventFormat=${eventFormat}` : ""}${
            eventTypeId ? `&EventTypeId=${eventTypeId}` : ""
          }`,
        };
      },

      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunityEvent: builder.query({
      query(id) {
        return {
          url: `community/event/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createCommunityEvent: builder.mutation({
      query(form) {
        return {
          url: "community/event",
          method: "POST",
          data: form,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    updateCommunityEvent: builder.mutation({
      query(data) {
        return {
          url: "community/event",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    getAllCommunityEventTypeWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `community/event/filterType`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    deleteCommunityEvent: builder.mutation({
      query(id) {
        return {
          url: `community/event`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    getCommunityDiscussionDetail: builder.query({
      query(id) {
        return {
          url: `community/discussion/byId?id=${id}`,
        };
      },
      providesTags: ["Community"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunityDiscussionComments: builder.query({
      query(filter) {
        const { communityDiscussionId, take, orderBy, sortField } = filter;
        return {
          url: `community/discussion/commentsFilter`,
          params: { communityDiscussionId, take, orderBy, sortField },
        };
      },
      providesTags: ["Community"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    communityDiscussionLike: builder.mutation({
      query(data) {
        return {
          url: "communitydiscussionlike",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    deleteCommunityDiscussionLike: builder.mutation({
      query(data) {
        return {
          url: "communitydiscussionlike",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    communityDiscussionCommentLike: builder.mutation({
      query(data) {
        return {
          url: "communitydiscussioncommentlike",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    deleteCommunityDiscussionCommentLike: builder.mutation({
      query(data) {
        return {
          url: "communitydiscussioncommentlike",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    createCommunityView: builder.mutation({
      query(data) {
        return {
          url: "communityview",
          method: "POST",
          data: data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
    createCommunityPostView: builder.mutation({
      query(data) {
        return {
          url: "communitydiscussionview",
          method: "POST",
          data: data,
        };
      },
      invalidatesTags: ["Community"],
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useCreateCommunityViewMutation,
  useCreateCommunityPostViewMutation,
  useDeleteCommunityDiscussionCommentLikeMutation,
  useCommunityDiscussionCommentLikeMutation,
  useDeleteCommunityDiscussionLikeMutation,
  useCommunityDiscussionLikeMutation,
  useLazyGetCommunityDiscussionCommentsQuery,
  useGetCommunityDiscussionDetailQuery,
  useLazyGetCommunityDiscussionDetailQuery,
  useLazyGetAllPostCommentsQuery,
  useReportCommunityDiscussionMutation,
  useGetCommunityUserIdQuery,
  useCreateCommunityEventMutation,
  useGetAllCommunityEventTypeWithoutFilterQuery,
  useGetAllCommunityEventQuery,
  useGetCommunityEventQuery,
  useApplyCommunityEventMutation,
  useRejectCommunityEventMutation,
  useAcceptCommunityEventMutation,
  useUpdateCommunityEventMutation,
  useJoinCommunityMutation,
  useJoinCommunityEventMutation,
  useSendCommunityMutation,
  useApplyCommunityMutation,
  useRejectCommunityMutation,
  useAcceptCommunityMutation,
  useGetCommunitiesQuery,
  useGetCommunityQuery,
  useUpdateCommunityMutation,
  useDeleteCommunityEventMutation,
  useCreateCommunityDiscussionCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useCreateCommunityDiscussionMutation,
  useUpdateCommunityDiscussionMutation,
  useDeleteCommunityDiscussionMutation,
  useGetAllCommunityDiscussionQuery,
} = communityApi;
