import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "shared/components/Icons";
import { RenderIf } from "shared/components";
import "./PageTitle.scss";

const PageTitle = ({
  text,
  innerTitle,
  onBackClickHandler,
  isShared,
  button,
}) => {
  const navigate = useNavigate();

  const backButtonClickHandler = () => {
    onBackClickHandler ? onBackClickHandler() : navigate(-1);
  };

  return (
    <div className="pageTitleMain">
      <div className="d-flex align-items-center justify-content-between">
        <h1>{text}</h1>
        <RenderIf condition={isShared}>{button}</RenderIf>
      </div>
      <RenderIf condition={innerTitle}>
        <div className="pageTitleMainInner">
          <button
            type="button"
            className="pageTitleMainInnerButton"
            onClick={backButtonClickHandler}>
            <ArrowLeft />
          </button>
          <h4>{innerTitle}</h4>
        </div>
      </RenderIf>
    </div>
  );
};

export default PageTitle;
