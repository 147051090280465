import * as React from "react";
const SvgLinkSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#313538"
      d="M14.66 7.74a.782.782 0 0 0 0 1.562h.006a2.792 2.792 0 0 1 0 5.584h-3.982a2.792 2.792 0 0 1 0-5.584h.006a.781.781 0 0 0 0-1.562h-.008a4.354 4.354 0 1 0 0 8.708H14.667a4.354 4.354 0 1 0 0-8.708h-.007Zm-9.328 3.08h-.006a2.79 2.79 0 1 1 0-5.578H9.3a2.789 2.789 0 0 1 0 5.579h-.001a.782.782 0 0 0 0 1.562 4.352 4.352 0 0 0 0-8.703H5.326a4.352 4.352 0 0 0 0 8.703h.007a.782.782 0 0 0 0-1.562Z"
    />
  </svg>
);
export default SvgLinkSvg;
