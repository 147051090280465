import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./InnerHeader.scss";

const InnerHeader = ({ url, title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const navigationHandler = (url) => {
    navigate(url);
  };

  return (
    <>
      <li
        onClick={() => navigationHandler(url)}
        className={`innerHeaderUlLi ${
          pathName.startsWith(url) ? "active" : ""
        }`}>
        <Link
          className={`${pathName.startsWith(url) ? "active-color" : ""}`}
          to=""
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </li>
    </>
  );
};

export default InnerHeader;
