import * as Yup from "yup";

export const initialValues = {
  text: "",
};
export const CreateSchema = (files) => {
  return Yup.object().shape({
    text: files?.length
      ? Yup.string().nullable()
      : Yup.string()
          .min(1, "Please fill the field")
          .required("Please fill the field"),
  });
};
