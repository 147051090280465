import * as React from "react";
const SvgRightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m8.223 18.132 5.868-5.868a1.787 1.787 0 0 0 0-2.52L8.223 3.876"
    />
  </svg>
);
export default SvgRightArrow;
