import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import Member from "shared/media/user/userIcon.png";
// import LinearProgress from "@mui/material/LinearProgress";
// import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { RenderIf, Button, Btn } from "shared/components";
import { Urls, modalType } from "shared/constants/url";
import { Bookmark, CourseDone } from "shared/components/Icons";
import { RateModal, EditRateModal } from "pages/Library/modals";
import {
    useBookmarkMutation,
    useDeleteBookmarkMutation,
} from "redux/api/bookmark";
import {
    useStartCourseMutation,
    useResetCourseProgressMutation,
} from "redux/api/library";
import "./Card.scss";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

// function LinearProgressWithLabel(props) {
//   const { t } = useTranslation();

//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Box sx={{ width: "100%", mr: 1 }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box sx={{ minWidth: 35, marginTop: "10px" }}>
//         <Typography variant="body2" color="text.secondary">
//           {`${Math.round(props.value)}%`} {t("complete")}
//         </Typography>
//       </Box>
//     </Box>
//   );
// }
const CategoryCard = ({
    title,
    img,
    cardClickHandler,
    type,
    experts,
    isBookmarked,
    userRating,
    rating,
    duration,
    categories,
    id,
    refetch,
    iframeUrl,
    hubDevCourseProgress,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [bookmark, { isSuccess }] = useBookmarkMutation();
    const [startCourse, { isLoading, isSuccess: startCourseSuccess, error }] =
        useStartCourseMutation();
    const [
        resetProgress,
        { isLoading: resetLoading, isSuccess: resetSuccess, error: resetError },
    ] = useResetCourseProgressMutation();
    const [deleteBookmark, { isSuccess: deleteSuccess }] =
        useDeleteBookmarkMutation();
    const saveBookmark = () => {
        const formData = new FormData();
        formData.append("HubDevCourseId", id);

        bookmark(formData);
    };
    const deleteBookmarked = () => {
        const data = {
            hubDevCourseId: id,
        };
        deleteBookmark(data);
    };
    const openInNewTab = () => {
        window.open(iframeUrl, "_blank", "noopener,noreferrer");
    };
    useEffect(() => {
        if (isSuccess || deleteSuccess) {
            refetch && refetch();
        }
    }, [isSuccess, deleteSuccess]);
    useEffect(() => {
        if (error || resetError) {
            toast.error("Please try again !", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }, [error || resetError]);
    useEffect(() => {
        if (resetSuccess) {
            toast.success("Course reset successfully !", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setOpen(false);
        }
    }, [resetSuccess]);
    useEffect(() => {
        if (startCourseSuccess) {
            window.open(iframeUrl, "_blank", "noopener,noreferrer");
        }
    }, [startCourseSuccess]);
    return (
        <div className={`categoriesCard`}>
            <div className="categoriesCardTop">
                <div className="categoriesCardTopLeft">
                    <img
                        alt="categories img"
                        src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`}
                    />
                </div>
                <div className="categoriesCardTopCenter">
                    {rating?.toFixed(1) || 0}
                </div>
                <div className="categoriesCardTopLeft">
                    {hubDevCourseProgress?.isFinished ? (
                        <CourseDone className="doneIcon" />
                    ) : (
                        <div
                            onClick={
                                isBookmarked ? deleteBookmarked : saveBookmark
                            }
                            className={`${isBookmarked ? "like-active" : ""}`}
                        >
                            <Bookmark />
                        </div>
                    )}
                </div>
            </div>
            <div
                onClick={cardClickHandler}
                className="categoriesCardCenter d-flex flex-column h-100"
            >
                <h4 title={categories?.title}>{categories?.title}</h4>

                <p title={title} className="categoriesCardCenterText">
                    {title}
                </p>
                <div className="categoriesCardCenterEnd mt-auto">
                    <span>{type}</span>{" "}
                    <span className="minute">{duration} min</span>
                </div>
            </div>
            <div
                className={`categoriesCardBottomTop ${
                    hubDevCourseProgress?.isFinished || hubDevCourseProgress
                        ? ""
                        : "d-flex justify-content-between mt-auto"
                }`}
            >
                <RenderIf
                    condition={
                        !hubDevCourseProgress &&
                        !hubDevCourseProgress?.isFinished
                    }
                >
                    {experts?.slice(0, 1)?.map((c) => (
                        <React.Fragment key={c?.id}>
                            <div className="d-flex align-items-center">
                                <div className="categoriesCardBottomTopRight">
                                    <img
                                        src={
                                            c?.profilePhotoUrl
                                                .slice("/uploads/".length)
                                                .trim() === ""
                                                ? Member
                                                : `${REACT_APP_BASE_PHOTO_URL_MAIN}${c?.profilePhotoUrl}`
                                        }
                                        alt="member"
                                    />
                                </div>
                                <div className="categoriesCardBottomTopLeft">
                                    <p>
                                        {c?.firstName} {c?.lastName}
                                    </p>
                                    <span>{c?.position}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </RenderIf>
                <RenderIf
                    condition={
                        hubDevCourseProgress &&
                        !hubDevCourseProgress?.isFinished
                    }
                >
                    {experts?.slice(0, 1)?.map((c) => (
                        <React.Fragment key={c?.id}>
                            <div className="d-flex align-items-center">
                                <div className="categoriesCardBottomTopRight">
                                    <img
                                        src={
                                            c?.profilePhotoUrl
                                                .slice("/uploads/".length)
                                                .trim() === ""
                                                ? Member
                                                : `${REACT_APP_BASE_PHOTO_URL_MAIN}${c?.profilePhotoUrl}`
                                        }
                                        alt="member"
                                    />
                                </div>
                                <div className="categoriesCardBottomTopLeft">
                                    <p>
                                        {c?.firstName} {c?.lastName}
                                    </p>
                                    <span>{c?.position}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </RenderIf>
                <RenderIf condition={hubDevCourseProgress?.isFinished}>
                    <Box
                        sx={{
                            "& > legend": { mt: 2 },
                        }}
                    >
                        <Rating
                            readOnly
                            name="simple-controlled"
                            value={Math.round(userRating)}
                        />
                    </Box>
                    <RenderIf
                        condition={userRating > 0}
                        renderElse={
                            <button
                                onClick={() => setShow(modalType.RateModal)}
                            >
                                {t("rateCourse")}
                            </button>
                        }
                    >
                        <button
                            onClick={() => setShow(modalType.EditRateModal)}
                        >
                            {t("editrating")}
                        </button>
                    </RenderIf>
                </RenderIf>
            </div>
            <div className="categoriesCardBottom">
                <RenderIf condition={hubDevCourseProgress?.isFinished}>
                    <Btn
                        onClick={() => navigate(Urls.LIBRARY_URL + `/${id}`)}
                        className="font_14"
                        border="glassy"
                        type="submit"
                    >
                        {t("details")}
                    </Btn>
                    <Button
                        type="submit"
                        className="pt_14_26 font_14"
                        disabled={resetLoading}
                        onClick={() => setOpen(true)}
                        background="#057953"
                    >
                        {t("restart")}
                        <RenderIf condition={resetLoading}>
                            <div class="spinner-border" role="status"></div>{" "}
                        </RenderIf>
                    </Button>
                </RenderIf>
                <RenderIf
                    condition={
                        hubDevCourseProgress &&
                        !hubDevCourseProgress?.isFinished
                    }
                >
                    <Btn
                        onClick={() => navigate(Urls.LIBRARY_URL + `/${id}`)}
                        className="font_14"
                        border="glassy"
                        type="submit"
                    >
                        {t("details")}
                    </Btn>
                    <Button
                        onClick={openInNewTab}
                        type="submit"
                        className="pt_14_26 font_14 w-100"
                        background="#057953"
                    >
                        {t("continue")}
                    </Button>
                </RenderIf>
                <RenderIf
                    condition={
                        !hubDevCourseProgress &&
                        !hubDevCourseProgress?.isFinished
                    }
                >
                    <Btn
                        onClick={() => navigate(Urls.LIBRARY_URL + `/${id}`)}
                        className="font_14"
                        border="glassy"
                        type="submit"
                    >
                        {t("details")}
                    </Btn>

                    <Button
                        onClick={() => startCourse({ HubDevCourseId: id })}
                        type="submit"
                        disabled={isLoading}
                        className="pt_14_26 font_14 w-100"
                        background="#057953"
                    >
                        {t("start")}{" "}
                        <RenderIf condition={isLoading}>
                            <div class="spinner-border" role="status"></div>{" "}
                        </RenderIf>
                    </Button>
                </RenderIf>
            </div>
            <RateModal
                hubDevCourseId={id}
                rating={Math.round(userRating)}
                show={show === modalType.RateModal}
                onHide={() => setShow(false)}
            />
            <EditRateModal
                hubDevCourseId={id}
                rating={Math.round(userRating)}
                show={show === modalType.EditRateModal}
                onHide={() => setShow(false)}
            />
            <Dialog
                open={open}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("restart")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t("restartCourse")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="justify-content-center">
                    <Btn
                        onClick={() => setOpen(false)}
                        className="font_14"
                        border="glassy"
                        type="submit"
                    >
                        {t("cancel")}
                    </Btn>{" "}
                    <Button
                        type="submit"
                        className="font_14"
                        disabled={resetLoading}
                        onClick={() => resetProgress({ hubDevCourseId: id })}
                        background="#057953"
                    >
                        {t("accept")}
                        <RenderIf condition={resetLoading}>
                            <div class="spinner-border" role="status"></div>{" "}
                        </RenderIf>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CategoryCard;
