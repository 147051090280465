import { React, useState, useRef, useEffect } from "react";
import { More } from "shared/components/Icons";
import { RenderIf } from "shared/components";
import "./More.scss";

const ClickMore = ({ id, buttonTitles, background }) => {
  const [toggle, setToggle] = useState(false);

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setToggle(!toggle);
  };
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div className="moreMain" ref={menuRef}>
      <div
        style={{ background: background }}
        onClick={handleMenuClick}
        className="moreMainIcon">
        <More />
      </div>
      <RenderIf condition={toggle}>
        <div className="moreMainBottom mt-2">
          {buttonTitles?.map((c) => (
            <button
              key={c?.id}
              type="button"
              className="moreMainBottomButton text-nowrap"
              id={id ? id : ""}
              onClick={(e) => {
                e?.stopPropagation();
                c?.onClick(e);
              }}>
              {c?.title}
            </button>
          ))}
        </div>
      </RenderIf>
    </div>
  );
};

export default ClickMore;
