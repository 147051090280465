import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getContents: builder.query({
      query(filter) {
        const { contentType } = filter;
        return {
          url: `content/filter?ContentType=${contentType}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getContent: builder.query({
      query(id) {
        return {
          url: `content/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetContentQuery, useGetContentsQuery } = contentApi;
