import * as React from "react";
const SvgOrganizationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#057953" opacity={0.2} rx={6} />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M16.665 22.666h-5.333c-1.333 0-2-.666-2-2v-5.333c0-1.333.667-2 2-2h3.333v7.333c0 1.334.667 2 2 2Z"
    />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M14.739 10.667c-.054.2-.074.42-.074.667v2h-3.333V12c0-.733.6-1.333 1.333-1.333h2.074ZM17.332 13.333v3.333M20 13.333v3.333M19.332 19.333h-1.333c-.367 0-.667.3-.667.667v2.666h2.667V20c0-.367-.3-.667-.667-.667ZM12 16.667v2.667"
    />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.4}
      d="M14.668 20.666v-9.333c0-1.333.667-2 2-2h4c1.333 0 2 .667 2 2v9.333c0 1.334-.667 2-2 2h-4c-1.333 0-2-.666-2-2Z"
    />
  </svg>
);
export default SvgOrganizationIcon;
