import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Picker from "emoji-picker-react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import Member from "shared/media/user/userIcon.png";
import { RenderIf, Btn, Button, GetMetaData } from "shared/components";
import { useDetailedInfoByIdQuery } from "redux/api/siteUsers";
import { useCreateDiscussionMutation } from "redux/api/discussion";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { CreateSchema, initialValues } from "./data";
import "./PostCard.scss";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const CreatePostCard = ({ className }) => {
  const { t } = useTranslation();
  const [createDiscussion, { isSuccess, isLoading }] =
    useCreateDiscussionMutation();
  const { firstName, lastName, id, position } = useSelector(
    (state) => state.user?.user
  );
  const [show, setShow] = useState(false);
  const [link, setLink] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [files, setFiles] = useState([]);
  const { REACT_APP_BASE_PHOTO_URL } = process.env;
  const { data: detailedData } = useDetailedInfoByIdQuery({ id: id });
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (emojiObject) => {
    const emoji = emojiObject.emoji;
    const textarea = document.getElementById("text");
    const { selectionStart, selectionEnd } = textarea;
    const currentValue = textarea.value;
    const newValue =
      currentValue.slice(0, selectionStart) +
      emoji +
      currentValue.slice(selectionEnd);
    setInputVal(newValue);
    formik.setFieldValue("text", newValue);
    formik.setFieldTouched("text", true);
    formik.setErrors({ ...formik.errors, text: undefined });
    requestAnimationFrame(() => {
      textarea.selectionStart = textarea.selectionEnd =
        selectionStart + emoji.length;
      textarea.focus();
    });
  };

  const handleShow = () => setShow(true);

  const conditionCheckbox = {
    Advice: 2,
    Request: 3,
  };
  const [topping, setTopping] = useState(conditionCheckbox["Advice"]);

  const onOptionChange = (e) => {
    setTopping(conditionCheckbox[e.target.value]);
  };

  const onClick = () => {
    setLink("");
  };
  const validationSchema = CreateSchema(files);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      const formData = new FormData();
      for (let i = 0; i < files?.length; i++) {
        formData.append("Files", files[i].file);
      }
      formData.append("Text", inputVal);
      formData.append("Top", true);
      formData.append("SiteUserId", id);
      formData.append("DiscussionType", topping);
      createDiscussion(formData);
    },
  });

  const handleClose = () => {
    setShow(false);
    setInputVal("");
    setLink("");
    setFiles([]);
    setShowPicker(false);
    formik.resetForm();
  };
  const handleInputChange = (event) => {
    const newValue = event.target.value.replace(/\n/g, "\r\n");
    setInputVal(newValue);
    formik.setFieldValue("text", newValue);
  };
  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };
  React.useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <div className={`postCard ${className}`}>
      <div className="postCardOne d-flex align-items-center">
        <div className="postCardOneLeft">
          <img
            alt="user"
            src={
              detailedData?.profilePhotoUrl
                ? `${REACT_APP_BASE_PHOTO_URL}${detailedData?.profilePhotoUrl}`
                : Member
            }
          />
        </div>
        <div className="postCardOneRight">
          <button
            onClick={handleShow}
            className="w-100 text-start d-flex align-items-center">
            {t("newPost")}
          </button>
        </div>
      </div>
      <div className="postCardTwo align-items-center justify-content-between">
        <div className="postCardTwoLeft">
          <Btn border="glassy">{t("needAdvice")}</Btn>
          <Btn border="purple">{t("conveyVisdom")}</Btn>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("newPost")}</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="body-div">
              <div className="body-divTop d-flex align-items-centr">
                <div className="body-divTopImg">
                  <img
                    alt="user"
                    src={
                      detailedData?.profilePhotoUrl
                        ? `${REACT_APP_BASE_PHOTO_URL}${detailedData?.profilePhotoUrl}`
                        : Member
                    }
                  />
                </div>
                <div className="body-divTopRight mx-2">
                  <h4>
                    {firstName} {lastName}
                  </h4>
                  <span>{position}</span>
                </div>
              </div>
              <div className="body-divBody" style={{ whiteSpace: "pre-line" }}>
                <textarea
                  className="mb-0 pt_8"
                  type="text"
                  value={inputVal}
                  rows="3"
                  cols="30"
                  id="text"
                  name="text"
                  onChange={handleInputChange}
                  placeholder={t("whatTalkAbout")}
                  error={getInputClasses("text")}
                />
                <RenderIf condition={formik.errors.text} renderElse={null}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.text}</div>
                  </div>
                </RenderIf>
                <div className="body-divBodyEmoji">
                  <div
                    className="body-divBodyEmojiIcon"
                    onClick={() => setShowPicker((val) => !val)}>
                    <InsertEmoticonIcon />
                  </div>
                  <RenderIf condition={showPicker}>
                    <Picker
                      className="emojiPicker"
                      onEmojiClick={onEmojiClick}
                      size="32"
                      height={500}
                      width={400}
                    />
                  </RenderIf>
                </div>

                <RenderIf condition={link !== ""}>
                  <GetMetaData url={link} closeHandler={onClick} />
                </RenderIf>
              </div>
              <div className="body-divUpload mt-2">
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={10}
                  acceptedFileTypes={[
                    "image/*",
                    "video/*",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.ms-powerpoint",
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/pdf",
                    "audio/mpeg",
                  ]}
                  name="files"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                />
              </div>
            </div>
          </Modal.Body>

          {/* <div className="postCardTwoLeft  d-flex ">
              <input
                type="radio"
                name="topping"
                value="Advice"
                id="advice"
                checked={topping === 2}
                onChange={onOptionChange}
              />
              <label className="green-border" htmlFor="advice">
                {t("needAdvice")}
              </label>

              <input
                type="radio"
                name="topping"
                value="Request"
                id="request"
                checked={topping === 3}
                onChange={onOptionChange}
              />
              <label className="purple-border" htmlFor="request">
                {t("conveyVisdom")}
              </label>
            </div> */}
          <Modal.Footer>
            <div className="postCardTwoRight">
              <Button
                disabled={isLoading}
                type="submit"
                className="pt_10_32 mx-2 align-items-center d-flex"
                background="#057953">
                {t("post")}
                <RenderIf condition={isLoading}>
                  <div class="spinner-border" role="status"></div>{" "}
                </RenderIf>
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default CreatePostCard;
