import * as React from "react";
const SvgBookmark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.017 1.667H5.983a3.224 3.224 0 0 0-3.216 3.217v11.741c0 1.5 1.075 2.134 2.391 1.409l4.067-2.259c.433-.241 1.133-.241 1.558 0l4.067 2.259c1.317.733 2.392.1 2.392-1.409V4.884a3.237 3.237 0 0 0-3.225-3.217Z"
    />
  </svg>
);
export default SvgBookmark;
