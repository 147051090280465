import React from "react";
import "./Btn.scss";

const Btn = ({ type, onClick, icon, children, border, className }) => {
    return (
        <button
            className={`btnMain ${border}_background ${
                icon ? "align-items-center d-flex" : ""
            } ${className || ""} 
            `}
            type={type}
            onClick={onClick}
        >
            {icon}
            {children}
        </button>
    );
};

export default Btn;
