import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import { RenderIf } from "shared/components";
import { Download, ArrowRight } from "shared/components/Icons";
import { handleDownload } from "shared/helper/updateObject";
import { useLazyGetFileQuery } from "redux/api/news";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";
const Carausel = ({
  images,
  initialImageIndex,
  isOpen,
  onClose,
  forComment,
}) => {
  const sliderRef = useRef(null);
  const [getFile, { isFetching: fileLoading }] = useLazyGetFileQuery();
  const settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialImageIndex,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowRight />,
  };

  return (
    <Modal
      show={isOpen}
      className="carousel-modal"
      onHide={onClose}
      contentlabel="Example Modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className={"modal_wrap"}>
          <Slider {...settings} ref={sliderRef}>
            {images?.map((image, index) => (
              <React.Fragment key={index}>
                <RenderIf condition={forComment}>
                  <div key={image?.key} className="d-flex align-items-center">
                    <div
                      onClick={() => {
                        handleDownload(
                          image?.key,
                          image?.props?.src ||
                            image?.props?.children?.props?.src,
                          getFile
                        );
                      }}
                      className="downloadIcon mb-3 d-flex">
                      <Download />
                    </div>
                    <RenderIf condition={fileLoading}>
                      <div class="spinner-border" role="status"></div>{" "}
                    </RenderIf>
                  </div>
                </RenderIf>
                <RenderIf
                  condition={image?.type === "img"}
                  renderElse={
                    <video
                      key={index}
                      src={
                        image?.props?.children?.props?.src || image?.props?.src
                      }
                      controls></video>
                  }>
                  <img key={index} src={image?.props?.src} alt="img" />
                </RenderIf>
              </React.Fragment>
            ))}
          </Slider>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Carausel;
