import * as React from "react";
const SvgStarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <g clipPath="url(#starIcon_svg__a)">
      <path
        fill="#313538"
        d="M16 31.618c8.626 0 15.619-6.992 15.619-15.618C31.619 7.374 24.626.382 16 .382 7.375.382.383 7.374.383 16c0 8.625 6.992 15.618 15.618 15.618Z"
      />
      <path
        fill="#313538"
        d="M16.001 30.857C7.81 30.857 1.145 24.192 1.145 16S7.809 1.144 16 1.144 30.858 7.808 30.858 16s-6.665 14.857-14.857 14.857Zm0-28.903C8.256 1.954 1.955 8.255 1.955 16c0 7.745 6.301 14.046 14.046 14.046 7.745 0 14.046-6.3 14.046-14.046 0-7.745-6.3-14.046-14.046-14.046Z"
      />
      <path
        fill="currentColor"
        stroke="#313538"
        strokeWidth={1.5}
        d="M16 30.324c7.912 0 14.325-6.413 14.325-14.325 0-7.91-6.413-14.324-14.325-14.324-7.91 0-14.324 6.413-14.324 14.324 0 7.912 6.413 14.325 14.324 14.325Z"
      />
      <path
        fill="#313538"
        d="M7.346 22.275c-.83-2.226-.307-1.704-2.534-2.534 2.227-.83 1.704-.308 2.534-2.534.83 2.226.308 1.704 2.534 2.534-2.226.83-1.704.308-2.534 2.534Z"
      />
      <path
        fill="#313538"
        d="M7.345 22.636a.362.362 0 0 1-.34-.236 35.04 35.04 0 0 1-.298-.837c-.157-.456-.244-.707-.361-.824-.117-.117-.368-.204-.824-.361-.222-.078-.5-.173-.837-.3a.362.362 0 0 1 0-.677c.337-.126.614-.222.837-.3.456-.157.707-.243.824-.36.117-.118.204-.369.361-.824.077-.223.173-.5.299-.838a.362.362 0 0 1 .678 0c.126.338.222.615.299.838.157.455.244.706.361.823.118.118.368.204.824.362.223.077.5.173.837.298a.362.362 0 0 1 0 .679c-.337.126-.614.221-.837.298-.456.158-.706.245-.824.362-.117.117-.204.368-.361.823-.077.223-.173.5-.299.838a.362.362 0 0 1-.339.236ZM5.892 19.74c.987.346 1.107.466 1.453 1.453.346-.987.466-1.107 1.453-1.453-.987-.347-1.107-.466-1.453-1.453-.346.987-.466 1.106-1.453 1.453ZM18.212 27.282c-1.153-3.093-.428-2.367-3.52-3.52 3.093-1.153 2.367-.428 3.52-3.52 1.153 3.092.427 2.367 3.52 3.52-3.093 1.153-2.367.427-3.52 3.52Z"
      />
      <path
        fill="#313538"
        d="M18.21 27.784a.503.503 0 0 1-.47-.327 48.75 48.75 0 0 1-.415-1.164c-.22-.633-.34-.981-.503-1.144-.162-.163-.511-.284-1.144-.502-.31-.107-.694-.24-1.163-.415a.503.503 0 0 1 0-.943c.469-.175.854-.308 1.163-.415.633-.218.982-.339 1.144-.502.163-.163.284-.511.503-1.144.106-.31.24-.695.414-1.164a.503.503 0 0 1 .943 0c.175.47.308.855.415 1.164.219.633.34.981.502 1.144.163.163.511.284 1.144.502.31.107.695.24 1.164.415a.503.503 0 0 1 0 .943c-.47.175-.854.308-1.164.415-.633.218-.981.339-1.144.502-.163.162-.283.511-.502 1.144-.107.31-.24.694-.415 1.163a.503.503 0 0 1-.471.328Zm-2.018-4.023c1.371.48 1.538.647 2.019 2.018.48-1.371.647-1.537 2.018-2.018-1.371-.482-1.537-.648-2.018-2.02-.481 1.372-.648 1.539-2.02 2.02ZM23.49 17.657c-.978-2.623-.363-2.008-2.986-2.986 2.623-.978 2.008-.362 2.986-2.986.978 2.624.362 2.008 2.986 2.986-2.624.978-2.008.363-2.986 2.986Z"
      />
      <path
        fill="#313538"
        d="M23.49 18.082a.427.427 0 0 1-.4-.277 41.672 41.672 0 0 1-.351-.987c-.186-.537-.288-.832-.426-.97s-.434-.24-.97-.426c-.263-.09-.59-.204-.987-.352a.427.427 0 0 1 0-.8c.397-.148.724-.26.986-.351.537-.186.833-.288.971-.426s.24-.434.426-.97c.09-.263.203-.59.352-.988a.427.427 0 0 1 .8 0c.147.398.26.725.351.987.186.537.288.833.426.97.138.139.434.241.97.426.263.091.59.204.987.352a.427.427 0 0 1 0 .8 41.24 41.24 0 0 1-.987.352c-.536.185-.832.287-.97.425-.138.139-.24.434-.426.97-.09.263-.204.59-.352.988a.427.427 0 0 1-.4.277Zm-1.712-3.412c1.163.408 1.304.55 1.712 1.712.408-1.163.55-1.304 1.713-1.712-1.164-.408-1.305-.549-1.713-1.712-.408 1.163-.549 1.304-1.712 1.712ZM12.565 14.201c-1.245-3.337-.462-2.554-3.8-3.799 3.338-1.244 2.555-.46 3.8-3.798 1.244 3.337.46 2.554 3.798 3.798-3.337 1.245-2.554.462-3.798 3.8Z"
      />
      <path
        fill="#313538"
        d="M12.568 14.743a.543.543 0 0 1-.508-.353 51.827 51.827 0 0 1-.448-1.256c-.236-.683-.366-1.059-.542-1.235-.176-.175-.552-.305-1.235-.541-.334-.116-.75-.26-1.255-.448a.543.543 0 0 1 0-1.017c.506-.189.921-.333 1.255-.448.683-.236 1.06-.366 1.235-.542.176-.176.306-.552.542-1.235.115-.334.259-.75.448-1.255a.543.543 0 0 1 1.017 0c.188.506.332.921.447 1.255.237.683.367 1.06.542 1.235.176.176.552.306 1.235.542.334.115.75.259 1.256.448a.543.543 0 0 1 0 1.017 52.88 52.88 0 0 1-1.256.447c-.683.237-1.059.367-1.235.542-.175.176-.305.552-.542 1.235-.115.334-.259.75-.447 1.256a.543.543 0 0 1-.509.353ZM10.39 10.4c1.48.52 1.659.699 2.178 2.179.52-1.48.699-1.66 2.178-2.179-1.48-.519-1.659-.698-2.178-2.178-.519 1.48-.699 1.66-2.178 2.178ZM21.01 10.019a.362.362 0 0 1-.362-.362V8.105a.362.362 0 0 1 .724 0v1.552c0 .2-.162.362-.362.362Z"
      />
      <path
        fill="#313538"
        d="M21.785 9.243h-1.552a.362.362 0 1 1 0-.724h1.552a.362.362 0 1 1 0 .724ZM15.983 17.658a.362.362 0 0 1-.362-.362v-1.552a.362.362 0 1 1 .724 0v1.552c0 .2-.162.362-.362.362Z"
      />
      <path
        fill="#313538"
        d="M16.758 16.881h-1.552a.362.362 0 0 1 0-.724h1.552a.362.362 0 0 1 0 .724Z"
      />
      <path
        fill="currentColor"
        d="M20.042 27.776a.382.382 0 0 1-.128-.742c.321-.114.642-.244.952-.387a.382.382 0 1 1 .318.695c-.33.151-.671.29-1.014.412a.38.38 0 0 1-.128.022ZM22.82 26.361a.381.381 0 0 1-.216-.697A11.717 11.717 0 0 0 27.71 16c0-2.913-1.082-5.707-3.045-7.867a.382.382 0 0 1 .565-.514A12.437 12.437 0 0 1 28.474 16a12.48 12.48 0 0 1-5.439 10.294.38.38 0 0 1-.215.067ZM22.87 6.439a.38.38 0 0 1-.217-.068 11.724 11.724 0 0 0-2.1-1.157.382.382 0 1 1 .298-.703c.783.332 1.536.746 2.237 1.232a.382.382 0 0 1-.218.696Z"
      />
    </g>
    <defs>
      <clipPath id="starIcon_svg__a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStarIcon;
