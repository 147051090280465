import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Index = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100%",
                minHeight: "80vh",
            }}
        >
            <CircularProgress style={{ color: "#057953" }} />
        </Box>
    );
};

export default Index;
