import moment from "moment";

export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-").toString();
};

export function calculateTimeAgo(recordDateTime) {
  const currentMoment = moment();
  const recordMoment = moment(recordDateTime);
  const duration = moment.duration(currentMoment.diff(recordMoment));

  if (duration.asMinutes() >= 1) {
    if (duration.asMonths() >= 1) {
      return `${Math.floor(duration.asMonths())} month ago`;
    } else if (duration.asWeeks() >= 1) {
      return `${Math.floor(duration.asWeeks())} week ago`;
    } else if (duration.asDays() >= 1) {
      return `${Math.floor(duration.asDays())} day ago`;
    } else if (duration.asHours() >= 1) {
      return `${Math.floor(duration.asHours())} hours ago`;
    } else {
      return `${Math.floor(duration.asMinutes())} minutes ago`;
    }
  } else {
    return "now";
  }
}
export function getYearAndMonthNameAndDate(datetime) {
  const dateObj = new Date(datetime);
  const day = dateObj.getDate();
  const monthName = dateObj.toLocaleString("default", { month: "long" });
  const year = dateObj.getFullYear();
  return `${day} ${monthName},${year}`;
}
