import * as React from "react";
const SvgSend = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M7.084 15.833h-.417c-3.333 0-5-.833-5-5V6.666c0-3.333 1.667-5 5-5h6.667c3.333 0 5 1.667 5 5v4.167c0 3.333-1.667 5-5 5h-.417a.845.845 0 0 0-.667.333L11 17.834c-.55.734-1.45.734-2 0l-1.25-1.666c-.133-.184-.441-.334-.666-.334Z"
    />
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.33 9.167h.008M9.996 9.167h.008M6.662 9.167h.008"
    />
  </svg>
);
export default SvgSend;
