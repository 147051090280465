import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextModal } from "shared/components";
import "./Card.scss";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const StoryCard = ({
  img,
  title,
  description,
  position,
  firstName,
  lastName,
}) => {
  const { t } = useTranslation();

  const data = description
    .split("</p>")
    .filter(Boolean)
    .map((item) => item + "</p>");

  const [show, setShow] = useState(false);
  const handleOpenModal = (e) => {
    setShow(true);
    e.preventDefault();
  };
  const visibleData = data.slice(0, 2);

  return (
    <>
      <div className="storyCard">
        <div className="storyCardLeft col-lg-5">
          <img alt="user" src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`} />
          <div className="blur"></div>
        </div>
        <div className="storyCardRight col-lg-7">
          <h3>{title}</h3>
          {visibleData?.map((item, index) => (
            <div
              className="reviewCardCenterDiv"
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
          <button onClick={(e) => handleOpenModal(e)}>{t("showMore")}</button>
          <div className="bottom">
            <h4>
              {firstName} {lastName}
            </h4>
            <span>{position}</span>
          </div>
        </div>
      </div>
      <TextModal
        show={show}
        description={description}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default StoryCard;
