import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { RenderIf } from "shared/components";
import { Add, Gallery } from "shared/components/Icons";
import { imgTypes, videoTypes } from "shared/constants/url";
import "./File.scss";

const allTypes = [...imgTypes, ...videoTypes];

export default function File({
  file,
  handleImageFile,
  multiple = true,
  commentSuccess,
  handleImageID,
}) {
  const [fileState, setFile] = useState([]);
  const [uplodedFile, setUploadedFile] = useState(null);
  const [deletedFilesId, setDeletedFilesId] = useState([]);

  const handleChange = (el, e) => {
    setUploadedFile([...(uplodedFile || []), ...Array.from(el)]);
    if (el) {
      const filesArray = Array.from(el).map((item) => ({
        url: URL.createObjectURL(item),
        type: item.type,
        name: item.name,
      }));
      setFile([...fileState, ...filesArray]);

      let fileList = [];
      for (let [, value] of Object.entries(el)) {
        fileList = fileList.concat(value);
      }
    }
  };

  const handleRemoveFileItem = (index, el) => {
    const removeItem = fileState.filter((_, fileIndex) => fileIndex !== index);
    setUploadedFile(
      uplodedFile.filter(
        (element) =>
          element?.name !== el?.name || element?.fileName !== el?.fileName
      )
    );
    setFile(removeItem);
    if (el?.id) {
      setDeletedFilesId([...deletedFilesId, el?.id]);
    }
  };

  useEffect(() => {
    handleImageFile?.(uplodedFile);
    handleImageID?.(deletedFilesId);
  }, [uplodedFile]);

  useEffect(() => {
    if (file) {
      setFile(file);
      setUploadedFile([...(uplodedFile || []), ...file]);
    }
  }, [file]);

  useEffect(() => {
    if (commentSuccess) {
      setFile(null);
      setUploadedFile(null);
    }
  }, [commentSuccess]);

  return (
    <div>
      <div className="photoDownladerFile styleDiv" id="drop-area">
        <div className="">
          <FileUploader
            multiple={multiple}
            handleChange={handleChange}
            name="file"
            types={allTypes}
          />
          <div className="Btns">
            <button>
              {" "}
              <Gallery width="30px" height="30px" />
            </button>
          </div>
        </div>
      </div>
      <RenderIf condition={fileState?.length}>
        <div className="fileContent fileSlider">
          <Carousel
            emulateTouch
            swipeable
            infiniteLoop
            showArrows={true}
            showThumbs={true}
            className="editSlider"
            selectedItem={fileState?.length - 1}>
            {fileState?.map((el, index) => {
              return (
                <div className="otherPhotoImgSide" key={index}>
                  <RenderIf condition={el?.type.startsWith("image/")}>
                    <img src={el.url} alt="slider" />
                  </RenderIf>
                  <RenderIf condition={el?.type.startsWith("video/")}>
                    <video src={el.url} controls></video>
                  </RenderIf>
                  <div
                    className="deleteIcon"
                    onClick={() => handleRemoveFileItem(index, el)}>
                    <Add className="deleteIcon" />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </RenderIf>
    </div>
  );
}
