import React, { useState, useRef } from "react";
import { Map, YMaps, Placemark } from "react-yandex-maps";
import { Link } from "react-router-dom";
import { Call, Location, Message, Printer } from "shared/components/Icons";
import GreenMarker from "./marker.png";
import Pasha from "shared/media/phCard/phLogo.png";
import Hub from "shared/media/phCard/hubLogo.png";
import "./Card.scss";
const PhCard = ({ src, lon, lan, id, email, fax, phone, location, title }) => {
  const [showMap, setShowMap] = useState(false);
  const cardRef = useRef(null);
  const images = [
    { id: 1, src: Pasha },
    { id: 2, src: Hub },
  ];

  return (
    <div ref={cardRef} className="phCard">
      <div className="phCardTop">
        <div className="innerDiv">
          <div className={`image ${showMap ? "hidden" : ""}`}>
            {images?.map((c) => (
              <img key={c?.id} alt="logo" className="cardImg" src={c?.src} />
            ))}
          </div>
          <div className="map">
            <YMaps
              query={{
                lang: "en-AZ",
              }}>
              <Map
                width="100%"
                height="100%"
                modules={["control.FullscreenControl"]}
                defaultState={{
                  center: [lan, lon],
                  type: "yandex#map",
                  zoom: 9,
                  controls: ["fullscreenControl"],
                }}>
                <Placemark
                  key={id}
                  geometry={[lan, lon]}
                  defaultOptions={{
                    iconLayout: "default#image",
                    iconImageHref: GreenMarker,
                    iconImageSize: [30, 45],
                  }}
                  properties={{
                    iconContent: `<div class="custom-marker"></div>`,
                  }}
                />
              </Map>
            </YMaps>
          </div>
        </div>

        <h1>{title}</h1>
        <ul className="phCardTopUl list-unstyled">
          <li className="phCardTopUlLi">
            <Link to="">
              <div className="svgDiv">
                <Location />
              </div>
              <span>{location}</span>
            </Link>
          </li>
          <li className="phCardTopUlLi">
            <Link to="">
              <div className="svgDiv">
                <Message />
              </div>
              <span>{email}</span>
            </Link>
          </li>
          <li className="phCardTopUlLi">
            <Link to="">
              <div className="svgDiv">
                <Call />
              </div>
              <span>{phone}</span>
            </Link>
          </li>
          <li className="phCardTopUlLi">
            <Link to="">
              <div className="svgDiv">
                <Printer />
              </div>
              <span>{fax}</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PhCard;
