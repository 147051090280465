import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { az } from "date-fns/locale";

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 56px;
  border-radius: var(--space-x1);
  border: none;
  padding: 12px 24px;
  padding-left: 40px;
  color: #495673;
  background: #f5f5f8;
`;
const StyledDiv = styled.div`
  position: relative;
  svg {
    position: absolute;
    margin-top: -37px;
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 12px;
  }
`;
const DatePickers = ({
  onChange,
  selected,
  excludeDates,
  placeholderText,
  minDate,
  maxDate,
  readOnly,
  value,
  dateFormat,
}) => {
  return (
    <StyledDiv>
      <StyledDatePicker
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        selected={selected}
        placeholderText={placeholderText}
        excludeDates={excludeDates}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        readOnly={readOnly}
        showPopperArrow={false}
        locale={az}
        value={value}
        dateFormat={dateFormat}
        showMonthYearPicker
        yearDropdownItemNumber={50}
        showYearDropdown
      />
      <img
        alt=""
        className="datepicker-img"
        src="/media/svg/icons/Design/calendar-right.svg"
      />
    </StyledDiv>
  );
};

export default DatePickers;
