import React from "react";
import { Link } from "react-router-dom";
import { Button, RenderIf } from "shared/components";
import "./Message.scss";

const Message = ({
    img,
    title,
    buttonContent,
    describtion,
    buttonColor,
    backgroundColor,
    link,
}) => {
    return (
        <div
            style={{ background: backgroundColor }}
            className="messageMain justify-content-between align-items-center"
        >
            <div className="messageMainLeft d-flex ">
                <div className="messageMainLeftOne">
                    <img alt="user" src={img} />
                </div>
                <div className="messageMainLeftTwo">
                    <h4>{title}</h4>
                    <p>
                        {describtion}
                        <Link className="mx-3">Learn more</Link>
                    </p>
                </div>
            </div>
            <RenderIf condition={buttonContent}>
                <div className="messageMainRight">
                    <Link to={link} target="_blank">
                        <Button
                            background={buttonColor}
                            className="font_16 pt_14_32"
                        >
                            {buttonContent}
                        </Button>
                    </Link>
                </div>
            </RenderIf>
        </div>
    );
};

export default Message;
