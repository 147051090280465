import * as React from "react";
const SvgNotification = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M10.018 2.425c-2.759 0-5 2.241-5 5v2.408c0 .508-.217 1.284-.476 1.717l-.958 1.591c-.592.984-.183 2.075.9 2.442 3.592 1.2 7.467 1.2 11.059 0a1.669 1.669 0 0 0 .9-2.441l-.959-1.592c-.25-.434-.466-1.209-.466-1.717V7.425c0-2.75-2.25-5-5-5Z"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M11.558 2.667a5.628 5.628 0 0 0-3.083 0 1.654 1.654 0 0 1 1.541-1.05c.7 0 1.3.433 1.542 1.05Z"
    />
    <path
      stroke="#313538"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M12.518 15.883c0 1.375-1.125 2.5-2.5 2.5a2.509 2.509 0 0 1-1.767-.733 2.509 2.509 0 0 1-.733-1.767"
    />
  </svg>
);
export default SvgNotification;
