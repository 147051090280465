import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const badgeApi = createApi({
    reducerPath: "badgeApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getBadges: builder.query({
            query(filter) {
                const { name } = filter;
                return {
                    url: `badge/filter?${name ? `Name=${name.name}` : ""}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getBadge: builder.query({
            query(id) {
                return {
                    url: `badge/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
    }),
});

export const { useGetBadgeQuery, useGetBadgesQuery } = badgeApi;
