import React from "react";
import { RenderIf } from "shared/components";
import "./Index.scss";

const Input = React.forwardRef(
  (
    {
      type,
      placeholder,
      name,
      value,
      disabled,
      accept,
      readOnly,
      onChange,
      label,
      required,

      searchButton,

      id,
      defaultValue,
      className,
    },
    ref
  ) => {
    return (
      <div className="inputs">
        <RenderIf condition={label}>
          <label>
            <RenderIf condition={required} renderElse={<>{label}</>}>
              <>
                {label}
                <span className="text-danger" style={{ fontWeight: 600 }}>
                  *
                </span>
              </>
            </RenderIf>
          </label>
        </RenderIf>

        <div style={{ position: "relative" }}>
          <input
            className={`${searchButton ? `padding` : ""} ${
              className ? className : ""
            }`}
            placeholder={placeholder}
            name={name}
            id={id}
            accept={accept}
            ref={ref}
            type={type}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
            autoComplete="new-password"
            style={{
              backgroundColor: disabled ? "hsl(0, 0%, 95%)" : "#ffff",
              cursor: disabled ? "not-allowed" : "auto",
            }}
          />
        </div>
      </div>
    );
  }
);

export default Input;
