import * as React from "react";
const SvgItemLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="#313538"
      d="M20.526 10.834a1.094 1.094 0 0 0 0 2.188h.008a3.909 3.909 0 0 1 0 7.817h-5.575a3.909 3.909 0 0 1 0-7.817h.009a1.093 1.093 0 0 0-.001-2.188h-.01a6.096 6.096 0 1 0 0 12.193H20.534a6.096 6.096 0 1 0 0-12.193h-.01Zm-13.06 4.314H7.46a3.905 3.905 0 1 1 0-7.81h5.562a3.904 3.904 0 0 1 0 7.81h-.001a1.094 1.094 0 0 0 0 2.188 6.093 6.093 0 0 0 0-12.186H7.457a6.093 6.093 0 0 0 0 12.186h.01a1.094 1.094 0 0 0 0-2.188Z"
    />
  </svg>
);
export default SvgItemLink;
