import { modalType } from "shared/constants/url";
import { Story, FallingStar, Saturn, LinkSvg } from "shared/components/Icons";

export const data = [
  {
    id: 1,
    icon: <Story />,
    text: "newStage",
    modal: modalType.NewStage,
  },
  {
    id: 2,
    icon: <FallingStar />,
    text: "mydevitem",
    modal: modalType.MyDevItem,
  },
  {
    id: 3,
    icon: <Saturn />,
    text: "additionalItem",
    modal: modalType.MyDevFile,
  },
  {
    id: 4,
    icon: <LinkSvg />,
    text: "link",
    modal: modalType.MyDevLink,
  },
];
