import * as React from "react";
const SvgLogin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#E40000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.158 6.93c.284-3.3 1.98-4.647 5.693-4.647h.119c4.097 0 5.738 1.641 5.738 5.739v5.976c0 4.098-1.64 5.739-5.738 5.739h-.12c-3.684 0-5.38-1.33-5.683-4.575M1.834 11h11.807"
    />
    <path
      stroke="#E40000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11.596 7.93 3.07 3.07-3.07 3.071"
    />
  </svg>
);
export default SvgLogin;
