import * as React from "react";
const SvgHome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11 16.5v-2.75M9.23 2.585 2.879 7.673c-.715.568-1.173 1.769-1.017 2.667l1.219 7.297c.22 1.302 1.466 2.356 2.786 2.356h10.267c1.31 0 2.567-1.064 2.787-2.356l1.219-7.297c.146-.898-.312-2.099-1.018-2.667L12.77 2.594c-.981-.788-2.567-.788-3.538-.009Z"
    />
  </svg>
);
export default SvgHome;
