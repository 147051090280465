import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const bookmarkApi = createApi({
  reducerPath: "bookmarkApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllBookmark: builder.query({
      query(filter) {
        const {
          skip,
          take,
          field,
          orderBy,
          isHubDevCourse,
          isDiscussion,
          isCommunityDiscussion,
          isNews,
        } = filter;
        return {
          url: `bookmark/filter`,
          params: {
            skip,
            take,
            field,
            orderBy,
            isHubDevCourse,
            isDiscussion,
            isCommunityDiscussion,
            isNews,
          },
        };
      },
      providesTags: ["Bookmark"],

      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    bookmark: builder.mutation({
      query(data) {
        return {
          url: "bookmark",
          method: "POST",
          data,
        };
      },
      invalidatesTags: [
        "Hubdev",
        "News",
        "Discussion",
        "Community",
        "Bookmark",
      ],
      transformResponse: (result) => result,
    }),
    deleteBookmark: builder.mutation({
      query(data) {
        return {
          url: "bookmark",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: [
        "Hubdev",
        "News",
        "Discussion",
        "Community",
        "Bookmark",
      ],
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useBookmarkMutation,
  useLazyGetAllBookmarkQuery,
  useGetAllBookmarkQuery,
  useDeleteBookmarkMutation,
} = bookmarkApi;
