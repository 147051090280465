import storage from "redux-persist/lib/storage";
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
const initialState = {
  lang: "en",
};
export const langSlice = createSlice({
  initialState,
  name: "langSlice",
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});
export const reducer = persistReducer(
  {
    key: "MG:Lang",
    storage,
    whitelist: ["lang"],
  },
  langSlice.reducer
);
export default reducer;
export const { setLang } = langSlice.actions;
