import * as React from "react";
const SvgFolderDoc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <g clipPath="url(#folderDoc_svg__a)">
      <g filter="url(#folderDoc_svg__b)">
        <path
          fill="#4876F9"
          fillRule="evenodd"
          d="M6.017 2.332c-.81 0-1.466.657-1.466 1.467v20.4c0 .81.656 1.466 1.466 1.466h15.967c.81 0 1.467-.656 1.467-1.466V8.165l-5.833-5.833h-11.6Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#folderDoc_svg__c)">
        <path
          fill="currentColor"
          d="m12.233 19.833 1.758-6.441h.032l1.746 6.44h.981l2.21-7.729h-.992l-1.692 6.485h-.043l-1.747-6.485h-.959l-1.757 6.485h-.043l-1.692-6.485h-.992l2.21 7.73h.98Z"
        />
      </g>
      <path
        fill="url(#folderDoc_svg__d)"
        d="M22.903 7.62h-4.739l5.287 5.286v-4.74l-.548-.547Z"
      />
      <path
        fill="#B5C8FC"
        d="M19.083 8.166h4.366l-5.833-5.833v4.366c0 .81.657 1.467 1.467 1.467Z"
      />
    </g>
    <defs>
      <filter
        id="folderDoc_svg__b"
        width={18.898}
        height={23.517}
        x={4.551}
        y={2.24}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-0.183} />
        <feGaussianBlur stdDeviation={0.046} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3674_29889" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.183} />
        <feGaussianBlur stdDeviation={0.046} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="effect1_innerShadow_3674_29889"
          result="effect2_innerShadow_3674_29889"
        />
      </filter>
      <filter
        id="folderDoc_svg__c"
        width={18.918}
        height={18.729}
        x={8.043}
        y={11.104}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={4} dy={6} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3674_29889"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend
          in2="effect1_dropShadow_3674_29889"
          result="effect2_dropShadow_3674_29889"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_3674_29889"
          result="shape"
        />
      </filter>
      <linearGradient
        id="folderDoc_svg__d"
        x1={19.349}
        x2={24.635}
        y1={6.434}
        y2={11.721}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.2} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <clipPath id="folderDoc_svg__a">
        <path fill="currentColor" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFolderDoc;
