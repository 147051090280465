export const initialValues = {
  newsId: "",
  description: "",
  siteUserId: "",
  files: [],
};
export const initialValues2 = {
  newsId: "",
  emails: [],
};
