import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const popupApi = createApi({
    reducerPath: "popupApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getPopups: builder.query({
            query(filter) {
                const { name } = filter;
                return {
                    url: `popup/filter${name ? `Name=${name.name}` : ""}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getPopupSearch: builder.query({
            query(filter) {
                const { name } = filter;
                return {
                    url: `popup/search?${name ? `Name=${name.name}` : ""}`,
                };
            },

            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getPopup: builder.query({
            query(id) {
                return {
                    url: `popup/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
    }),
});

export const {
    useGetPopupQuery,
    useGetPopupsQuery,
    useLazyGetPopupSearchQuery,
    useGetPopupSearchQuery,
} = popupApi;
