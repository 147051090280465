import React from "react";
import { RenderIf } from "shared/components";
import { StarIcon } from "shared/components/Icons";
import "./Card.scss";

const LibraryCard = ({ image, title, svg, className, isEditText, onClick }) => {
  return (
    <div title={title} onClick={onClick} className={`libraryCard ${className}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <RenderIf condition={image}>
            <img alt="logo" src={`${image}`} />
          </RenderIf>
          <RenderIf condition={svg}>
            <div className="libraryCardIcon">
              <StarIcon />
            </div>
          </RenderIf>
          <RenderIf condition={!isEditText}>
            <h4>{title}</h4>
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default LibraryCard;
