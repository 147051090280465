import * as React from "react";
const SvgLike = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.667 8.334v8.333m0-8.333H3.333v8.333h3.334m0-8.333 4.33-5.052a1.667 1.667 0 0 1 1.669-.532l.04.01a1.667 1.667 0 0 1 .982 2.541l-2.021 3.033h3.8c1.052 0 1.84.962 1.634 1.993l-1 5c-.156.78-.84 1.34-1.634 1.34h-7.8"
    />
  </svg>
);
export default SvgLike;
