import * as React from "react";
const SvgScore = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#057953" opacity={0.2} rx={6} />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m17.155 10.34 1.174 2.346c.16.327.586.64.946.7l2.127.354c1.36.226 1.68 1.213.7 2.186l-1.653 1.654c-.28.28-.434.82-.347 1.206l.473 2.047c.374 1.62-.486 2.247-1.92 1.4l-1.993-1.18c-.36-.213-.953-.213-1.32 0l-1.993 1.18c-1.427.847-2.294.213-1.92-1.4l.473-2.047c.087-.386-.067-.926-.347-1.206l-1.653-1.654c-.973-.973-.66-1.96.7-2.186l2.127-.354c.353-.06.78-.373.94-.7l1.173-2.346c.64-1.274 1.68-1.274 2.313 0Z"
    />
  </svg>
);
export default SvgScore;
