export const Urls = {
  HOME_URL: "/",
  ABOUT_URL: "/about",
  NEWS_URL: "/news",
  CONTACT_URL: "/contact",
  DISCUSSIONS_URL: "/discussions",
  REVIEWS_STORIES_URL: "/about/reviews-stories",
  ABOUT_US_URL: "/about/about-us",
  CONTRIBUTORS_URL: "/about/contributors",
  VALUE_ETHICS_URL: "/about/values-ethics",
  PARTNERS_URL: "/about/partners",
  EVENTS_URL: "/events",
  COMMUNITY_URL: "/community",
  LIBRARY_URL: "/hubdev",
  SETTINGS_URL: "/settings",
  NOTIFICATIONS_URL: "/notifications",
  MESSAGES_URL: "/messages",
  PROFILE_URL: "/profile",
  POSTS_URL: "/profile/posts",
  PROFILE_ABOUT_URL: "/profile/profile-about",
  INTERESTED_EVENTS_URL: "/profile/interested-events",
  FOLLOWED_COMMUNITIES_URL: "/profile/followed-communities",
  LOGIN_URL: "/login",
  OTP_URL: "/otp",
  FOGOT_PASSWORD_URL: "/forgot-password",
  REGISTER_URL: "/register",
  RESET_PASSWORD_URL: "/reset-password",
  LOGIN_CONTACT_URL: "/login-contact",
  POLICY_URL: "/policy",
  LANDING_URL: "/",
  SEARCH_RESULTS_URL: "/search-results",
  SUGGESTION_URL: "/suggestion",
};
export const genderConverterToBoolean = {
  male: true,
  female: false,
};

export const imgTypes = [
  "tif",
  "tiff",
  "bmp",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "raw",
  "jfif",
  "svg",
];
export const videoTypes = [
  "3gp",
  "avi",
  "flv",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogg",
  "rm",
  "vob",
  "webm",
  "wmv",
  "mp3",
];
export const fileTypes = [
  "tif",
  "tiff",
  "bmp",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "raw",
  "jfif",
  "3gp",
  "avi",
  "flv",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogg",
  "rm",
  "vob",
  "webm",
  "wmv",
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xlsx",
  "xls",
  "pdf",
  "mp3",
];
export const docType = ["pdf", "doc", "docx", "txt", "pptx", "xlsx"];
export const mainDocType = ["doc", "docx", "txt", "pptx", "xlsx", "csv"];

export const modalType = {
  RateModal: "RateModal",
  EditRateModal: "EditRateModal",
  SendCourse: "SendCourse",
  MyDevContent: "MyDevContent",
  MyDevCourse: "MyDevCourse",
  MyDevImage: "MyDevImage",
  MyDevItem: "MyDevItem",
  MyDevLink: "MyDevLink",
  SendPlayList: "SendPlayList",
  NewStage: "NewStage",
  MyDevFile: "MyDevFile",
};
export const Accordions = {
  PlayListAccordion: "PlayListAccordion",
  Ongoing: "Ongoing",
};
export const Tabs = {
  CourseTab: "CourseTab",
  ContentTab: "ContentTab",
};
export const itemType = {
  HubDevCourse: "HubDevCourse",
  HubDevFile: "HubDevFile",
  HubDevLink: "HubDevLink",
  HubDevDiscussion: "HubDevDiscussion",
  HubDevNews: "HubDevNews",
  HubDevCommunityDiscussion: "HubDevCommunityDiscussion",
};
