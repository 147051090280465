import rootReducer from "pages/slice";
import userReducer from "./feature/user";
import roleReducer from "./feature/role";
import interestReducer from "./feature/interest";
import tabReducer from "./feature/tabs";
import langReducer from "./feature/language";
import resultSlice from "./feature/searchResults";
import { apiSiteUser } from "./api";
import { userApi } from "./api/authApi/userApi";
import { authApi } from "./api/authApi";
import { interestsApi } from "./api/interestApi";
import { teamApi } from "./api/team";
import { contributorsApi } from "./api/contributors";
import { valueAndEthicApi } from "./api/valueAndEthic";
import { contentApi } from "./api/content";
import { contactApi } from "./api/contact";
import { newsApi } from "./api/news";
import { eventsApi } from "./api/events";
import { reviewAndStoryApi } from "./api/reviewAndStory";
import { libraryApi } from "./api/library";
import { discussionApi } from "./api/discussion";
import { suggestApi } from "./api/suggest";
import { partnersApi } from "./api/partners";
import { activityApi } from "./api/activity";
import { communityApi } from "./api/community";
import { notificationApi } from "./api/notification";
import { additionalLinksApi } from "./api/additionalLinks";
import { popupApi } from "./api/popup";
import { badgeApi } from "./api/badge";
import { bookmarkApi } from "./api/bookmark";

export const reducer = {
  root: rootReducer,
  result: resultSlice,
  tab: tabReducer,
  user: userReducer,
  lang: langReducer,
  interestSlicer: interestReducer,
  rolesSlicer: roleReducer,
  [suggestApi.reducerPath]: suggestApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [valueAndEthicApi.reducerPath]: valueAndEthicApi.reducer,
  [contributorsApi.reducerPath]: contributorsApi.reducer,
  [apiSiteUser.siteUserApi.reducerPath]: apiSiteUser.siteUserApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [interestsApi.reducerPath]: interestsApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [libraryApi.reducerPath]: libraryApi.reducer,
  [discussionApi.reducerPath]: discussionApi.reducer,
  [reviewAndStoryApi.reducerPath]: reviewAndStoryApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [additionalLinksApi.reducerPath]: additionalLinksApi.reducer,
  [popupApi.reducerPath]: popupApi.reducer,
  [badgeApi.reducerPath]: badgeApi.reducer,
  [bookmarkApi.reducerPath]: bookmarkApi.reducer,
};

export const middleWares = [
  bookmarkApi.middleware,
  partnersApi.middleware,
  activityApi.middleware,
  apiSiteUser.siteUserApi.middleware,
  userApi.middleware,
  contentApi.middleware,
  suggestApi.middleware,
  authApi.middleware,
  contactApi.middleware,
  libraryApi.middleware,
  communityApi.middleware,
  contributorsApi.middleware,
  teamApi.middleware,
  discussionApi.middleware,
  newsApi.middleware,
  valueAndEthicApi.middleware,
  interestsApi.middleware,
  eventsApi.middleware,
  reviewAndStoryApi.middleware,
  notificationApi.middleware,
  additionalLinksApi.middleware,
  popupApi.middleware,
  badgeApi.middleware,
];
