import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const teamApi = createApi({
  reducerPath: "teamApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllTeam: builder.query({
      query(filter) {
        return {
          url: `team/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
  }),
});

export const { useGetAllTeamQuery } = teamApi;
