import React from "react";
import { Link } from "react-router-dom";

import "./Card.scss";

const UserCard = ({ image, userName, userLastName, profession, link }) => {
  return (
    <div className="userCard">
      <div className="userCardLeft">
        <Link to={link}>
          <img src={image} alt="user" />
        </Link>
      </div>
      <div className="userCardRight">
        <Link to={link}>
          <p>
            {userName} {userLastName}
          </p>
          <span>{profession}</span>
        </Link>
      </div>
    </div>
  );
};

export default UserCard;
