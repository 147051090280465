import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Index({ onChange, value }) {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ header: 1 }, { header: 2 }],
    [{ align: "justify" }, { align: "center" }, { align: "right" }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link", "formula"],
  ];

  return (
    <ReactQuill
      onChange={onChange}
      value={value}
      modules={{
        toolbar: toolbarOptions,
      }}
      placeholder="Text..."
    />
  );
}
