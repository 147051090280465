import React from "react";
import { Link } from "react-router-dom";
import { Urls } from "shared/constants/url";
import Member from "shared/media/user/userIcon.png";

const SearchResult = ({ data }) => {
  const { REACT_APP_BASE_PHOTO_URL } = process.env;
  return data?.map((c) => (
    <Link to={Urls.POSTS_URL + `/${c?.id}`}>
      <div className="sendListBodyItem d-flex">
        <div className="sendListBodyItemLeft">
          <div className="Left">
            <img
              alt="user"
              src={
                c?.profilePhotoUrl
                  ? `${REACT_APP_BASE_PHOTO_URL}${c?.profilePhotoUrl}`
                  : Member
              }
            />
          </div>
          <div className="Right">
            <h4>
              {c?.firstName} {c?.lastName}
            </h4>
            <p>{c?.position}</p>
          </div>
        </div>
      </div>
    </Link>
  ));
};

export default SearchResult;
