import React from "react";
import "./Button.scss";

const Button = ({
  type,
  onClick,
  icon,
  children,
  background,
  className,
  disabled,
  marginRight,
}) => {
  return (
    <button
      className={`buttonMain  ${
        icon ? "align-items-center d-flex icon" : ""
      }  ${className || ""} 
            `}
      style={{ background: background, marginRight: marginRight }}
      type={type}
      onClick={onClick}
      disabled={disabled}>
      {icon}
      {children}
    </button>
  );
};

export default Button;
