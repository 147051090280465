import { createApi } from "@reduxjs/toolkit/query/react";
import { setToken } from "pages/slice";
import { setUniqueKey } from "redux/feature/user";
import { APIBaseQuery } from "../axiosBase";

export const siteUserApi = createApi({
  reducerPath: "siteUserApi",
  baseQuery: APIBaseQuery,
  endpoints(builder) {
    return {
      forgotPassword: builder.mutation({
        query(data) {
          return {
            url: "siteUser/forgotPassword",
            method: "PUT",
            data,
          };
        },
      }),
      updateUserPassword: builder.mutation({
        query(data) {
          return {
            url: "siteUser/changePassword",
            method: "PUT",
            data: data,
          };
        },
        transformResponse: (result) => result,
      }),
      resetPassword: builder.mutation({
        query(data) {
          return {
            url: "siteUser/resetPassword",
            method: "PUT",
            data: data,
          };
        },
        transformResponse: (result) => result,
      }),
      addUserPhoto: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addPhoto",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],
      }),
      addUserInterest: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addInterest",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],
      }),
      userRegister: builder.mutation({
        query(data) {
          return {
            url: "siteUser/register",
            method: "POST",
            data,
          };
        },
        transformResponse: (result) => result,
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const {
              data: { expiresAt, refreshToken, token },
            } = await queryFulfilled;
            await dispatch(
              setToken({
                accessToken: token,
                refreshToken,
                expiresAt,
              })
            );
          } catch (error) {}
        },
      }),
      updateUser: builder.mutation({
        query(data) {
          return {
            url: "siteUser/update",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],
      }),
      permissions: builder.query({
        query() {
          return {
            url: "siteUser/permissions",
            method: "GET",
          };
        },
      }),
      detailedInfoById: builder.query({
        query(filter) {
          const { id } = filter;
          return {
            url: `siteUser/byId`,
            method: "GET",
            params: { id: id },
          };
        },
        transformResponse: (result) => result,
        providesTags: ["User"],
      }),
      removeUserInterest: builder.mutation({
        query(data) {
          return {
            url: "siteUser/removeInterest",
            method: "DELETE",
            data,
          };
        },
        invalidatesTags: ["User"],
      }),
      createAboutInfoUser: builder.mutation({
        query(data) {
          return {
            url: "siteUser/createAboutInfo",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      createContactInfoUser: builder.mutation({
        query(data) {
          return {
            url: "siteUser/createContactInfo",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      createTrainingUser: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addTraining",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      updateTrainingUser: builder.mutation({
        query(data) {
          return {
            url: "siteUser/updateTraining",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      deleteTrainingUser: builder.mutation({
        query(id) {
          return {
            url: "siteUser/removeTraining",
            method: "DELETE",
            data: { id: id },
          };
        },
        invalidatesTags: ["User"],
      }),
      createLanguage: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addLanguage",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      updateLanguage: builder.mutation({
        query(data) {
          return {
            url: "siteUser/updateLanguage",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      deleteLanguage: builder.mutation({
        query(id) {
          return {
            url: "siteUser/deleteLanguage",
            method: "DELETE",
            data: { id: id },
          };
        },
        invalidatesTags: ["User"],
      }),
      createEducation: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addEducation",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      updateEducation: builder.mutation({
        query(data) {
          return {
            url: "siteUser/updateEducation",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      deleteEducation: builder.mutation({
        query(id) {
          return {
            url: "siteUser/removeEducation",
            method: "DELETE",
            data: { id: id },
          };
        },
        invalidatesTags: ["User"],
      }),
      addWorkExperience: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addWorkExperience",
            method: "POST",
            data,
          };
        },
        transformResponse: (result) => result,
        invalidatesTags: ["User"],
      }),
      updateWorkExperience: builder.mutation({
        query(data) {
          return {
            url: "siteUser/updateWorkExperience",
            method: "PUT",
            data,
          };
        },
        transformResponse: (result) => result,
        invalidatesTags: ["User"],
      }),
      deleteWorkExperience: builder.mutation({
        query(id) {
          return {
            url: "siteUser/deleteWorkExperience",
            method: "DELETE",
            data: { id: id },
          };
        },
        invalidatesTags: ["User"],
      }),
      addSkill: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addSkill",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      addSkillEndor: builder.mutation({
        query(data) {
          return {
            url: "siteUser/addSkillEndor",
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      sendOtpCode: builder.mutation({
        query(data) {
          return {
            url: "siteUser/verifyOtp",
            method: "POST",
            data,
          };
        },
        transformResponse: (result) => result,
      }),
      updateSkill: builder.mutation({
        query(data) {
          return {
            url: "siteUser/updateSkill",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      deleteSkill: builder.mutation({
        query(id) {
          return {
            url: "siteUser/deleteSkill",
            method: "DELETE",
            data: { id: id },
          };
        },
        invalidatesTags: ["User"],
      }),
      deleteSkillEndor: builder.mutation({
        query(id) {
          return {
            url: "siteUser/deleteSkillEndor",
            method: "DELETE",
            data: { id: id },
          };
        },
        invalidatesTags: ["User"],
      }),
      getAllUsers: builder.query({
        query(data) {
          const { name, take } = data;
          return {
            url: `siteUser/filter?${
              name?.firstName ? `&FirstName=${name?.firstName}` : ""
            }${name?.lastName ? `&LastName=${name?.lastName}` : ""} ${
              take ? `&Take=${take}` : ""
            }`,
          };
        },
        providesTags: ["User"],

        transformResponse: (result) => result,
      }),
      getAllUsersWithoutFilter: builder.query({
        query(data) {
          return {
            url: `siteUser/filter`,
          };
        },
        providesTags: ["User"],

        transformResponse: (result) => result,
      }),
      getCompletedProfile: builder.query({
        query() {
          return {
            url: `siteUser/completedProfile`,
          };
        },
        providesTags: ["User"],
        transformResponse: (result) => result,
      }),
      getCompanies: builder.query({
        query() {
          return {
            url: `siteUser/companies`,
          };
        },
        providesTags: ["User"],

        transformResponse: (result) => result,
      }),
      getUniqueKey: builder.query({
        query() {
          return {
            url: `siteUser/uniqueKey`,
          };
        },
        transformResponse: (result) => result,
      }),
      updateIsOnboard: builder.mutation({
        query(data) {
          return {
            url: "siteUser/updateIsOnboard",
            method: "PUT",
            data,
          };
        },
        invalidatesTags: ["User"],

        transformResponse: (result) => result,
      }),
      getDepartments: builder.query({
        query(filter) {
          const { skip, take, name, sortField, orderBy } = filter;

          return {
            url: `departments/filter`,
            params: { skip, take, name, sortField, orderBy },
          };
        },
        keepUnusedDataFor: 1,
        transformResponse: (result) => result?.data,
      }),
      getAllCompanies: builder.query({
        query(filter) {
          const { skip, take, name, sortField, orderBy } = filter;

          return {
            url: `company/filter`,
            params: { skip, take, name, sortField, orderBy },
          };
        },
        keepUnusedDataFor: 1,
        transformResponse: (result) => result?.data,
      }),
      getPositions: builder.query({
        query(filter) {
          const { skip, take, name, sortField, orderBy } = filter;

          return {
            url: `position/filter`,
            params: { skip, take, name, sortField, orderBy },
          };
        },
        keepUnusedDataFor: 1,
        transformResponse: (result) => result?.data,
      }),
      getDomains: builder.query({
        query(filter) {
          const { skip, take, name, sortField, orderBy } = filter;

          return {
            url: `acceptabledomain/filter`,
            params: { skip, take, name, sortField, orderBy },
          };
        },
        keepUnusedDataFor: 1,
        transformResponse: (result) => result?.data,
      }),
    };
  },
});

export const {
  useGetDomainsQuery,
  useGetAllCompaniesQuery,
  useGetDepartmentsQuery,
  useGetPositionsQuery,
  useUpdateIsOnboardMutation,
  useGetUniqueKeyQuery,
  useSendOtpCodeMutation,
  useResetPasswordMutation,
  useGetCompaniesQuery,
  useGetCompletedProfileQuery,
  useAddSkillEndorMutation,
  useDeleteSkillEndorMutation,
  useGetAllUsersWithoutFilterQuery,
  useGetAllUsersQuery,
  useUpdateUserMutation,
  useDetailedInfoByIdQuery,
  useLazyDetailedInfoByIdQuery,
  useRemoveUserInterestMutation,
  useForgotPasswordMutation,
  useUserRegisterMutation,
  useAddUserPhotoMutation,
  useAddUserInterestMutation,
  usePermissionsQuery,
  useUpdateUserPasswordMutation,
  useCreateAboutInfoUserMutation,
  useCreateContactInfoUserMutation,
  useCreateTrainingUserMutation,
  useUpdateTrainingUserMutation,
  useCreateLanguageMutation,
  useCreateEducationMutation,
  useUpdateLanguageMutation,
  useDeleteLanguageMutation,
  useUpdateEducationMutation,
  useDeleteEducationMutation,
  useDeleteTrainingUserMutation,
  useAddWorkExperienceMutation,
  useUpdateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
  useAddSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
} = siteUserApi;
