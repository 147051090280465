import * as React from "react";
const SvgFilepdf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#CCC" d="M10 0h45v15h15v65H10z" />
      <path fill="#FFF" d="M11 1h44v14h14v64H11z" />
      <path fill="#E75A5A" fillRule="nonzero" d="m55 0 15 15H55z" />
      <path
        fill="#666"
        fillRule="nonzero"
        d="M28 72.672V64h2.361c1.047 0 1.8.177 2.256.53.457.354.686.935.686 1.743 0 .922-.313 1.645-.938 2.168-.625.524-1.492.786-2.601.786h-.545v3.445H28zm1.219-4.377h.498c.73 0 1.295-.168 1.693-.504.399-.336.598-.81.598-1.424 0-.52-.156-.89-.469-1.113-.312-.223-.834-.334-1.564-.334h-.756v3.375zm6.914 4.377V64h2.883c.859 0 1.553.096 2.083.287.529.192.987.516 1.374.973.613.726.92 1.683.92 2.87 0 1.442-.381 2.56-1.143 3.352-.762.793-1.834 1.19-3.217 1.19h-2.9zm1.23-.92h1.576c1.125 0 1.922-.303 2.391-.908.504-.645.756-1.51.756-2.596 0-1.02-.248-1.82-.744-2.402a2.29 2.29 0 0 0-1.078-.73c-.418-.13-1.047-.196-1.887-.196h-1.014v6.832zm9.258.92V64h4.846v.92h-3.615v2.906h3.035v.908h-3.035v3.938h-1.23z"
      />
      <path
        fill="#DA0000"
        fillRule="nonzero"
        d="M44.477 38.62a44.911 44.911 0 0 1 6.97-.543c4.119 0 6.947 1.374 7.275 3.4.345 2.138-2.122 3.487-5.611 3.143-3.425-.337-6.548-2.01-9.142-4.395a43.54 43.54 0 0 0-6.069 1.518c-1.39 2.684-3.033 5.088-5.089 7.498-2.719 3.187-5.785 4.52-7.611 2.698-2.175-2.17-.696-5.367 4.124-8.061a46.018 46.018 0 0 1 7.517-3.362c.933-1.874 1.75-3.899 2.508-6.178a24.827 24.827 0 0 1-1.75-3.816c-.733-2.049-1.11-4.72-.937-6.86.217-2.672 1.261-4.412 3.225-4.412 3.342 0 3.922 5.18 1.835 12.476a77.91 77.91 0 0 1-.736 2.432 22.87 22.87 0 0 0 3.49 4.462zm-1.794.319a24.274 24.274 0 0 1-2.316-2.948 48.874 48.874 0 0 1-1.568 3.902 45.01 45.01 0 0 1 3.884-.954zM31.67 48.267c1.602-1.878 2.943-3.75 4.107-5.767a44.645 44.645 0 0 0-5.72 2.687c-4.08 2.281-5.07 4.42-3.798 5.69.965.962 3.188-.004 5.411-2.61zm19.776-8.69c-1.86 0-3.698.119-5.507.35 2.169 1.745 4.66 2.939 7.319 3.2 2.658.262 4.124-.54 3.983-1.41-.178-1.099-2.321-2.14-5.795-2.14zm-12.434-9.56c.276.771.602 1.543.974 2.303.098-.33.197-.665.294-1.006 1.83-6.402 1.365-10.564-.393-10.564-.943 0-1.568 1.042-1.73 3.033-.156 1.922.192 4.381.855 6.233z"
      />
    </g>
  </svg>
);
export default SvgFilepdf;
