import * as React from "react";
const SvgNote2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.856 9.57-.899 3.832c-.77 3.309-2.291 4.647-5.151 4.372a9.623 9.623 0 0 1-1.485-.247l-1.54-.367c-3.823-.907-5.005-2.796-4.107-6.627l.898-3.841c.184-.78.404-1.458.679-2.017 1.072-2.218 2.897-2.814 5.958-2.09l1.531.357c3.84.899 5.014 2.796 4.116 6.628Z"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.805 17.775c-.569.385-1.284.706-2.154.99l-1.449.477c-3.639 1.173-5.555.192-6.737-3.447l-1.174-3.62C1.118 8.534 2.09 6.61 5.73 5.436l1.448-.477c.376-.12.734-.22 1.073-.284-.275.559-.495 1.237-.679 2.016l-.898 3.841c-.898 3.832.284 5.72 4.107 6.628l1.54.366c.531.129 1.027.211 1.485.248ZM11.586 7.818l4.446 1.128M10.688 11.367l2.658.678"
    />
  </svg>
);
export default SvgNote2;
