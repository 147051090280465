import React from "react";
import Modal from "react-bootstrap/Modal";
import "./index.scss";

const TextModal = ({ description, show, setShow, onHide }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>
                <div
                    className="textModalMain"
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                ></div>
            </Modal.Body>
        </Modal>
    );
};

export default TextModal;
