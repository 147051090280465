import React from "react";
import { SideMenu, Header } from "shared/layout";
import "./Cover.scss";

const Cover = ({ children }) => {
  return (
    <div className="d-flex flex-column flex-root">
      <SideMenu />
      <div className="d-flex flex-column flex-row-fluid wrapper">
        <Header />
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="body-wrapper">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
