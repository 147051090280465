import * as React from "react";
const SvgPeople = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 5.967a.504.504 0 0 0-.158 0 2.144 2.144 0 0 1-2.067-2.15A2.15 2.15 0 1 1 15 5.967ZM14.142 12.033c1.142.192 2.4-.008 3.284-.6 1.175-.783 1.175-2.066 0-2.85-.892-.591-2.167-.791-3.309-.591M4.973 5.967a.504.504 0 0 1 .159 0 2.144 2.144 0 0 0 2.066-2.15c0-1.192-.958-2.15-2.15-2.15a2.15 2.15 0 0 0-.075 4.3ZM5.833 12.033c-1.142.192-2.4-.008-3.284-.6-1.175-.783-1.175-2.066 0-2.85.892-.591 2.167-.791 3.309-.591M10 12.192a.507.507 0 0 0-.158 0 2.144 2.144 0 0 1-2.067-2.15 2.15 2.15 0 1 1 4.3 0c-.008 1.166-.925 2.116-2.075 2.15ZM7.575 14.817c-1.175.783-1.175 2.067 0 2.85 1.333.891 3.516.891 4.85 0 1.175-.784 1.175-2.067 0-2.85-1.325-.883-3.517-.883-4.85 0Z"
    />
  </svg>
);
export default SvgPeople;
