import * as React from "react";
const SvgRotateLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <mask
      id="rotate-left_svg__a"
      width={18}
      height={18}
      x={0.775}
      y={1.875}
      fill="currentColor"
      maskUnits="userSpaceOnUse"
    >
      <path fill="currentColor" d="M.775 1.875h18v18h-18z" />
      <path d="M7.592 4.233A8.35 8.35 0 0 1 10 3.875a7.223 7.223 0 0 1 7.225 7.225A7.223 7.223 0 0 1 10 18.325 7.223 7.223 0 0 1 2.775 11.1c0-1.483.45-2.867 1.217-4.017" />
    </mask>
    <path
      fill="#057953"
      d="M7.163 2.796a1.5 1.5 0 0 0 .859 2.875l-.86-2.875ZM5.24 7.916A1.5 1.5 0 1 0 2.744 6.25L5.24 7.915ZM8.022 5.67A6.85 6.85 0 0 1 10 5.375v-3a9.849 9.849 0 0 0-2.837.421l.859 2.875ZM10 5.375a5.723 5.723 0 0 1 5.725 5.725h3A8.723 8.723 0 0 0 10 2.375v3Zm5.725 5.725A5.723 5.723 0 0 1 10 16.825v3a8.723 8.723 0 0 0 8.725-8.725h-3ZM10 16.825A5.723 5.723 0 0 1 4.275 11.1h-3A8.723 8.723 0 0 0 10 19.825v-3ZM4.275 11.1c0-1.176.356-2.272.965-3.185L2.744 6.251A8.724 8.724 0 0 0 1.275 11.1h3Z"
      mask="url(#rotate-left_svg__a)"
    />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.832 3.814 8.24 1.047M5.832 3.814l2.808 2.05"
    />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M11.08 8.666H8.92c-1.254 0-1.35 1.127-.674 1.74l3.507 3.187c.677.613.58 1.74-.673 1.74H8.92c-1.254 0-1.35-1.127-.674-1.74l3.507-3.187c.677-.613.58-1.74-.673-1.74Z"
    />
  </svg>
);
export default SvgRotateLeft;
