import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const additionalLinksApi = createApi({
  reducerPath: "additionalLinksApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getLinks: builder.query({
      query() {
        return {
          url: `additionalLink/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getLink: builder.query({
      query(id) {
        return {
          url: `additionalLink/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
  }),
});

export const { useGetLinkQuery, useGetLinksQuery } = additionalLinksApi;
