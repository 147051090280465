import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import { Call } from "shared/components/Icons";
import { ReactSelect, RenderIf } from "shared/components";
import {
  useCreateSuggestMutation,
  useGetAllComplainReasonQuery,
} from "redux/api/suggest";
import "./SuggestForm.scss";
import "react-toastify/dist/ReactToastify.css";

const SuggestForm = () => {
  const { t } = useTranslation();

  const {
    data: complainReason,
    isSuccess: resasonSuccess,
    isLoading: reasonLoading,
  } = useGetAllComplainReasonQuery();

  const form = useRef(null);
  const complainReasons = complainReason?.data;

  const [complainReasonOption, setComplainReasonOption] = React.useState(null);

  const complainReasonOptions = complainReasons?.map((regional) => ({
    value: regional.id,
    label: regional.title,
  }));

  function handleChangeReason(value) {
    setComplainReasonOption(value);
    formik.setFieldValue("complaintReasonId", value?.value);
  }

  const initialValues = {
    email: "",
    phone: "",
    text: "",
    complaintReasonId: "",
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [createSuggestion, { isLoading, error, isSuccess }] =
    useCreateSuggestMutation();

  const CreateSchema = Yup.object().shape({
    email: Yup.string().required("Please fill the field"),
    text: Yup.string().required("Please fill the field"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostSuggest = (values, resetForm, setSubmitting) => {
    const asset = new FormData(form.current);
    asset.append("complaintReasonId", values.complaintReasonId);

    createSuggestion(asset);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostSuggest(values, resetForm, setSubmitting);
      resetForm();
    },
  });
  useEffect(() => {
    if (resasonSuccess) {
      handleChangeReason();
    }
  }, [reasonLoading]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setComplainReasonOption(null);
    } else if (error) {
      setComplainReasonOption(null);

      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [isLoading]);

  return (
    <div className="form-wrapper">
      <form onSubmit={formik.handleSubmit} ref={form}>
        <h4>{t("suggestionContent")}</h4>

        <div className="mt-3">
          <input
            id="email"
            name="email"
            label="username@gmail.com"
            placeholder="username@gmail.com"
            className="input-design"
            error={getInputClasses("email")}
            {...formik.getFieldProps("email")}
            onKeyDown={handleEnter}
          />
        </div>
        <RenderIf
          condition={formik.touched.email && formik.errors.email}
          renderElse={null}>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.email}</div>
          </div>
        </RenderIf>

        <div className="mt-3 input-position mb-3">
          <ReactInputMask
            className="input-design"
            mask="999-999-99-99"
            placeholder={t("phoneNumber")}
            maskChar=""
            id="phone"
            name="phone"
            onChange={formik.handleChange}
            {...formik.getFieldProps("phone")}
            error={getInputClasses("phone")}>
            {(inputProps) => (
              <input {...inputProps} type="tel" disableUnderline />
            )}
          </ReactInputMask>
          <Call />
        </div>
        <RenderIf
          condition={formik.touched.phone && formik.errors.phone}
          renderElse={null}>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.phone}</div>
          </div>
        </RenderIf>
        <div className="w-100">
          <ReactSelect
            isSearchable={false}
            options={complainReasonOptions}
            onChange={handleChangeReason}
            single={complainReasonOption}
            placeholder={t("suggestion")}
            instanceId="regionalCenterId"
          />
        </div>

        <RenderIf
          condition={
            formik.touched.complaintReasonId && formik.errors.complaintReasonId
          }
          renderElse={null}>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {formik.errors.complaintReasonId}
            </div>
          </div>
        </RenderIf>

        <div className="mt-3">
          <textarea
            rows="5"
            placeholder={t("text")}
            className="input-design"
            id="text"
            name="text"
            label="username@gmail.com"
            error={getInputClasses("text")}
            {...formik.getFieldProps("text")}
            onKeyDown={handleEnter}></textarea>
          <RenderIf
            condition={formik.touched.text && formik.errors.text}
            renderElse={null}>
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.text}</div>
            </div>
          </RenderIf>
        </div>

        <div className="form-btn">
          <button
            disabled={isLoading}
            className="formbold-btn d-flex align-items-center">
            {t("reportIt")}
            <RenderIf condition={isLoading}>
              <div class="spinner-border" role="status"></div>{" "}
            </RenderIf>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SuggestForm;
