import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const contactApi = createApi({
    reducerPath: "contactApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getContactInfo: builder.query({
            query() {
                return {
                    url: `contact/filter`,
                };
            },
            keepUnusedDataFor: 1,
        }),
    }),
});

export const { useGetContactInfoQuery } = contactApi;
