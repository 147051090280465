import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { logout } from "redux/feature/user";
import { Notification, Menu, Dote, Login } from "shared/components/Icons";
import { SearchInput, RenderIf, SearchResult } from "shared/components";
import { Urls } from "shared/constants/url";
import CrocusoftLogo from "shared/media/logos/image.png";
import { useDetailedInfoByIdQuery } from "redux/api/siteUsers";
import { useGetNotificationsQuery } from "redux/api/notification";
import { useGetLinksQuery } from "redux/api/additionalLinks";
import PashaHubLogo from "shared/media/logos/PashaHubLogo.png";
import NotificationLogo from "shared/media/notification.png";
import NotificationGif from "shared/media/notification.gif";
import { updateObject } from "shared/helper/updateObject";
import { useLazyGetPopupSearchQuery } from "redux/api/popup";
import ReturnerData from "./data";
import "./Header.scss";
const { REACT_APP_BASE_PHOTO_URL, REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const Header = () => {
  const { data } = ReturnerData();
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: SiteUserId } = useSelector((state) => state?.user?.user);
  const { data: userInfo } = useDetailedInfoByIdQuery({ id: SiteUserId });

  const { data: notificationData } = useGetNotificationsQuery({
    SiteUserId,
  });
  const { data: linkData } = useGetLinksQuery();

  const [isVisible, setVisibility] = useState(false);
  const [isMobileMenuVisible, setVisibilityMobileMenu] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [filterInputs, setFilterInputs] = useState({
    name: "",
  });

  const toggle = () => {
    isMobileMenuVisible && setVisibilityMobileMenu(false);
    setVisibility(!isVisible);
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const clickInnerPage = (event) => {
    event.preventDefault();
    const encodedQuery = encodeURIComponent(searchQuery);
    navigate(Urls.SEARCH_RESULTS_URL + `/${encodedQuery}`);
    isMobileMenuVisible && setVisibilityMobileMenu(false);
    setVisibility(!isVisible);
  };
  const clickPage = () => {
    navigate(Urls.POSTS_URL + `/${SiteUserId}`);
    isMobileMenuVisible && setVisibilityMobileMenu(false);
    setVisibility(!isVisible);
  };
  useEffect(() => {
    document.body.style.overflow = isMobileMenuVisible ? "hidden" : "scroll";
  }, [isMobileMenuVisible]);
  const containerRef = useRef();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        showMenu
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showMenu]);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setShowMenu(true);
    const updateFiltersInput = updateObject(filterInputs, {
      [event.target.name]: event.target.value,
    });
    setFilterInputs(updateFiltersInput);
    getPopupSearch({
      name: { name: encodeURIComponent(searchQuery) },
    });
  };
  const handleSearchSubmit = (event) => {
    event && event.preventDefault();
    const encodedQuery = encodeURIComponent(searchQuery);
    navigate(Urls.SEARCH_RESULTS_URL + `/${encodedQuery}`);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(event);
    }
  };
  const [getPopupSearch, { data: mainData, refetch, isSuccess }] =
    useLazyGetPopupSearchQuery();
  const slicedResults = mainData?.siteUsers?.slice(0, 5);
  useEffect(() => {
    searchQuery &&
      getPopupSearch({
        name: { name: encodeURIComponent(searchQuery) },
      });
  }, [searchQuery]);

  return (
    <div className="top-main">
      <div className="main-header">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="w-60 d-md-block header-titles">
            <Link
              className={`${pathName === Urls.HOME_URL ? "title-active" : ""}`}
              to={Urls.HOME_URL}>
              {t("home")}
            </Link>
            <Link
              className={`${
                pathName === Urls.ABOUT_US_URL ? "title-active" : ""
              }`}
              to={Urls.ABOUT_URL}>
              {t("about-us")}
            </Link>
            <Link
              className={`${
                pathName === Urls.CONTACT_URL ? "title-active" : ""
              } ${pathName === Urls.SUGGESTION_URL ? "title-active" : ""}`}
              to={Urls.CONTACT_URL}>
              {t("contact")}
            </Link>
          </div>
          <div className="d-md-flex align-items-center position-relative">
            <div className="mx-4 search-div">
              <SearchInput
                filterInputsUpdate={handleSearchChange}
                filterInputs={filterInputs?.name}
                setFilterInputs={setFilterInputs}
                placeholder={t("search")}
                name="name"
                handleKeyPress={handleKeyPress}
              />
              <RenderIf condition={filterInputs && showMenu}>
                <div ref={containerRef} className="sendList">
                  <div className="sendListBody">
                    <SearchResult data={slicedResults} />
                  </div>
                  {/* <form onSubmit={handleSearchSubmit}> */}
                  <button
                    onClick={handleSearchSubmit}
                    className="sendListButton"
                    type="submit">
                    {t("searchAllResult")}
                  </button>
                  {/* </form> */}
                </div>
              </RenderIf>
            </div>

            <Link to={Urls.NOTIFICATIONS_URL}>
              {/* <Notification /> */}

              <RenderIf
                condition={notificationData?.unReadDatas?.length}
                renderElse={
                  <img
                    src={NotificationLogo}
                    alt=""
                    width="32px"
                    height="32px"
                  />
                }>
                {/* <Dote className="dote" /> */}
                <img src={NotificationGif} alt="" width="40px" height="40px" />
              </RenderIf>
            </Link>
          </div>
        </div>
      </div>
      <div className="mobile-menu">
        <div className="d-flex justify-content-between">
          <div className="mobile-menuLeft">
            <Link className="brand-logo" to={Urls.HOME_URL}>
              <img src={PashaHubLogo} alt="" width="100px" height="40px" />
            </Link>
          </div>
          <div className="mobile-menuRight">
            <div className="d-md-flex align-items-center position-relative">
              <Link className="header-icon" to={Urls.NOTIFICATIONS_URL}>
                <Notification />
              </Link>
              <div
                onClick={() => setVisibilityMobileMenu(true)}
                className="header-icon">
                <Menu />
              </div>
            </div>
          </div>
        </div>
      </div>
      <RenderIf condition={isMobileMenuVisible}>
        <div className="mobile-sidebar">
          <div className="mobile-sidebar_border">
            <button
              className="mobile-sidebar_close"
              type="button"
              background="white"
              onClick={() => setVisibilityMobileMenu(false)}>
              +
            </button>
            <div className="mobile-sidebar_border_left d-flex">
              <div onClick={() => clickPage()}>
                <img
                  src={`${REACT_APP_BASE_PHOTO_URL}${userInfo?.profilePhotoUrl}`}
                  alt="user"
                  width="64px"
                  height="64px"
                />
              </div>
              <div className="mobile-sidebar_border_right">
                <h4>
                  {userInfo?.firstName} {userInfo?.lastName}
                </h4>

                <p>{userInfo?.position}</p>
              </div>
            </div>
          </div>
          <ul className="mobile-sidebar_body">
            <SearchInput
              filterInputsUpdate={handleSearchChange}
              filterInputs={filterInputs?.name}
              setFilterInputs={setFilterInputs}
              placeholder={t("search")}
              onClick={toggleMenu}
              name="name"
              handleKeyPress={handleKeyPress}
            />
            <RenderIf condition={searchResults && showMenu}>
              <div ref={containerRef} className="sendList">
                <div className="sendListBody">
                  <SearchResult data={slicedResults} />
                </div>

                <form onSubmit={clickInnerPage}>
                  <button className="sendListButton" type="submit">
                    {t("searchAllResult")}
                  </button>
                </form>
              </div>
            </RenderIf>

            {data?.map((c) => (
              <li
                onClick={toggle}
                key={c?.id}
                className={`mobile-sidebar_body_item ${
                  pathName === c?.url ? "li-active" : ""
                }`}>
                <Link to={c?.url}>
                  <div
                    className={`${pathName === c?.url ? "icon-active" : ""}`}>
                    {/* {c?.icon} */}
                    <RenderIf condition={c?.img} renderElse={c?.icon}>
                      <img
                        width="35px"
                        height="35px"
                        style={{ objectFit: "cover" }}
                        src={c?.img}
                        alt="logo"
                      />
                    </RenderIf>
                  </div>
                  <span
                    className={`${pathName === c?.url ? "span-active" : ""}`}>
                    {c?.title}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
          <RenderIf condition={linkData?.data?.length}>
            <ul className="mobile-sidebar_footer pb-4">
              <li className="menu-footer_item">
                <span>{t("additional-links")}</span>
              </li>
              <li className="menu-footer_item">
                {linkData?.data?.map((c) => (
                  <div key={c?.id} className="d-flex align-items-center mt-3">
                    <img
                      src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${c?.fileUrl}`}
                      alt="logo"
                    />
                    <Link to={c?.url} target="_blank">
                      <p>{c?.name}</p>
                    </Link>
                  </div>
                ))}
              </li>
            </ul>
          </RenderIf>

          <div className="mobile-sidebar_button">
            <button onClick={logoutHandler}>
              <Login />
              {t("signOut")}
            </button>
          </div>
          <Link
            to="https://crocusoft.com/"
            target="blank"
            className="ms-2 link">
            <div className="mt-2">
              <span
                style={{
                  fontSize: "14px",
                  color: "black",
                }}>
                Designed & Developed by
              </span>
              <img
                src={CrocusoftLogo}
                alt="Crocusoft-logo"
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "cover",
                }}
              />
              <span style={{ fontWeight: "600" }}>Crocusoft</span>
            </div>
          </Link>
        </div>
      </RenderIf>
    </div>
  );
};

export default Header;
