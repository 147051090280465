import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const libraryApi = createApi({
  reducerPath: "libraryApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllBanner: builder.query({
      query() {
        return {
          url: `hubdev/ad/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getAllHubDevCategory: builder.query({
      query() {
        return {
          url: `hubdev/category/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getAllCourseType: builder.query({
      query() {
        return {
          url: `hubdev/course/type/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getAllCourseOrganization: builder.query({
      query() {
        return {
          url: `hubdev/courseorganization/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),

    createCourseRating: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/rating",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
    updateCourseRating: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/rating",
          method: "PUT",
          data,
        };
      },
      transformResponse: (result) => result,
      invalidatesTags: ["HubDev"],
    }),
    deleteCourseRating: builder.mutation({
      query(id) {
        return {
          url: `hubdev/course/rating`,
          method: "DELETE",
          data: { id: id },
        };
      },
      invalidatesTags: ["HubDev"],
    }),

    getAllCourse: builder.query({
      query(filter) {
        const {
          skip,
          take,
          field,
          orderBy,
          prompt,
          categoryId,
          organizationId,
          typeId,
          complexity,
        } = filter;
        return {
          url: `hubdev/course/filter?`,
          params: {
            skip,
            take,
            field,
            orderBy,
            prompt,
            categoryId,
            organizationId,
            typeId,
            complexity,
          },
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["HubDev"],
      transformResponse: (result) => result?.data,
    }),
    getCourse: builder.query({
      query(id) {
        return {
          url: `hubdev/course/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),

    createCourseComment: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/comment",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
    updateCourseComment: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/comment",
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["HubDev"],

      transformResponse: (result) => result,
    }),
    deleteCourseComment: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/comment",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
    getAllCourseComment: builder.query({
      query(filter) {
        const { name, take, orderBy, sortField, hubDevCourseId } = filter;
        return {
          url: `hubdev/course/comment/filter`,
          params: { name, take, orderBy, sortField, hubDevCourseId },
        };
      },
      providesTags: ["HubDev"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getCourseComment: builder.query({
      query(id) {
        return {
          url: `hubdev/course/comment/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),

    sendCourse: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/send",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
    createPlaylist: builder.mutation({
      query(data) {
        return {
          url: "hubdev/playlist",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["PlayList"],
      transformResponse: (result) => result,
    }),
    updatePlaylist: builder.mutation({
      query(data) {
        return {
          url: "hubdev/playlist",
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["PlayList"],

      transformResponse: (result) => result,
    }),
    deletePlaylist: builder.mutation({
      query(data) {
        return {
          url: "hubdev/playlist",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: ["PlayList"],
      transformResponse: (result) => result,
    }),
    getAllPlaylist: builder.query({
      query(filter) {
        const { prompt, skip, take, orderBy, sortField } = filter;
        return {
          url: `hubdev/playlist/filter`,
          params: { prompt, skip, take, orderBy, sortField },
        };
      },
      providesTags: ["PlayList"],
      keepUnusedDataFor: 1,
      transformResponse: (result) => result?.data,
    }),
    getPlaylist: builder.query({
      query(id) {
        return {
          url: `hubdev/playlist/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      providesTags: ["PlayList"],
      transformResponse: (result) => result,
    }),
    createStage: builder.mutation({
      query(data) {
        return {
          url: "hubdev/stage",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["PlayList"],
      transformResponse: (result) => result,
    }),
    updateStage: builder.mutation({
      query(data) {
        return {
          url: "hubdev/stage",
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["PlayList"],

      transformResponse: (result) => result,
    }),
    deleteStage: builder.mutation({
      query(data) {
        return {
          url: "hubdev/stage",
          method: "DELETE",
          data: data,
        };
      },
      invalidatesTags: ["PlayList"],
      transformResponse: (result) => result,
    }),
    sendPlaylist: builder.mutation({
      query(data) {
        return {
          url: "hubdev/playlist/send",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
    startCourse: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/start",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
    resetCourseProgress: builder.mutation({
      query(data) {
        return {
          url: "hubdev/course/resetprogress",
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["HubDev"],
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useResetCourseProgressMutation,
  useStartCourseMutation,
  useSendPlaylistMutation,
  useCreateStageMutation,
  useUpdateStageMutation,
  useDeleteStageMutation,
  useCreatePlaylistMutation,
  useUpdatePlaylistMutation,
  useDeletePlaylistMutation,
  useGetAllPlaylistQuery,
  useGetPlaylistQuery,
  useSendCourseMutation,
  useCreateCourseCommentMutation,
  useUpdateCourseCommentMutation,
  useDeleteCourseCommentMutation,
  useGetAllCourseCommentQuery,
  useGetCourseCommentQuery,
  useGetAllCourseQuery,
  useGetCourseQuery,
  useCreateCourseRatingMutation,
  useUpdateCourseRatingMutation,
  useDeleteCourseRatingMutation,
  useGetAllCourseTypeQuery,
  useGetAllCourseOrganizationQuery,
  useGetAllHubDevCategoryQuery,
  useGetAllBannerQuery,
} = libraryApi;
