import * as React from "react";
const SvgSend2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.166 5.267 7.075-2.359c3.175-1.058 4.9.675 3.85 3.85l-2.358 7.076c-1.583 4.758-4.183 4.758-5.767 0l-.7-2.1-2.1-.7c-4.758-1.584-4.758-4.176 0-5.767ZM8.425 11.375l2.983-2.992"
    />
  </svg>
);
export default SvgSend2;
