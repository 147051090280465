import * as React from "react";
const SvgType = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#057953" opacity={0.2} rx={6} />
    <path
      stroke="#057953"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M18.393 16.173a3.42 3.42 0 1 0 0-6.84 3.42 3.42 0 0 0 0 6.84ZM12.24 20.96a2.053 2.053 0 1 0 0-4.107 2.053 2.053 0 0 0 0 4.107ZM19.082 22.666a1.707 1.707 0 1 0 0-3.413 1.707 1.707 0 0 0 0 3.413Z"
    />
  </svg>
);
export default SvgType;
