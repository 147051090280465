import {
  Barcode,
  Book,
  CalendarSearch,
  Messages2,
  People,
  Home,
  Note2,
  Call,
  Setting2,
} from "shared/components/Icons";
import { Urls } from "shared/constants/url";
import { useTranslation } from "react-i18next";

// export const info = [
//   {
//     id: 1,
//     title: "Home",
//     url: Urls.HOME_URL,
//     icon: <Home />,
//   },
//   {
//     id: 2,
//     title: "News",
//     url: Urls.NEWS_URL,
//     icon: <Barcode />,
//   },
//   {
//     id: 3,
//     title: "Discussions",
//     url: Urls.DISCUSSIONS_URL,
//     icon: <Messages2 />,
//   },
//   {
//     id: 4,
//     title: "Community",
//     url: Urls.COMMUNITY_URL,
//     icon: <People />,
//   },
//   {
//     id: 5,
//     title: "Events",
//     url: Urls.EVENTS_URL,
//     icon: <CalendarSearch />,
//   },
//   {
//     id: 6,
//     title: "Library",
//     url: Urls.LIBRARY_URL,
//     icon: <Book />,
//   },
//   {
//     id: 7,
//     title: "About us",
//     url: Urls.ABOUT_US_URL,
//     icon: <Note2 />,
//   },
//   {
//     id: 8,
//     title: "Contact",
//     url: Urls.CONTACT_URL,
//     icon: <Call />,
//   },
//   {
//     id: 9,
//     title: "Settings",
//     url: Urls.SETTINGS_URL,
//     icon: <Setting2 />,
//   },
// ];
const ReturnerData = () => {
  const { t } = useTranslation();
  const data = [
    {
      id: 9,
      title: "Home",
      url: Urls.HOME_URL,
      icon: <Home />,
    },
    {
      id: 1,
      title: t("news"),
      url: Urls.NEWS_URL,
      icon: <Barcode />,
      text: t("newsDescription"),
      img: "/media/live.gif",
    },
    {
      id: 2,
      title: t("discussion"),
      url: Urls.DISCUSSIONS_URL,
      icon: <Messages2 />,
      text: t("discussionsDescription"),
      img: "/media/customer.gif",
    },
    {
      id: 3,
      title: t("community"),
      url: Urls.COMMUNITY_URL,
      icon: <People />,
      text: t("communityDescription"),
      img: "/media/diagram.gif",
    },
    {
      id: 4,
      title: t("events"),
      url: Urls.EVENTS_URL,
      icon: <CalendarSearch />,
      text: t("eventsDescription"),
      img: "/media/calendar.gif",
    },
    {
      id: 5,
      title: t("HUBDev"),
      url: Urls.LIBRARY_URL,
      icon: <Book />,
      text: t("libraryDescription"),
      img: "/media/rocket.gif",
    },
    {
      id: 7,
      title: "About us",
      url: Urls.ABOUT_US_URL,
      icon: <Note2 />,
    },
    {
      id: 8,
      title: "Contact",
      url: Urls.CONTACT_URL,
      icon: <Call />,
    },
  ];

  return {
    data,
  };
};
export default ReturnerData;
