import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { MentionsInput, Mention } from "react-mentions";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Member from "shared/media/user/userIcon.png";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Urls } from "shared/constants/url";
import { updateObject } from "shared/helper";
import {
  Send,
  Send2,
  MessageQuestion,
  Bookmark,
  Like,
  DownArrow,
  Eye,
  FilePpt,
  Filedoc,
  Filepdf,
  Filexls,
  Download,
} from "shared/components/Icons";
import {
  Btn,
  RenderIf,
  SearchInput,
  Button,
  ClickMore,
  EditCommunityDiscussionCard,
  CommunityDiscussionComment,
  ImageGrid,
  File,
  UserCard,
} from "shared/components";
import {
  imgTypes,
  mainDocType,
  videoTypes,
  docType,
} from "shared/constants/url";
import {
  getYouTubeVideoId,
  processTextWithLinks,
} from "shared/helper/getYoutubeVideoId";
import { handleDownload } from "shared/helper/updateObject";
import { useLazyGetFileQuery } from "redux/api/news";

import {
  useGetAllUsersQuery,
  useDetailedInfoByIdQuery,
} from "redux/api/siteUsers";
import {
  useCreateCommunityDiscussionCommentMutation,
  useDeleteCommunityDiscussionMutation,
  useReportCommunityDiscussionMutation,
  useSendCommunityMutation,
  useLazyGetCommunityDiscussionCommentsQuery,
  useCommunityDiscussionLikeMutation,
  useDeleteCommunityDiscussionLikeMutation,
  useLazyGetCommunityDiscussionDetailQuery,
} from "redux/api/community";
import {
  useBookmarkMutation,
  useDeleteBookmarkMutation,
} from "redux/api/bookmark";
import { custom, customStyleSecond } from "../DiscussionComment/style";
import classNames from "./Card.scss";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CommunityDiscussionCard = ({
  files,
  discussionComments,
  userImg,
  advice,
  name,
  surname,
  className,
  communityData,
  refetch,
  mission,
  description,
  postID,
  siteUserID,
  cardClickHandler,
  isLiked,
  likeCount,
  viewCount,
  isBookmarked,
  communityDiscussionId,
}) => {
  const { t } = useTranslation();
  const { id: ownerID } = useSelector((state) => state.user.user);
  const { REACT_APP_BASE_PHOTO_URL, REACT_APP_BASE_PHOTO_URL_MAIN } =
    process.env;
  const initialValues = {
    newsId: "",
    description: "",
    siteUserId: "",
    files: [],
  };
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [discussionFile, setDiscussionFile] = useState();
  const [editInputVal, setEditInputVal] = useState();
  const [selectedUserEmails, setSelectedUserEmails] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [selectedId, setSelectedId] = useState([]);
  const handleTagClick = (id) => {
    setSelectedId((prevIds) => [...prevIds, id]);
  };
  const [suggestion, setSuggettion] = useState([]);
  const [getFile, { isFetching: fileLoading }] = useLazyGetFileQuery();

  const [getCommentsByPost, { data: commentData }] =
    useLazyGetCommunityDiscussionCommentsQuery();
  const [likeCommunityPost, { isSuccess: likeSuccess }] =
    useCommunityDiscussionLikeMutation();
  const [deleteCommunityLike, { isSuccess: deleteLikeSuccess }] =
    useDeleteCommunityDiscussionLikeMutation();
  const [bookmark, { isSuccess: bookmarkSuccess }] = useBookmarkMutation();
  const [deleteBookmark, { isSuccess: deleteBookmarkSuccess }] =
    useDeleteBookmarkMutation();
  const [
    getCommunityDiscussionDetail,
    {
      data: discussionData,
      isSuccess: discussionDataSuccess,
      isLoading: discussionDataLoading,
    },
  ] = useLazyGetCommunityDiscussionDetailQuery();
  const likePost = () => {
    const formData = new FormData();
    formData.append("CommunityDiscussionId", postID);
    likeCommunityPost(formData);
  };
  const deleteLike = () => {
    const data = {
      communityDiscussionId: postID,
    };
    deleteCommunityLike(data);
  };
  const saveBookmark = () => {
    const formData = new FormData();

    formData.append("CommunityDiscussionId", postID);

    bookmark(formData);
  };
  const deleteBookmarked = () => {
    const data = {
      communityDiscussionId: postID,
    };
    deleteBookmark(data);
  };
  let formattedMessage = inputVal;
  selectedId?.forEach((id, index) => {
    const regex = new RegExp(`@\\[.*?\\]\\(${id}\\)`, "g");
    formattedMessage = formattedMessage.replace(regex, `@${id}`);
  });

  const [showMore, setShowMore] = useState(true);
  const [show, setShow] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [modal, setModal] = useState(false);
  const [partsOther, setPartsOther] = useState([]);

  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
  });
  const { id } = useSelector((state) => state.user.user);
  const { data } = useDetailedInfoByIdQuery({ id });
  const getImagesFromFile = (value) => {
    setFileData(value);
  };

  const [deleteCommunity, { isSuccess: deleteSuccess }] =
    useDeleteCommunityDiscussionMutation();
  const { data: usersEmail, isSuccess: usersSuccess } = useGetAllUsersQuery({
    name: filterInputs,
  });
  const [
    sendCommunity,
    { isLoading: sendLoading, isSuccess: sendSuccess, error },
  ] = useSendCommunityMutation();
  const [createComment, { isSuccess: commentSuccess }] =
    useCreateCommunityDiscussionCommentMutation();
  const [
    reportDiscussion,
    {
      isSuccess: reportSuccess,
      isError: reportError,
      isLoading: reportLoading,
    },
  ] = useReportCommunityDiscussionMutation();

  const handleCheckboxChange = (event, email, id) => {
    if (event.target.checked) {
      setSelectedUserEmails((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        email,
      ]);
      setSelectedUserIds((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        id,
      ]);
    } else {
      setSelectedUserEmails((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== email)
      );
      setSelectedUserIds((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== id)
      );
    }
  };

  const deletePost = () => deleteCommunity(postID);

  const titles = [
    {
      id: uuid(),
      title: t("edit"),
      onClick: () => {
        setShow(true);
        getCommunityDiscussionDetail(postID);
        setDiscussionFile(discussionData?.communityDiscussionFiles);
        setEditInputVal(discussionData?.text);
      },
    },
    {
      id: uuid(),
      title: t("delete"),
      onClick: deletePost,
    },
  ];

  const filterInputsUpdate = (e) => {
    const { name, value } = e.target;
    const updateFiltersInput = updateObject(filterInputs, {
      [name]: value,
    });
    setFilterInputs(updateFiltersInput);
    let filteredUsers = [];
    if (value === "") {
      filteredUsers = [];
    } else {
      filteredUsers = usersEmail?.data?.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(updateFiltersInput[name].toLowerCase())
      );
    }
    setSearchResults(filteredUsers);
  };
  const sendDiscussionPost = () => {
    const data = {
      communityDiscussionId: postID,
      emails: selectedUserEmails,
      ids: selectedUserIds,
    };
    selectedUserEmails.length && sendCommunity(data);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (values.submitType === "POST2") {
        const comment = new FormData();
        comment.append("communityDiscussionId", communityData);
        formattedMessage && comment.append("description", formattedMessage);
        comment.append("siteUserId", id);
        if (fileData?.length) {
          for (let i = 0; i < fileData?.length; i++) {
            comment.append(`files`, fileData?.[i]);
          }
        } else {
          comment.append(`files`, null);
        }

        let condition = !inputVal?.trim() && !fileData?.length;
        !condition && createComment(comment);
      }

      resetForm();
    },
  });
  const reportCommunityDiscussion = () => {
    const formData = new FormData();
    formData.append("SiteUserId", id);
    formData.append("DiscussionId", postID);
    reportDiscussion(formData);
  };
  const onHideModal = () => {
    setModal(false);
    setSelectedUserEmails([]);
    setSearchResults([]);
    setFilterInputs({
      firstName: "",
      lastName: "",
    });
  };
  function handleLoadMore() {
    setRowsPerPage(rowsPerPage + 3);
    getCommentsByPost({
      communityDiscussionId: postID,
      skip: 0,
      take: rowsPerPage + 3,
      orderBy: false,
      sortField: "id",
    });
  }
  const imageClickHandler = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    if (deleteSuccess) {
      refetch();
    }
  }, [deleteSuccess]);
  useEffect(() => {
    if (sendSuccess) {
      setModal(false);
      setSelectedUserEmails([]);
      setFilterInputs({
        firstName: "",
        lastName: "",
      });
      setSearchResults([]);
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [sendSuccess]);
  useEffect(() => {
    if (error) {
      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);
  useEffect(() => {
    if (commentSuccess) {
      setFileData(null);
      setModal(false);
      setInputVal("");
      refetch();
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [commentSuccess]);
  useEffect(() => {
    if (reportSuccess) {
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (reportError) {
      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [reportLoading]);

  useEffect(() => {
    const formattedData = usersEmail?.data?.map((user) => ({
      id: user.id,
      display: `${user.firstName} ${user.lastName}`,
    }));
    setSuggettion(formattedData);
  }, [usersSuccess]);

  useEffect(() => {
    const extractLinks = (text) => {
      if (!text) {
        return [];
      }

      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const matches = text.match(urlRegex);

      if (!matches) {
        return [];
      }

      const parts = text.split(urlRegex);

      const links = parts.reduce((acc, part, index) => {
        if (matches.includes(part)) {
          acc.push(part);
        }
        return acc;
      }, []);

      return links;
    };

    const extractedLinks = extractLinks(description);
    setPartsOther(extractedLinks);
  }, [description]);
  useEffect(() => {
    if (
      deleteBookmarkSuccess ||
      bookmarkSuccess ||
      deleteLikeSuccess ||
      likeSuccess
    ) {
      refetch();
    }
  }, [deleteBookmarkSuccess, bookmarkSuccess, deleteLikeSuccess, likeSuccess]);
  useEffect(() => {
    if (discussionDataSuccess) {
      setDiscussionFile(discussionData?.communityDiscussionFiles);
      setEditInputVal(discussionData?.text);
    }
  }, [discussionData, postID, discussionDataSuccess]);
  return (
    <div className={`cardMain ${className}`}>
      <div
        onClick={cardClickHandler}
        className="cardMainTop d-flex justify-content-between">
        <div className="d-flex">
          <div className="cardMainTopLeft">
            <Link
              to={Urls.POSTS_URL + `/${siteUserID}`}
              onClick={imageClickHandler}>
              <img
                alt="user"
                src={userImg ? `${REACT_APP_BASE_PHOTO_URL}${userImg}` : Member}
              />
            </Link>
          </div>
          <div className="cardMainTopRight">
            <Link
              to={Urls.POSTS_URL + `/${siteUserID}`}
              onClick={imageClickHandler}>
              <h4 className="name">
                {name} {surname}
              </h4>{" "}
            </Link>
            <p className="duty">{mission}</p>
          </div>
        </div>
        {/* <RenderIf
          condition={advice === 2}
          renderElse={
            <div className="cardMainTopBtn">
              <Btn className="purple_background" border="glassy">
                {t("conveyVisdom")}
              </Btn>
            </div>
          }>
          <div className="cardMainTopBtn">
            <Btn border="glassy">{t("needAdvice")}</Btn>
          </div>
        </RenderIf> */}
      </div>
      <div className="cardMainCenter">
        <RenderIf condition={description}>
          <div className="cardMainCenterText">
            <p>
              <RenderIf
                condition={files?.length}
                renderElse={
                  <>
                    <div style={{ whiteSpace: "pre-line" }}>
                      {processTextWithLinks(description)}
                      {partsOther?.map((link, index) => {
                        if (
                          link?.startsWith("https://www.youtube") ||
                          link?.startsWith("https://youtube") ||
                          link?.startsWith("https://youtu.be")
                        ) {
                          const shortVideoID = link.split("/").slice(-1)[0];
                          const videoId = getYouTubeVideoId(link);
                          const embedUrl =
                            link?.startsWith(
                              "https://www.youtube.com/shorts"
                            ) || link?.startsWith("https://youtube")
                              ? `https://www.youtube.com/embed/${shortVideoID}`
                              : `https://www.youtube.com/embed/${videoId}`;
                          return (
                            <div className="mt-2" key={index}>
                              <iframe
                                width="100%"
                                height="400"
                                src={embedUrl}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                title="YouTube Video"
                                allowFullScreen></iframe>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                }>
                <RenderIf
                  condition={showMore}
                  renderElse={
                    <div
                      onClick={() => setShowMore(!showMore)}
                      style={{ whiteSpace: "pre-line" }}>
                      {" "}
                      {processTextWithLinks(description)}
                    </div>
                  }>
                  <div
                    onClick={() => setShowMore(!showMore)}
                    style={{ whiteSpace: "pre-line" }}>
                    {" "}
                    {processTextWithLinks(description?.substring(0, 250))}
                  </div>
                </RenderIf>
              </RenderIf>
            </p>
            <RenderIf condition={files?.length}>
              <RenderIf condition={description?.length > 250}>
                <button onClick={() => setShowMore(!showMore)} className="more">
                  See more...
                </button>
              </RenderIf>
            </RenderIf>
          </div>
        </RenderIf>
        <RenderIf condition={files?.length}>
          <div className="mt-2">
            <>
              {files?.some((item) => {
                const extension = item.fileUrl.split(".").pop().toLowerCase();
                return docType.includes(extension);
              }) && (
                <div className="d-flex mb-3">
                  {files
                    .filter((item) => {
                      const extension = item.fileUrl
                        .split(".")
                        .pop()
                        .toLowerCase();
                      return docType.includes(extension);
                    })
                    .map((item, index) => {
                      const extension = item.fileUrl
                        .split(".")
                        .pop()
                        .toLowerCase();

                      const fileIcons = {
                        doc: <Filedoc width="60px" height="60px" />,
                        docx: <Filedoc width="60px" height="60px" />,
                        pdf: <Filepdf width="60px" height="60px" />,
                        pptx: <FilePpt width="60px" height="60px" />,
                        ppt: <FilePpt width="60px" height="60px" />,
                        xlsx: <Filexls width="60px" height="60px" />,
                        xls: <Filexls width="60px" height="60px" />,
                      };
                      const FileComponent = fileIcons[extension] || null;
                      return (
                        <div key={index} className="svg-wrapper mt-2">
                          {FileComponent}
                          <div
                            onClick={() => {
                              handleDownload(item?.id, item?.fileUrl, getFile);
                            }}
                            className="svg-wrapper-svg">
                            <Download />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {files?.some((item) => {
                const extension = item.fileUrl.split(".").pop().toLowerCase();
                return (
                  imgTypes.includes(extension) || videoTypes.includes(extension)
                );
              }) && (
                <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}>
                  <ImageGrid>
                    {files
                      .filter((item) => {
                        const extension = item.fileUrl
                          .split(".")
                          .pop()
                          .toLowerCase();
                        return (
                          imgTypes.includes(extension) ||
                          videoTypes.includes(extension)
                        );
                      })
                      .map((item) => {
                        const extension = item.fileUrl
                          .split(".")
                          .pop()
                          .toLowerCase();
                        if (imgTypes.includes(extension)) {
                          return (
                            <img
                              key={item?.id}
                              src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${item.fileUrl}`}
                              alt="img"
                            />
                          );
                        } else if (videoTypes.includes(extension)) {
                          return (
                            <video key={item?.id} controls>
                              <source
                                src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${item.fileUrl}`}
                              />
                            </video>
                          );
                        }
                      })}
                  </ImageGrid>
                </div>
              )}
              <div>
                {files
                  ?.filter((item) => {
                    const extension = item.fileUrl
                      .split(".")
                      .pop()
                      .toLowerCase();
                    return (
                      !imgTypes.includes(extension) &&
                      !videoTypes.includes(extension) &&
                      !mainDocType.includes(extension) &&
                      extension !== "pdf"
                    );
                  })
                  .map((item) => (
                    <p key={item?.id}>{item.fileUrl}</p>
                  ))}
              </div>
            </>
          </div>
        </RenderIf>
      </div>
      <div className="cardMainBottom align-items-center justify-content-between">
        <div className=" d-flex align-items-center cardBottom ">
          <Tooltip title={t("like")}>
            <div
              onClick={isLiked ? deleteLike : likePost}
              className={`${isLiked ? "like-active" : ""} cardMainBottomIcon`}>
              <Like />
              <span>
                {" "}
                {t("like")} ({likeCount})
              </span>
            </div>
          </Tooltip>
          <Tooltip title={t("share")}>
            <div onClick={() => setModal(true)} className="cardMainBottomIcon">
              <Send2 />
              <span> {t("share")}</span>
            </div>
          </Tooltip>
          <Tooltip title={t("comment")}>
            <div
              onClick={() => {
                setToggle(!toggle);
                getCommentsByPost({
                  communityDiscussionId: postID,
                  skip: 0,
                  take: rowsPerPage,
                  orderBy: false,
                  sortField: "id",
                });
              }}
              className="cardMainBottomIcon">
              <Send />
              <span>
                {" "}
                {t("comment")} ({discussionComments?.length})
              </span>
            </div>
          </Tooltip>
          <Tooltip title={t("Bookmark")}>
            <div
              onClick={isBookmarked ? deleteBookmarked : saveBookmark}
              className={`${
                isBookmarked ? "like-active" : ""
              } cardMainBottomIcon`}>
              <Bookmark />
              <span> {t("Bookmark")}</span>
            </div>
          </Tooltip>
          <Tooltip title={t("reportIt")}>
            <div
              onClick={reportCommunityDiscussion}
              className="cardMainBottomIcon">
              <MessageQuestion />
              <span> {t("reportIt")}</span>
            </div>
          </Tooltip>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center newsCardOneRightBottomIcon mx-2">
            <Eye />{" "}
            <span className="mx-2">
              {viewCount} {t("view")}
            </span>
          </div>
          <RenderIf condition={ownerID === siteUserID}>
            <div>
              <ClickMore background="#F3F8F6" buttonTitles={titles} />
              <EditCommunityDiscussionCard
                refetch={refetch}
                inputVal={editInputVal}
                setInputVal={setEditInputVal}
                show={show}
                close={() => setShow(false)}
                postID={postID}
                disType={advice}
                siteUserID={siteUserID}
                name={name}
                surname={surname}
                filer={discussionFile}
                setDiscussionFile={setDiscussionFile}
              />
            </div>
          </RenderIf>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <RenderIf condition={toggle}>
          <div className="newsCardComment">
            <div className="newsCardCommentInput d-flex">
              <div className="newsCardCommentInputLeft">
                <img
                  alt="user"
                  src={
                    data?.profilePhotoUrl
                      ? `${REACT_APP_BASE_PHOTO_URL}${data?.profilePhotoUrl}`
                      : Member
                  }
                />
              </div>
              <div className="newsCardCommentInputRight">
                <div>
                  <MentionsInput
                    autoFocus={false}
                    value={inputVal}
                    onChange={(e) => {
                      setInputVal(e.target.value);
                    }}
                    placeholder={t("writeComment")}
                    style={customStyleSecond}
                    classNames={classNames}>
                    <Mention
                      key={() => uuid()}
                      trigger="@"
                      data={suggestion}
                      onAdd={handleTagClick}
                      style={custom}
                    />
                  </MentionsInput>
                </div>

                <File
                  commentSuccess={commentSuccess}
                  handleImageFile={getImagesFromFile}
                />
                <button
                  className="sendButton"
                  onClick={() => formik.setFieldValue("submitType", "POST2")}
                  type="submit">
                  <Send2 />
                </button>
              </div>
            </div>

            {commentData?.map((c) => (
              <CommunityDiscussionComment
                key={c?.discussionId}
                communityDiscussionId={communityDiscussionId}
                isLiked={c?.isLiked}
                likeCount={c?.likeCount}
                siteUserId={c?.siteUserId}
                description={c?.text}
                userName={c?.siteUserName}
                userPhoto={c?.siteUserFileUrl}
                createDate={c?.createdDate}
                newsCommentFiles={c?.communityDiscussionCommentFiles}
                commentId={c?.id}
                refetch={refetch}
              />
            ))}
            <RenderIf condition={commentData?.length}>
              <Button
                onClick={handleLoadMore}
                className="showMoreButton mt-3"
                marginRight="8px"
                icon={<DownArrow />}>
                {t("showMore")}
              </Button>
            </RenderIf>
          </div>
        </RenderIf>
      </form>
      <Modal show={modal} onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("sendPost")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sendList">
            <SearchInput
              filterInputsUpdate={filterInputsUpdate}
              filterInputs={filterInputs?.firstName}
              setFilterInputs={setFilterInputs}
              placeholder={t("search")}
              name="firstName"
            />
            <div className="sendListBody">
              {searchResults?.map((c) => (
                <div key={c?.id} className="sendListBodyItem d-flex">
                  <div className="sendListBodyItemLeft">
                    <UserCard
                      image={
                        c?.profilePhotoUrl
                          ? `${REACT_APP_BASE_PHOTO_URL}${c?.profilePhotoUrl}`
                          : Member
                      }
                      userName={c?.firstName}
                      userLastName={c?.lastName}
                      profession={c?.position}
                    />
                  </div>
                  <div className="sendListBodyItemRight">
                    <Checkbox
                      {...label}
                      color="success"
                      checked={selectedUserEmails.includes(c?.email)}
                      onChange={(e) => handleCheckboxChange(e, c?.email, c?.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={sendDiscussionPost}
            className="pt_14_26 font_14 align-items-center d-flex"
            background="#057953"
            type="submit"
            disabled={!selectedUserIds?.length || sendLoading}>
            {t("share")}
            <RenderIf condition={sendLoading}>
              <div class="spinner-border" role="status"></div>{" "}
            </RenderIf>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CommunityDiscussionCard;
