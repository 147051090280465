import * as React from "react";
const SvgMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M2.75 6.416h16.5M2.75 11h16.5M2.75 15.584h16.5"
    />
  </svg>
);
export default SvgMenu;
