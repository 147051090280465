import React from "react";
import "./HomeCard.scss";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const HomeCard = ({
  title,
  description,
  img,
  children,
  backgroundColor,
  imgWidth,
  imgHeight,
  cardHeight,
  cardWidth,
  className,
}) => {
  return (
    <div
      className={`${className || ""} home-card`}
      style={{
        backgroundColor: backgroundColor || "",
        height: cardHeight,
        width: cardWidth,
      }}>
      <div className="home-cardText">
        <h1>{title}</h1>
      </div>
      <img
        className="img-fluid"
        width={imgWidth}
        height={imgHeight}
        src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`}
        alt="logo"
      />
      <p>{description}</p>
      <>{children}</>
    </div>
  );
};

export default HomeCard;
