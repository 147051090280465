import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "redux/store";
import Router from "./pages";
import Loading from "shared/components/Loading";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
const TRACKING_ID = "G-R46D7QF2DM";
ReactGA.initialize(TRACKING_ID);
function App() {
  let persistor = persistStore(store);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.hash });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <HashRouter>
          <Router />
          <ToastContainer />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
