import * as React from "react";
const SvgSaturn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.225 4.4C4.232 2.878 2.018 2.625 1.32 3.584.295 4.984 3.347 8.955 8.136 12.45c4.788 3.496 9.499 5.195 10.522 3.793.675-.924-.214-2.845-2.415-5.132m0-.63a6.501 6.501 0 1 1-13.002 0 6.501 6.501 0 0 1 13.002 0Z"
    />
  </svg>
);
export default SvgSaturn;
