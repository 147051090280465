import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, RenderIf } from "shared/components";
import { Plus } from "shared/components/Icons";
import "./NotFound.scss";

const NotFound = ({ description, logo, onClick, isEditable, titles }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div className="w-100 py-4 notFoundMain">
      <img alt="empty" src={logo} />
      <h4 className="mt-4">{description}</h4>
      <RenderIf condition={isEditable}>
        <div className="notFoundMainInside" ref={menuRef}>
          <Button
            type="button"
            className="font_14 mt-3 d-inline"
            background="#057953"
            icon={<Plus />}
            onClick={() => setShow(!show)}>
            {t("add")}
          </Button>
          <RenderIf condition={show}>{titles}</RenderIf>
        </div>
      </RenderIf>
    </div>
  );
};

export default NotFound;
