import * as React from "react";
const SvgStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 16"
    {...props}
  >
    <path
      fill="#464A4D"
      d="M17.333 6.059a.833.833 0 0 0-.716-.559l-4.742-.691L9.75.5a.833.833 0 0 0-1.5 0L6.125 4.8l-4.742.7a.833.833 0 0 0-.675.567.833.833 0 0 0 .209.833l3.441 3.334-.833 4.733a.834.834 0 0 0 1.225.9L9 13.642l4.25 2.225c.117.066.249.1.383.1a.833.833 0 0 0 .78-.526.832.832 0 0 0 .045-.466l-.833-4.733 3.442-3.333a.833.833 0 0 0 .266-.85Zm-5.125 3.333a.834.834 0 0 0-.241.733l.6 3.5-3.134-1.666a.884.884 0 0 0-.783 0l-3.133 1.666.6-3.5a.834.834 0 0 0-.242-.733l-2.5-2.5 3.508-.508a.833.833 0 0 0 .634-.459L9 2.75l1.567 3.184a.832.832 0 0 0 .633.458l3.508.508-2.5 2.492Z"
    />
  </svg>
);
export default SvgStar;
