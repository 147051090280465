import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const valueAndEthicApi = createApi({
  reducerPath: "valueAndEthicApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllValue: builder.query({
      query(filter) {
        return {
          url: `ValueAndEthic/value/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
    getAllEthic: builder.query({
      query(filter) {
        const { skip, take } = filter;

        return {
          url: `ValueAndEthic/ethic/filter?`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetAllEthicQuery, useGetAllValueQuery } = valueAndEthicApi;
