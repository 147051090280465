import React from "react";
import { Search } from "shared/components/Icons";
import { RenderIf } from "shared/components";
import "./SearchInput.scss";
import { Clear } from "../../../../node_modules/@mui/icons-material/index";

const SearchInput = ({
  placeholder,
  filterInputsUpdate,
  label,
  field,
  name,
  onBlur,
  onClick,
  handleKeyPress,
  className,
  background,
  height,
  isClearable,
  setFilterInputs,
  filterInputs,
}) => {
  return (
    <div
      className={`search-input ${className ? className : ""}`}
      style={{ height: height }}>
      <Search />
      <input
        style={{ background: background }}
        onBlur={onBlur}
        id={name}
        name={name}
        label={label}
        className="w-100"
        value={filterInputs}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        onChange={(e) => filterInputsUpdate(e)}
        type="text"
        placeholder={placeholder}
      />
      <RenderIf condition={isClearable}>
        <div
          onClick={() => setFilterInputs({ title: "" })}
          className="clearButton">
          <Clear />
        </div>
      </RenderIf>
    </div>
  );
};

export default SearchInput;
