import { ArrowLeft } from "shared/components/Icons";
import { Link } from "react-router-dom";

export function getYouTubeVideoId(url) {
  const regex =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
}
export const processTextWithLinks = (text) => {
  if (!text) {
    return null;
  }
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const matches = text.match(urlRegex);

  if (!matches) {
    return <p>{text}</p>;
  }
  const parts = text.split(urlRegex);

  return (
    <p>
      {parts?.map((part, index) => {
        if (matches.includes(part)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              className="linkText"
              rel="noopener noreferrer">
              Go to link
              <ArrowLeft />
            </a>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    </p>
  );
};
export const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  );
};
