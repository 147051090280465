import React from "react";
import { Received } from "shared/components/Icons";
import "./Card.scss";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const TeamCard = ({ img, firstName, lastName, position, description }) => {
  return (
    <div className="teamCardMain">
      <img
        className="w-100"
        alt="user img"
        src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`}
      />
      <div className="teamCardMainText w-100">
        <div className="d-flex justify-content-between align-items-center">
          <h4>
            {firstName} {lastName}
          </h4>
          <Received />
        </div>
        <div className="innerTextDiv">
          <span>{position}</span>
          <p
            dangerouslySetInnerHTML={{
              __html: description,
            }}></p>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
