import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

const FETCHTYPE = "Notification";
export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  tagTypes: [FETCHTYPE],
  endpoints: (builder) => ({
    editNotification: builder.mutation({
      query(data) {
        return {
          url: "notification/read",
          method: "PUT",
          data,
        };
      },
      invalidatesTags: (result, error) => (error ? "" : [FETCHTYPE]),
    }),
    deleteNotification: builder.mutation({
      query(data) {
        return {
          url: "notification",
          method: "DELETE",
          data,
        };
      },
      invalidatesTags: (result, error) => (error ? "" : [FETCHTYPE]),
    }),
    getNotifications: builder.query({
      query(data) {
        const { SiteUserId, sortField, orderBy } = data;
        return {
          url: "notification/filter",
          params: { SiteUserId, sortField, orderBy },
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result) => {
        const updatedEarlierData = [];
        const updatedNewData = [];
        const unReadData = [];

        result.data?.forEach((notification) => {
          const timeDiff = new Date(notification?.createdDate);
          const diffInDays = (new Date() - timeDiff) / (1000 * 60 * 60 * 24);
          const isOneDayAgo = diffInDays <= 1;

          if (!isOneDayAgo) {
            updatedEarlierData.push(notification);
          } else {
            updatedNewData.push(notification);
          }

          if (!notification?.read) {
            unReadData.push(notification);
          }
        });

        return {
          data: result?.data,
          newData: updatedNewData,
          earlierData: updatedEarlierData,
          unReadDatas: unReadData,
        };
      },
      providesTags: [FETCHTYPE],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useEditNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApi;
