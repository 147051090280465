import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const suggestApi = createApi({
    reducerPath: "suggestApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        createSuggest: builder.mutation({
            query(form) {
                return {
                    url: "suggestion",
                    method: "POST",
                    data: form,
                };
            },
            transformResponse: (result) => result,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {}
            },
        }),
        getAllComplainReason: builder.query({
            query() {
                return {
                    url: `suggestion/filterComplaintReason`,
                };
            },
        }),
    }),
});

export const { useCreateSuggestMutation, useGetAllComplainReasonQuery } =
    suggestApi;
