import { React, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import { Button, RenderIf } from "shared/components";
import { Add, Plus, RotateLeft, TickSquare } from "../Icons";
import { useSelector } from "react-redux";
import {
  useApplyCommunityMutation,
  useJoinCommunityMutation,
} from "redux/api/community";
import "./Card.scss";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
const initialValues = {
  communityId: "",
  siteUserId: "",
};

const CommunityCard = ({
  img,
  title,
  member,
  description,
  cardClickHandler,
  className,
  refetch,
  communityData,
  communitySiteUsers,
}) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const { id } = useSelector((state) => state.user.user);
  const filterData = communitySiteUsers?.filter((c) => c.siteUserId === id);
  const filterMember = communitySiteUsers?.filter(
    (c) => c.role === "Member" && c.status === 3
  );

  const textData = description
    .split("</p>")
    .filter(Boolean)
    .map((item) => item + "</p>");
  const visibleData = textData.slice(0, 1);
  const handlerOnClick = () => {
    filterData?.forEach((c) => {
      c?.status === 3 && cardClickHandler();
    });
    // cardClickHandler();
  };

  const [applyCommunity, { isLoading, isSuccess }] =
    useApplyCommunityMutation();
  const [joinCommunity, { isLoading: joinLoading, isSuccess: joinSuccess }] =
    useJoinCommunityMutation();

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (values.submitType === "POST1") {
        const apply = new FormData(form.current);
        apply.append("siteUserId", id);
        apply.append("communityId", communityData);

        applyCommunity(apply);
      } else if (values.submitType === "POST2") {
        const join = {
          siteUserId: id,
          communityId: communityData,
        };

        joinCommunity(join);
      }

      resetForm();
    },
  });
  useEffect(() => {
    if (isSuccess) {
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      refetch();
    }
  }, [isLoading]);
  useEffect(() => {
    if (joinSuccess) {
      refetch();
    }
  }, [joinLoading]);

  return (
    <div className="communityCard">
      <div className="communityCardTop justify-content-between align-items-center">
        <div onClick={handlerOnClick} className="communityCardTopLeft d-flex">
          <div className="communityCardTopLeftOne">
            <img alt="user" src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`} />
          </div>
          <div className="communityCardTopLeftTwo">
            <h4>{title}</h4>
            <span>
              {filterMember?.length} {t("members")}
            </span>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} ref={form}>
          <div className="eventCardRightBottomRight d-flex">
            <RenderIf condition={!filterData.length}>
              <Button
                type="submit"
                className="font_14"
                background="#057953"
                icon={<Plus />}
                onClick={() => formik.setFieldValue("submitType", "POST1")}>
                {t("apply")}
              </Button>
            </RenderIf>
            {filterData?.map((c) => {
              return (
                <div key={c?.id}>
                  <RenderIf condition={c.status === 2}>
                    <Button
                      className="font_14"
                      background="#4C5359"
                      icon={<Add />}>
                      {t("unableJoin")}
                    </Button>
                  </RenderIf>
                  <RenderIf condition={c.status === 0}>
                    <Button
                      type="submit"
                      className="font_14"
                      background="#057953"
                      icon={<Plus />}
                      onClick={() =>
                        formik.setFieldValue("submitType", "POST1")
                      }>
                      {t("apply")}
                    </Button>
                  </RenderIf>
                  <RenderIf condition={c.status === 3}>
                    <Button
                      className="font_14 joinButton"
                      background="#874A9C"
                      icon={<TickSquare />}
                      onClick={() => {
                        formik.setFieldValue("submitType", "POST2");
                      }}>
                      {t("joined")}
                    </Button>
                  </RenderIf>
                  <RenderIf condition={c.status === 1}>
                    <Button
                      className="font_14 noBackground"
                      background="#F3F8F6"
                      icon={<RotateLeft />}>
                      {t("pending")}
                    </Button>
                  </RenderIf>
                </div>
              );
            })}
          </div>
          <ToastContainer />
        </form>
      </div>
      <RenderIf condition={description}>
        <div onClick={handlerOnClick} className="communityCardBottom">
          {/* <p
            dangerouslySetInnerHTML={{
              __html: description,
            }}></p> */}
          {visibleData?.map((item, index) => (
            <div
              className="newsCardOneRightDiv"
              key={index}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </div>
      </RenderIf>
    </div>
  );
};

export default CommunityCard;
