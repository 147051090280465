import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const partnersApi = createApi({
  reducerPath: "partnersApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getPartners: builder.query({
      query(filter) {
        return {
          url: `partner/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getPartner: builder.query({
      query(id) {
        return {
          url: `partner/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetPartnerQuery, useGetPartnersQuery } = partnersApi;
