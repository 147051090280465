import * as React from "react";
const SvgVideoPlay = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <path
      fill="#835C91"
      d="M17.183 2.334h-6.37v5.087h6.37V2.334ZM18.938 2.334v5.087h6.58c-.596-3.209-2.964-5.075-6.58-5.087ZM2.332 9.17v9.718c0 4.247 2.532 6.779 6.778 6.779h9.777c4.247 0 6.778-2.532 6.778-6.779V9.17H2.332Zm14.513 9.707-2.426 1.4c-.514.291-1.015.443-1.482.443-.35 0-.665-.082-.957-.245-.676-.385-1.05-1.178-1.05-2.205v-2.8c0-1.027.374-1.82 1.05-2.205.677-.397 1.54-.327 2.439.198l2.426 1.4c.899.514 1.389 1.237 1.389 2.019 0 .781-.502 1.47-1.389 1.995ZM9.064 2.334c-3.616.012-5.985 1.878-6.58 5.087h6.58V2.334Z"
    />
  </svg>
);
export default SvgVideoPlay;
