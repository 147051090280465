import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabIndex: "1",
};

export const tabSlicer = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
  },
});

export const { setTabIndex } = tabSlicer.actions;

export default tabSlicer.reducer;
