import * as React from "react";
const SvgBarcode = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M2.917 14.166V5.833c0-2.5 1.25-4.167 4.167-4.167h5.833c2.917 0 4.167 1.667 4.167 4.167v8.334c0 2.5-1.25 4.166-4.167 4.166H7.084c-2.917 0-4.167-1.666-4.167-4.166ZM13.334 5H6.667M13.334 7.5h-3.333M7.5 7.5h-.833M13.334 12.5h-.833M13.334 10H6.667M10 12.5H6.667M13.334 15H6.667"
    />
  </svg>
);
export default SvgBarcode;
