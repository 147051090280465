import * as React from "react";
const SvgComplexity = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#057953" opacity={0.2} rx={6} />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.602 22.667h12.797"
    />
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4}
      d="M11.335 13.253h-1.067c-.366 0-.666.3-.666.667v5.746c0 .367.3.667.666.667h1.067c.366 0 .666-.3.666-.667V13.92c0-.367-.3-.667-.666-.667ZM16.534 11.127h-1.067c-.366 0-.666.3-.666.667v7.873c0 .367.3.667.666.667h1.067c.367 0 .667-.3.667-.667v-7.873c0-.367-.3-.667-.667-.667ZM21.733 9h-1.066C20.3 9 20 9.3 20 9.667v10c0 .366.3.666.667.666h1.066c.367 0 .667-.3.667-.666v-10c0-.367-.3-.667-.667-.667Z"
    />
  </svg>
);
export default SvgComplexity;
