import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../axiosBase";

export const reviewAndStoryApi = createApi({
  reducerPath: "reviewAndStoryApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllReview: builder.query({
      query(filter) {
        return {
          url: `reviewAndStory/review/filter?`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getAllStory: builder.query({
      query(filter) {
        return {
          url: `reviewAndStory/story/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
  }),
});

export const { useGetAllReviewQuery, useGetAllStoryQuery } = reviewAndStoryApi;
