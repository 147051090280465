import * as React from "react";
const SvgFilePpt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    {...props}
  >
    <g fill="none">
      <path fill="#CCC" d="M10 0h45l15 15v65H10z" />
      <path fill="#FFF" d="M11 79h58V15L55 1H11z" />
      <path fill="#CCC" d="M56 14h11.586L56 2.414V14zM55 0l15 15H55V0z" />
      <path
        fill="#F6BA9D"
        d="M33 52v-3h31v3H33zm31 8-31-.023V57h31v3zm0 8-31-.012V65h31v3zm-31 8v-3h31v3H33zm9-64c7.732 0 14 6.268 14 14H42V12z"
      />
      <path
        fill="#949494"
        d="M40 14c-7.056 0-12.893 5.22-13.86 12.01C33.01 25 33 25 33 25s.01 13.588.01 15.133A13.936 13.936 0 0 0 40 42c7.732 0 14-6.268 14-14H40V14z"
      />
      <path fill="#D04727" d="M0 33v38l30 5V29z" />
      <path
        fill="#FFF"
        d="M15.288 44 10 44.043l.013 16.936L13.38 61l-.004-5.851 1.617.002c.29 0 .574-.014.85-.044s.545-.076.805-.136a6.329 6.329 0 0 0 1.473-.534c.23-.12.446-.253.65-.398a5.143 5.143 0 0 0 1.48-1.632 5.391 5.391 0 0 0 .563-1.41c.062-.25.11-.508.14-.776.032-.266.047-.541.047-.823-.001-.907-.12-1.7-.36-2.38a4.198 4.198 0 0 0-1.075-1.693c-.477-.45-1.072-.784-1.786-1.005-.714-.22-1.544-.327-2.491-.32m-1.917 8.241-.004-5.29 1.33-.007a3.83 3.83 0 0 1 1.185.157c.34.107.623.269.85.487.226.217.397.49.511.818.114.327.171.71.171 1.149 0 .448-.056.84-.17 1.175a2.1 2.1 0 0 1-.51.839 2.133 2.133 0 0 1-.848.503c-.339.11-.734.167-1.185.167l-1.33.002"
      />
    </g>
  </svg>
);
export default SvgFilePpt;
