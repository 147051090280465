import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Linkify from "linkify-react";
import { renderLink } from "shared/helper/getYoutubeVideoId";
import { MentionsInput, Mention } from "react-mentions";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import {
  RenderIf,
  FileSlider,
  ClickMore,
  ImageGrid,
  Loading,
} from "shared/components";
import { Urls } from "shared/constants/url";
import Member from "shared/media/user/userIcon.png";
import { calculateTimeAgo } from "shared/helper/formatDate";
import { Send2, Like } from "shared/components/Icons";
import {
  useDeleteDiscussionCommentsMutation,
  useUpdateDiscussionCommentsMutation,
  useDeleteDiscussionCommentLikeMutation,
  useDiscussionCommentLikeMutation,
} from "redux/api/discussion";
import { useGetAllUsersWithoutFilterQuery } from "redux/api/siteUsers";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./DiscussionComment.scss";
import { custom, customStyle } from "./style";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const DiscussionComment = ({
  siteUserId,
  description,
  userName,
  userSurname,
  userPhoto,
  createDate,
  newsCommentFiles,
  commentLoading,
  commentId,
  refetch,
  isLiked,
  likeCount,
  discussionId,
}) => {
  const { t } = useTranslation();
  const { id } = useSelector((state) => state.user.user);
  const { data, isSuccess: usersSuccess } = useGetAllUsersWithoutFilterQuery(
    {}
  );
  const [modal, setModal] = useState(false);
  const [gotFileFromFileComp, setGotFileFromFileComp] = React.useState();
  const [getDeletedIds, setDeletedIds] = React.useState();
  const [suggestion, setSuggettion] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [text, setText] = useState("");
  const handleInputChange = (event) => setText(event.target.value);
  const handleTagClick = (id) => {
    setSelectedId((prevIds) => [...prevIds, id]);
  };
  const selectedIds = useMemo(() => {
    const ids = [...selectedId];
    const idRegex = /@(\d+)/g;
    let match;
    while ((match = idRegex.exec(description)) !== null) {
      const id = parseInt(match[1]);
      if (!ids.includes(id)) {
        ids.push(id);
      }
    }
    return ids;
  }, [description, selectedId]);

  const formattedMessage = useMemo(() => {
    let message = text;
    selectedId?.forEach((id) => {
      const regex = new RegExp(`@\\[.*?\\]\\(${id}\\)`, "g");
      message = message.replace(regex, `@${id}`);
    });
    return message;
  }, [text, selectedId]);

  const updatedDescription = useMemo(() => {
    return selectedIds?.reduce((prevDescription, id) => {
      const user = data?.data?.find((user) => user.id === id);
      const fullName = user ? `${user.firstName} ${user.lastName}` : "";
      const regex = new RegExp(`@${id}`, "g");
      return prevDescription.replace(regex, `@${fullName}`);
    }, description);
  }, [selectedIds, description, data]);
  const [deleteComment, { isLoading, isSuccess }] =
    useDeleteDiscussionCommentsMutation();
  const [
    updateComment,
    { isSuccess: updateSuccess, isLoading: updateLoading },
  ] = useUpdateDiscussionCommentsMutation();
  const [commentLike] = useDiscussionCommentLikeMutation();
  const [deleteCommentLike] = useDeleteDiscussionCommentLikeMutation();
  const likeComment = () => {
    const formData = new FormData();
    formData.append("DiscussionCommentId", commentId);
    commentLike(formData);
  };
  const deleteLikeComment = () => {
    const data = {
      discussionCommentId: commentId,
    };
    deleteCommentLike(data);
  };
  const onDeleteComment = (e) => {
    e.preventDefault();
    deleteComment(commentId);
  };
  const titles = [
    {
      id: uuid(),
      title: t("edit"),
      onClick: () => setModal(true),
    },
    {
      id: uuid(),
      title: t("delete"),
      onClick: onDeleteComment,
    },
  ];

  const getImagesFromFile = (files) => {
    setGotFileFromFileComp(files);
  };
  const getImagesIds = (files) => {
    setDeletedIds(files);
  };
  const newFiles = useMemo(
    () =>
      newsCommentFiles?.map((file) => {
        return {
          ...file,
          fileUrl: `${REACT_APP_BASE_PHOTO_URL_MAIN}${file?.fileUrl}`,
        };
      }),
    [newsCommentFiles]
  );

  const updatePostComment = () => {
    const formData = new FormData();
    formData.append("DiscussionId", discussionId);
    formData.append("DiscussionCommentId", commentId);
    formData.append("description", formattedMessage);
    if (getDeletedIds?.length) {
      for (let i = 0; i < getDeletedIds?.length; i++) {
        formData.append("DeletedFiles", getDeletedIds[i]);
      }
    }

    for (let i = 0; i < gotFileFromFileComp?.length; i++) {
      const currentFile = gotFileFromFileComp?.[i];

      if (!currentFile?.id) {
        formData.append(`Files`, currentFile);
      }
    }
    updateComment(formData);
  };
  useEffect(() => {
    if (isSuccess || updateSuccess) {
      refetch && refetch();
      setModal(false);
    }
  }, [isLoading, updateLoading]);

  useEffect(() => {
    const formattedData = data?.data?.map((user) => ({
      id: user?.id,
      display: `${user.firstName} ${user.lastName}`,
    }));
    setSuggettion(formattedData);
    setText(updatedDescription);
  }, [usersSuccess]);
  return (
    <div className="cardMainCommentList">
      <RenderIf condition={!commentLoading} renderElse={<Loading />}>
        <div className="cardMainCommentListBox">
          <div className="cardMainCommentListBoxLeft">
            <Link to={Urls.POSTS_URL + `/${siteUserId}`}>
              <img
                alt="user"
                src={
                  userPhoto
                    ? `${REACT_APP_BASE_PHOTO_URL_MAIN}${userPhoto}`
                    : Member
                }
              />
            </Link>
          </div>
          <div className="cardMainCommentListBoxRight">
            <RenderIf condition={!modal}>
              <div className="cardMainCommentListBoxRightInner noFile">
                <div className="d-flex justify-content-between align-items-center">
                  <Link to={Urls.POSTS_URL + `/${siteUserId}`}>
                    <span className="name">
                      {userName} {userSurname}
                    </span>
                  </Link>
                  <div className="d-flex align-items-center">
                    <span className="time mx-2">
                      {calculateTimeAgo(createDate)}
                    </span>
                    <RenderIf condition={id == siteUserId}>
                      <ClickMore buttonTitles={titles} />
                    </RenderIf>
                  </div>
                </div>
                <RenderIf condition={text !== null}>
                  <div className={`${newsCommentFiles?.length ? "mb-3" : ""}`}>
                    <Linkify options={{ render: renderLink }}>
                      {updatedDescription}
                    </Linkify>
                  </div>
                </RenderIf>
                <RenderIf condition={newsCommentFiles?.length}>
                  <ImageGrid forComment>
                    {newsCommentFiles.map((item) => {
                      if (item?.fileUrl.includes(".mp4")) {
                        return (
                          <video
                            src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${item?.fileUrl}`}
                            controls></video>
                        );
                      } else {
                        return (
                          <img
                            alt="comment"
                            src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${item?.fileUrl}`}
                          />
                        );
                      }
                    })}
                  </ImageGrid>
                </RenderIf>
              </div>
            </RenderIf>
            <RenderIf condition={modal}>
              <div className="cardMainCommentListBoxRightInner">
                <div className="d-flex justify-content-between align-items-center">
                  <Link to={Urls.POSTS_URL + `/${siteUserId}`}>
                    <span className="name">
                      {userName} {userSurname}
                    </span>
                  </Link>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <MentionsInput
                    autoFocus={false}
                    value={text || ""}
                    onChange={handleInputChange}
                    placeholder={t("writeComment")}
                    style={customStyle}>
                    {suggestion?.map((item, index) => (
                      <Mention
                        key={uuid()}
                        trigger="@"
                        data={[item]}
                        onAdd={handleTagClick}
                        style={{
                          ...custom,
                        }}
                      />
                    ))}
                  </MentionsInput>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <button
                    onClick={() => {
                      setModal(false);
                      setText(description);
                    }}>
                    {t("cancel")}
                  </button>
                  <div className="d-flex align-items-center">
                    <Send2
                      onClick={updatePostComment}
                      width="20px"
                      height="20px"
                      className="mx-2 sendButton"
                    />
                  </div>
                </div>
              </div>
              <FileSlider
                forComment
                file={newFiles}
                multiple={true}
                handleImageID={getImagesIds}
                handleImageFile={getImagesFromFile}
              />
            </RenderIf>
            <div
              onClick={isLiked ? deleteLikeComment : likeComment}
              className={`${
                isLiked ? "like-active" : ""
              } newsCardTwoIcon mt-1`}>
              <Like />
              <span>
                {" "}
                {t("like")} ({likeCount})
              </span>
            </div>
          </div>
        </div>
      </RenderIf>
    </div>
  );
};

export default DiscussionComment;
