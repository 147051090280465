import React from "react";
import "./Tags.scss";

const Tags = ({ content }) => {
  return (
    <div className="tags">
      <p>{content}</p>
    </div>
  );
};

export default Tags;
