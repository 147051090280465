import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    interest: [],
    join: false,
    endorse: false,
};

export const interestSlicer = createSlice({
    name: "interest",
    initialState,
    reducers: {
        setInterest: (state, action) => {
            state.interest = action.payload;
        },
        setJoin: (state, action) => {
            state.join = action.payload;
        },
        setEndorse: (state, action) => {
            state.join = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setInterest, setJoin, setEndorse } = interestSlicer.actions;

export default interestSlicer.reducer;
