import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import { Button, RenderIf } from "shared/components";
import { getYearAndMonthNameAndDate } from "shared/helper/formatDate";
import { Add, Plus, RotateLeft, Star } from "shared/components/Icons";
import {
  useApplyEventMutation,
  useJoinEventMutation,
  useRejectEventMutation,
} from "redux/api/events";
import "./Card.scss";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
const initialValues = {
  eventId: "",
  siteUserId: "",
};

const EventCard = ({
  isPromoted,
  title,
  date,
  img,
  eventType,
  cardClickHandler,
  fromClock,
  toClock,
  refetch,
  eventFormatText,
  eventData,
  eventSiteUsers,
}) => {
  const { t } = useTranslation();
  const form = useRef(null);
  const { id } = useSelector((state) => state.user.user);
  const [applyEvent, { isLoading, isSuccess }] = useApplyEventMutation();
  const [joinEvent, { isLoading: joinLoading, isSuccess: joinSuccess }] =
    useJoinEventMutation();
  const filterData = eventSiteUsers?.filter((c) => c.siteUserId === id);
  const filterData2 = eventSiteUsers?.filter((c) => c.status === 3);

  const [rejectEvent] = useRejectEventMutation();
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (values.submitType === "POST1") {
        const apply = new FormData(form.current);
        apply.append("siteUserId", id);
        apply.append("eventId", eventData);

        applyEvent(apply);
      } else if (values.submitType === "POST2") {
        const reject = new FormData(form.current);
        reject.append("siteUserId", id);
        reject.append("eventId", eventData);

        rejectEvent(reject);
      } else if (values.submitType === "POST3") {
        const join = {
          siteUserId: id,
          eventId: eventData,
        };

        joinEvent(join);
      }

      resetForm();
    },
  });
  useEffect(() => {
    if (refetch) {
      if (isSuccess) {
        toast.success("Send successfully !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        refetch();
      }
    }
  }, [isLoading]);
  useEffect(() => {
    if (refetch) {
      if (joinSuccess) {
        refetch();
      }
    }
  }, [joinLoading]);
  return (
    <div className={`eventCard  ${isPromoted ? "card-active" : ""}`}>
      <div className="eventCardLeft" onClick={cardClickHandler}>
        <img alt="event img" src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${img}`} />
        <RenderIf condition={isPromoted}>
          <button className="active">Promoted</button>
        </RenderIf>
      </div>
      <div className="eventCardRight">
        <div onClick={cardClickHandler} className="eventCardRightTop">
          <span>{eventType}</span>{" "}
          <span className="before">{eventFormatText}</span>
          <h4>{title}</h4>
          <div className="d-flex">
            <span className="time">{getYearAndMonthNameAndDate(date)} </span>
            <span className="time hours">
              {" "}
              {fromClock?.slice(11, 16)}-{toClock?.slice(11, 16)}
            </span>
          </div>
        </div>
        <div className="eventCardRightBottom d-flex justify-content-between align-items-center">
          <div className="eventCardRightBottomLeft d-flex">
            <div className="eventCardRightBottomLeftImg">
              {filterData2?.map((c) => (
                <img
                  alt="event img"
                  src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${c?.siteUserFileUrl}`}
                />
              ))}
              <span>{filterData2?.length} attended</span>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} ref={form}>
            <div className="eventCardRightBottomRight d-flex">
              <RenderIf condition={!filterData.length}>
                <Button
                  type="submit"
                  className="font_14"
                  background="#057953"
                  icon={<Plus />}
                  onClick={() => formik.setFieldValue("submitType", "POST1")}>
                  {t("apply")}{" "}
                </Button>
              </RenderIf>
              {filterData?.map((c) => {
                return (
                  <>
                    <RenderIf condition={c.status === 2}>
                      <Button
                        className="font_14"
                        background="#4C5359"
                        icon={<Add />}>
                        {t("unableJoin")}
                      </Button>
                    </RenderIf>
                    <RenderIf condition={c.status === 0}>
                      <Button
                        type="submit"
                        className="font_14"
                        background="#057953"
                        icon={<Plus />}
                        onClick={() =>
                          formik.setFieldValue("submitType", "POST1")
                        }>
                        {t("apply")}
                      </Button>
                    </RenderIf>
                    <RenderIf condition={c.status === 3}>
                      <Button
                        className="font_14 iconPath"
                        background="#874A9C"
                        icon={<Star />}
                        onClick={() =>
                          formik.setFieldValue("submitType", "POST3")
                        }>
                        {t("interested")}
                      </Button>
                    </RenderIf>
                    <RenderIf condition={c.status === 1}>
                      <Button
                        className="font_14 noBackground"
                        background="#F3F8F6"
                        icon={<RotateLeft />}>
                        {t("pending")}
                      </Button>
                    </RenderIf>
                  </>
                );
              })}
            </div>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
