import * as React from "react";
const SvgCalendarSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M6.666 1.667v2.5M13.334 1.667v2.5M2.916 7.575h14.167M10.833 18.333H6.667c-2.917 0-4.167-1.666-4.167-4.166V7.082c0-2.5 1.25-4.167 4.167-4.167h6.666c2.917 0 4.167 1.667 4.167 4.167v3.333"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9.996 11.417h.008M6.912 11.417h.008M6.912 13.917h.008"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="m16.91 13.708.443.885c.06.123.22.24.356.264l.802.133c.513.085.633.457.264.824l-.623.623a.545.545 0 0 0-.13.455l.178.772c.14.61-.184.846-.724.527l-.752-.444a.543.543 0 0 0-.497 0l-.752.444c-.537.32-.864.08-.723-.527l.178-.772a.545.545 0 0 0-.13-.455l-.624-.623c-.367-.367-.249-.739.264-.824l.802-.133a.548.548 0 0 0 .354-.264l.442-.885c.242-.48.634-.48.872 0Z"
    />
  </svg>
);
export default SvgCalendarSearch;
