import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    result: {
        users: [],
        communities: [],
        libraries: [],
        events: [],
    },
};

export const resultSlice = createSlice({
    name: "resultSlice",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.result.users = action.payload;
        },
        setCommunities: (state, action) => {
            state.result.communities = action.payload;
        },
        setLibraries: (state, action) => {
            state.result.libraries = action.payload;
        },
        setEvents: (state, action) => {
            state.result.events = action.payload;
        },
    },
});

export const {
    setUsers,
    setCommunities,
    setEvents,
    setLibraries,
    setTriggered,
} = resultSlice.actions;

export default resultSlice.reducer;
