import * as React from "react";
const SvgFolderPdf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <g filter="url(#folderPdf_svg__a)">
      <path
        fill="#FA4E4E"
        fillRule="evenodd"
        d="M6.329 2.332c-.982 0-1.778.796-1.778 1.778v19.778c0 .981.796 1.777 1.778 1.777h15.344c.982 0 1.778-.796 1.778-1.777V8.165l-5.833-5.833H6.328Z"
        clipRule="evenodd"
      />
    </g>
    <path
      fill="currentColor"
      d="M17.79 18.048a3.716 3.716 0 0 1-2.158-.877 20.834 20.834 0 0 0-3.47 1.111c-.904 1.608-1.75 2.427-2.479 2.427-.146 0-.32-.03-.437-.117a.862.862 0 0 1-.496-.79c0-.263.058-.994 2.828-2.192a20.84 20.84 0 0 0 1.546-3.626c-.35-.702-1.108-2.427-.583-3.304.175-.322.525-.497.904-.468.291 0 .583.146.758.38.379.526.35 1.638-.146 3.275a8.816 8.816 0 0 0 1.808 2.34c.612-.118 1.225-.206 1.837-.206 1.37.03 1.575.673 1.545 1.053 0 .994-.962.994-1.457.994Zm-8.165 1.813.087-.03c.409-.145.73-.438.963-.818a2.305 2.305 0 0 0-1.05.848Zm3.878-8.772h-.088c-.029 0-.087 0-.116.03-.117.497-.03 1.023.175 1.49a2.463 2.463 0 0 0 .029-1.52Zm.204 4.24-.029.058-.03-.029a24.184 24.184 0 0 1-.874 1.988l.058-.029v.059c.649-.236 1.311-.43 1.983-.585l-.029-.03h.088a8.953 8.953 0 0 1-1.167-1.432Zm3.966 1.55c-.263 0-.496 0-.758.058.291.146.583.205.875.234.204.03.408 0 .583-.058 0-.088-.117-.234-.7-.234Z"
    />
    <path
      fill="url(#folderPdf_svg__b)"
      d="M22.903 7.62h-4.739l5.287 5.286v-4.74l-.548-.547Z"
    />
    <path
      fill="#FDB8B8"
      d="M19.394 8.166h4.055l-5.833-5.833v4.055c0 .982.796 1.778 1.778 1.778Z"
    />
    <defs>
      <linearGradient
        id="folderPdf_svg__b"
        x1={19.349}
        x2={24.635}
        y1={6.434}
        y2={11.721}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.2} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <filter
        id="folderPdf_svg__a"
        width={18.898}
        height={23.556}
        x={4.551}
        y={2.221}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={-0.222} />
        <feGaussianBlur stdDeviation={0.056} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3674_29934" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={0.222} />
        <feGaussianBlur stdDeviation={0.056} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
        <feBlend
          in2="effect1_innerShadow_3674_29934"
          result="effect2_innerShadow_3674_29934"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgFolderPdf;
