import { createApi } from "@reduxjs/toolkit/query/react";
import { setInterest } from "redux/feature/interest";
import { APIBaseQuery } from "../axiosBase";

export const interestsApi = createApi({
    reducerPath: "interestsApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getInterests: builder.query({
            query(filter) {
                return {
                    url: `interest/filter`,
                };
            },
            transformResponse: (result) => result,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setInterest(data));
                } catch (error) {}
            },
        }),
        getInterest: builder.query({
            query(id) {
                return {
                    url: `interest/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {}
            },
        }),
    }),
});

export const { useGetInterestsQuery, useGetInterestQuery } = interestsApi;
