import * as React from "react";
const SvgFiledoc = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    {...props}
  >
    <g fill="none">
      <path fill="#CCC" d="M10 0h45l15 15v65H10z" />
      <path fill="#FFF" d="M11 79h58V15L55 1H11z" />
      <path fill="#CCC" d="M56 14h11.586L56 2.414V14zM55 0l15 15H55V0z" />
      <path
        fill="#999"
        d="M15 12V9h40v3H15zm0 9v-3h50v3H15zm18 9v-3h32v3H33zm0 9v-3h32v3H33zm0 9v-3h32v3H33zm0 9v-3h32v3H33zm0 9v-3h32v3H33zm0 9v-3h32v3H33z"
      />
      <path fill="#2B579A" d="M0 32v38l30 5V28z" />
      <path
        fill="#FFF"
        d="M22.558 43.027 20 55.5 17.2 43h-3.4L11 55.5 8.5 43H5l3.8 17h3.9l2.8-12 2.7 12H22l4-17z"
      />
    </g>
  </svg>
);
export default SvgFiledoc;
