import * as React from "react";
const SvgArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 18 19"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.94 7.213-4.89 4.89a1.49 1.49 0 0 1-2.1 0l-4.89-4.89"
    />
  </svg>
);
export default SvgArrowDown;
