import * as React from "react";
const SvgPen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 30 30"
    {...props}>
    <circle
      cx={15}
      cy={15}
      r={14.5}
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m16.11 8.996-7.184 7.603c-.272.289-.534.858-.587 1.252l-.323 2.835c-.114 1.023.62 1.723 1.636 1.548l2.817-.481c.394-.07.945-.359 1.216-.656l7.184-7.604c1.243-1.313 1.803-2.809-.131-4.637-1.925-1.812-3.386-1.173-4.629.14Z"
    />
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14.914 10.265a5.36 5.36 0 0 0 4.769 4.506"
    />
  </svg>
);
export default SvgPen;
