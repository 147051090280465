import { format } from "date-fns";
import moment from "moment";

export const dateConvertItem = (date) => {
  if (date) {
    const datetime = new Date(date);
    return format(datetime, "MM.dd.yyyy");
  }
};
export const dateDiff = (startDate, endDate) => {
  const format = "YYYY-MM-DDTHH:mm:ssZ";
  const t1 = moment(startDate, format);
  const t2 = moment(endDate, format);
  let yearDiff = t2.diff(t1, "years");
  t1.add(yearDiff, "years");
  let monthDiff = t2.diff(t1, "months");
  return { yearDiff, monthDiff };
};
export function getYearAndMonthName(datetime) {
  const dateObj = new Date(datetime);
  const monthName = dateObj.toLocaleString("default", { month: "long" });
  const year = dateObj.getFullYear();
  return `${monthName} ${year}`;
}
