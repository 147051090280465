import * as React from "react";
const SvgFallingStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="m8.589 10.559 6.433-6.673M7.027 7.644l1.75-1.815m1.873-1.943 3.123-3.24m1.562 6.803 2.81-2.915M11.9 11.012l1.562-1.62m-2.998 7.646 8.932-9.265m-9.812-3.24.041.131h.132l-.107.081.041.13-.107-.08-.107.08.041-.13-.107-.08h.132l.041-.131Zm5.049 3.753.04.13h.133l-.107.081.041.13-.107-.08-.107.08.04-.13-.106-.08h.132l.04-.131Z"
    />
    <path
      stroke="#313538"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m5.683 9.305 1.151 3.544h3.726l-3.014 2.19 1.151 3.544-3.014-2.19-3.015 2.19 1.151-3.544-3.014-2.19H4.53l1.152-3.544Z"
    />
  </svg>
);
export default SvgFallingStar;
