import * as React from "react";
const SvgMore = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#313538"
      stroke="#313538"
      strokeWidth={0.5}
      d="M4.167 8.333C3.25 8.333 2.5 9.083 2.5 10c0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667ZM15.83 8.333c-.916 0-1.666.75-1.666 1.667 0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667ZM10.003 8.333c-.917 0-1.667.75-1.667 1.667 0 .916.75 1.666 1.667 1.666.916 0 1.666-.75 1.666-1.666 0-.917-.75-1.667-1.666-1.667Z"
    />
  </svg>
);
export default SvgMore;
