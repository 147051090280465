import React from "react";
import { useTranslation } from "react-i18next";
import { data } from "./data";
import "./Index.scss";
const AddMore = ({ setShow, ref }) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className="addMoreMainOptions mt-3">
      {data?.map((c) => (
        <div
          key={c?.id}
          onClick={() => setShow(c?.modal)}
          className="addMoreMainOptionsItem">
          {c?.icon}
          <p>{t(c?.text)}</p>
        </div>
      ))}
    </div>
  );
};

export default AddMore;
