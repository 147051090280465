import * as React from "react";
const SvgDownload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 20"
    {...props}
  >
    <path
      stroke="#464A4D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.078 9.203 4.92 4.92 4.92-4.92M11 14.12V1M1.16 17.398a31.074 31.074 0 0 0 19.68 0"
    />
  </svg>
);
export default SvgDownload;
