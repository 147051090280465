import * as React from "react";
const SvgDocumentText = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.334 8.333V12.5c0 4.166-1.667 5.833-5.834 5.833h-5c-4.166 0-5.833-1.666-5.833-5.833v-5c0-4.167 1.667-5.833 5.833-5.833h4.167"
    />
    <path
      stroke="#313538"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.334 8.333H15c-2.5 0-3.333-.833-3.333-3.333V1.667l6.667 6.666ZM5.833 10.834h5M5.833 14.166h3.333"
    />
  </svg>
);
export default SvgDocumentText;
