export const customStyle = {
  "&multiLine": {
    width: "100%",
    control: {
      border: "none",
      fontFamily: "OpenSans",
    },
    highlighter: {
      border: "none",
    },
  },
  "&singleLine": {
    display: "inline-block",
    width: 180,
    border: "none",
    highlighter: {
      border: "none",
      padding: 1,
    },
    input: {
      padding: 1,
      border: "none",
      width: "100%",
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      fontSize: 14,
      top: "30px",
      width: "200px",
      maxHeight: "200px",
      overflowY: "scroll",
      // overflow: "hidden",
      position: "absolute",
      borderRadius: "10px",
    },
    item: {
      padding: "5px 15px",
      zIndex: "99",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
export const customStyleSecond = {
  "&multiLine": {
    control: {
      border: "none",
      fontFamily: "OpenSans",
    },
    highlighter: {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "24px",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,
    border: "none",

    highlighter: {
      padding: 1,
    },
    input: {
      padding: 1,
      border: "none",
      width: "100%",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      fontSize: 14,
      top: "30px",
      width: "200px",
      height: "200px",
      overflowY: "scroll",
      // overflow: "hidden",
      position: "absolute",
      zIndex: "999",
      borderRadius: "10px",
      border: "1px solid #e2e2e2",
    },
    item: {
      padding: "5px 15px",
      zIndex: "99",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
export const custom = {
  color: "blue",
  textDecoration: "underline",
  fontWeight: "inherit",
  position: "relative",
  zIndex: "99",
};
