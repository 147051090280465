import * as React from "react";
const SvgAddCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#057953"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.999 20.167c5.041 0 9.166-4.125 9.166-9.166 0-5.042-4.125-9.167-9.166-9.167-5.042 0-9.167 4.125-9.167 9.167 0 5.041 4.125 9.166 9.167 9.166ZM7.332 11h7.333M11 14.667V7.334"
    />
  </svg>
);
export default SvgAddCircle;
